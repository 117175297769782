<template>
  <svg
    class="byourplanIllus"
    xmlns="http://www.w3.org/2000/svg"
    id="a27520da-4319-485c-96d7-e776d7397e13"
    data-name="Layer 1"
    width="876.27165"
    height="661.47277"
    viewBox="0 0 876.27165 661.47277"
  >
    <path
      d="M685.83423,138.6545c7.51624,15.99516-8.41069,20.5258-26.65516,29.099s-31.8977,17.94247-39.41393,1.94732-13.61474-47.74263,12.19523-59.87092C658.65953,97.2838,678.318,122.65934,685.83423,138.6545Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#2f2e41"
    />
    <circle cx="467.74627" cy="39.08912" r="24.56103" fill="#ffb8b8" />
    <path
      d="M770.29037,254.47277c-.85009-1.11-1.70019-2.2-2.56006-3.26q-1.66479-2.07-3.35009-4.01c-12.73-14.69-26.27-23.94-41.17969-24.93h-.02l-.22021-.02-29.71,28.96-10.10009,9.84.22021.54,5.44971,13.57h95.49023A234.83392,234.83392,0,0,0,770.29037,254.47277Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#2f2e41"
    />
    <path
      d="M621.90207,155.43779l0,0,8.792-4.13144-1.22476-12.68333,5.87908,10.49623,4.20223-1.97466-.7144-7.39862,3.42957,6.12274,31.22444-14.67258,0,0a26,26,0,0,0-34.589-12.47385l-4.52533,2.12648A26,26,0,0,0,621.90207,155.43779Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#2f2e41"
    />
    <path
      d="M825.39023,601.26062H312.55173a16.51867,16.51867,0,0,1-16.5-16.5V276.60681a12.10184,12.10184,0,0,1,12.08789-12.08838H829.59311a12.31112,12.31112,0,0,1,12.29712,12.29736V584.76062A16.51868,16.51868,0,0,1,825.39023,601.26062Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#f2f2f2"
    />
    <path
      d="M328.55173,584.76062h480.8385a16,16,0,0,0,16-16V307.21277a16,16,0,0,0-16-16H328.55173a16,16,0,0,0-16,16V568.76062A16,16,0,0,0,328.55173,584.76062Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#fff"
    />
    <path
      d="M841.66049,275.663H295.82016v-8.40039a16.57376,16.57376,0,0,1,16.56006-16.5498H825.10043a16.57368,16.57368,0,0,1,16.56006,16.5498Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#f50057"
    />
    <circle cx="145.05135" cy="156.7523" r="4.28342" fill="#fff" />
    <circle cx="161.31015" cy="156.7523" r="4.28342" fill="#fff" />
    <circle cx="177.56896" cy="156.7523" r="4.28342" fill="#fff" />
    <path
      d="M583.97441,528.03954h-43a9.51081,9.51081,0,0,1-9.5-9.5v-43a9.51081,9.51081,0,0,1,9.5-9.5h43a9.51081,9.51081,0,0,1,9.5,9.5v43A9.51081,9.51081,0,0,1,583.97441,528.03954Zm-43-60a7.50835,7.50835,0,0,0-7.5,7.5v43a7.50836,7.50836,0,0,0,7.5,7.5h43a7.50836,7.50836,0,0,0,7.5-7.5v-43a7.50835,7.50835,0,0,0-7.5-7.5Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#ccc"
    />
    <path
      d="M575.47441,516.03954h-26a3.00328,3.00328,0,0,1-3-3v-32a3.00328,3.00328,0,0,1,3-3h18.83935a3.0073,3.0073,0,0,1,1.8794.66162l7.16016,5.7539a2.9844,2.9844,0,0,1,1.12109,2.33838v26.2461A3.00328,3.00328,0,0,1,575.47441,516.03954Zm-26-36a1.0013,1.0013,0,0,0-1,1v32a1.0013,1.0013,0,0,0,1,1h26a1.0013,1.0013,0,0,0,1-1v-26.2461a.99457.99457,0,0,0-.37354-.77929l-7.16113-5.7544a1.00206,1.00206,0,0,0-.626-.22021Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#ccc"
    />
    <path
      d="M570.47441,492.03954h-16a1,1,0,0,1,0-2h16a1,1,0,0,1,0,2Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#ccc"
    />
    <path
      d="M570.47441,498.03954h-16a1,1,0,0,1,0-2h16a1,1,0,0,1,0,2Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#ccc"
    />
    <path
      d="M570.47441,504.03954h-16a1,1,0,0,1,0-2h16a1,1,0,0,1,0,2Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#ccc"
    />
    <path
      d="M685.97441,528.03954h-43a9.51081,9.51081,0,0,1-9.5-9.5v-43a9.51081,9.51081,0,0,1,9.5-9.5h43a9.51081,9.51081,0,0,1,9.5,9.5v43A9.51081,9.51081,0,0,1,685.97441,528.03954Zm-43-60a7.50835,7.50835,0,0,0-7.5,7.5v43a7.50836,7.50836,0,0,0,7.5,7.5h43a7.50836,7.50836,0,0,0,7.5-7.5v-43a7.50835,7.50835,0,0,0-7.5-7.5Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#ccc"
    />
    <path
      d="M678.47441,508.03954h-28a3.00328,3.00328,0,0,1-3-3v-16a3.00328,3.00328,0,0,1,3-3h28a3.00328,3.00328,0,0,1,3,3v16A3.00328,3.00328,0,0,1,678.47441,508.03954Zm-28-20a1.001,1.001,0,0,0-1,1v16a1.001,1.001,0,0,0,1,1h28a1.001,1.001,0,0,0,1-1v-16a1.001,1.001,0,0,0-1-1Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#ccc"
    />
    <path
      d="M664.47441,499.67772a3.00345,3.00345,0,0,1-1.58984-.45264l-14.94043-9.3374a1.00027,1.00027,0,0,1,1.06054-1.69629l14.94043,9.3374a1.00555,1.00555,0,0,0,1.05957,0l14.93946-9.3374a1.00027,1.00027,0,0,1,1.06054,1.69629l-14.93945,9.3374A3.00432,3.00432,0,0,1,664.47441,499.67772Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#ccc"
    />
    <path
      d="M481.97441,528.03954h-43a9.51081,9.51081,0,0,1-9.5-9.5v-43a9.51081,9.51081,0,0,1,9.5-9.5h43a9.51081,9.51081,0,0,1,9.5,9.5v43A9.51081,9.51081,0,0,1,481.97441,528.03954Zm-43-60a7.50835,7.50835,0,0,0-7.5,7.5v43a7.50836,7.50836,0,0,0,7.5,7.5h43a7.50836,7.50836,0,0,0,7.5-7.5v-43a7.50835,7.50835,0,0,0-7.5-7.5Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#ccc"
    />
    <path
      d="M474.47441,508.03954h-28a3.00328,3.00328,0,0,1-3-3v-16a3.00328,3.00328,0,0,1,3-3h28a3.00328,3.00328,0,0,1,3,3v16A3.00328,3.00328,0,0,1,474.47441,508.03954Zm-28-20a1.001,1.001,0,0,0-1,1v16a1.001,1.001,0,0,0,1,1h28a1.001,1.001,0,0,0,1-1v-16a1.001,1.001,0,0,0-1-1Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#ccc"
    />
    <path
      d="M470.03642,508.03954h-20.124a1.99958,1.99958,0,0,1-1.73194-3l6.06153-10.5a2.00022,2.00022,0,0,1,3.46435,0l3.915,6.78125,2.26807-3.92871a2.00022,2.00022,0,0,1,3.46435,0l4.415,7.64746a2,2,0,0,1-1.73242,3Zm0-2h0l-4.415-7.64746-3.13379,5.42871a.99985.99985,0,0,1-.86621.5h0a.99985.99985,0,0,1-.86621-.5l-4.78076-8.28125-6.062,10.5Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#ccc"
    />
    <circle cx="290.61013" cy="385.57888" r="2" fill="#ccc" />
    <polygon
      points="768.825 648.15 756.565 648.149 750.733 600.861 768.827 600.862 768.825 648.15"
      fill="#ffb8b8"
    />
    <path
      d="M952.81564,766.49434l-39.53051-.00146v-.5a15.38605,15.38605,0,0,1,15.38647-15.38623h.001l24.1438.001Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#2f2e41"
    />
    <polygon
      points="818.825 648.15 806.565 648.149 800.733 600.861 818.827 600.862 818.825 648.15"
      fill="#ffb8b8"
    />
    <path
      d="M1002.81564,766.49434l-39.53051-.00146v-.5a15.38605,15.38605,0,0,1,15.38647-15.38623h.001l24.1438.001Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#2f2e41"
    />
    <path
      d="M996.0139,734.11079l-11.35449-3.4541a4.49323,4.49323,0,0,1-3.16577-3.833L970.9077,626.49751a.47984.47984,0,0,0-.47851-.44726.46935.46935,0,0,0-.5105.40918l-17.731,97.19922a4.50036,4.50036,0,0,1-5.33471,3.59961L932.34154,724.269a4.51613,4.51613,0,0,1-3.58179-4.71192l10.7854-159.3125,73.13794-7.8916-10.86792,177.72656a4.50464,4.50464,0,0,1-4.48437,4.22754A4.52613,4.52613,0,0,1,996.0139,734.11079Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#2f2e41"
    />
    <circle cx="815.08028" cy="290.48693" r="24.56103" fill="#ffb8b8" />
    <path
      d="M939.43261,561.18013l15.85815-99.85107a37.85746,37.85746,0,0,1,48.00537-30.39942h0a37.74323,37.74323,0,0,1,26.60108,43.26221l-16.59107,89.17187Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#f50057"
    />
    <path
      d="M1002.725,579.04609a10.0558,10.0558,0,0,0,1.747-15.32l16.39619-31.75128-18.46486,1.97048-12.73924,29.84946a10.11027,10.11027,0,0,0,13.06089,15.25138Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#ffb8b8"
    />
    <path
      d="M1006.23594,558.12555a4.50552,4.50552,0,0,1-2.4265-1.15083l-6.27324-5.79662a4.51468,4.51468,0,0,1-1.09178-5.05417l12.53746-29.71816-1.77859-58.71981a14.49652,14.49652,0,1,1,28.727,3.91856l3.041,36.91226a46.37346,46.37346,0,0,1-8.93769,31.39683l-19.55206,26.43232a4.505,4.505,0,0,1-3.19513,1.80387A4.45452,4.45452,0,0,1,1006.23594,558.12555Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#f50057"
    />
    <path
      d="M854.50508,383.5656a10.05575,10.05575,0,0,0,13.42647,7.58193l22.84575,27.47819,5.38477-17.77183-22.52025-23.36914a10.11027,10.11027,0,0,0-19.13674,6.08085Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#ffb8b8"
    />
    <path
      d="M872.40107,394.9555a4.50564,4.50564,0,0,1,2.00583-1.78577l7.78377-3.51672a4.51467,4.51467,0,0,1,5.07986.96521l22.478,23.13213,54.76621,21.25658a14.49652,14.49652,0,1,1-14.80889,24.92573l-35.17667-11.59151a46.37351,46.37351,0,0,1-25.42736-20.47176l-16.71728-28.31048a4.50493,4.50493,0,0,1-.41535-3.64558A4.45446,4.45446,0,0,1,872.40107,394.9555Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#f50057"
    />
    <polygon
      points="835.002 375.652 834.002 407.652 815.58 439.663 830.962 403.99 835.002 375.652"
      opacity="0.1"
    />
    <path
      d="M993.13047,401.56313a6.42292,6.42292,0,0,1-7.03053-4.70224,9.76432,9.76432,0,0,0-6.95075-7.2736c-4.5055-1.02949-10.01213,1.09128-13.31351-2.32232a6.63748,6.63748,0,0,1-1.57539-5.84234c.60807-3.31718,2.981-5.8232,5.56326-7.8275a34.06226,34.06226,0,0,1,18.8661-7.05067,67.67657,67.67657,0,0,1,10.62084.52837,47.00933,47.00933,0,0,1,10.86368,1.98538,25.57605,25.57605,0,0,1,16.00171,18.68024,29.528,29.528,0,0,1-6.05709,24.13034,31.70221,31.70221,0,0,1-12.16059,9.25872,4.74429,4.74429,0,0,1-3.60621.18291c-2.95734-1.17583-2.47409-4.83917-1.12569-7.28253,1.443-2.61478,3.66335-5.1464,2.50287-8.32859a6.302,6.302,0,0,0-3.12714-3.3662c-2.93992-1.49928-6.22821-1.14456-9.38876-.77957Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#2f2e41"
    />
    <path
      d="M846.29741,415.4331h-304a16.51867,16.51867,0,0,1-16.5-16.5v-65a16.51868,16.51868,0,0,1,16.5-16.5h304a16.519,16.519,0,0,1,16.5,16.5v65A16.519,16.519,0,0,1,846.29741,415.4331Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#fff"
    />
    <path
      d="M846.29724,415.93324h-304a17.01917,17.01917,0,0,1-17-17v-65a17.01916,17.01916,0,0,1,17-17h304a17.01916,17.01916,0,0,1,17,17v65A17.01917,17.01917,0,0,1,846.29724,415.93324Zm-304-97a15.017,15.017,0,0,0-15,15v65a15.017,15.017,0,0,0,15,15h304a15.017,15.017,0,0,0,15-15v-65a15.017,15.017,0,0,0-15-15Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#3f3d56"
    />
    <path
      d="M809.29724,399.43324h-230a16.51868,16.51868,0,0,1-16.5-16.5v-34a16.51867,16.51867,0,0,1,16.5-16.5h230a16.51866,16.51866,0,0,1,16.5,16.5v34A16.51867,16.51867,0,0,1,809.29724,399.43324Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#f2f2f2"
    />
    <circle cx="447.92802" cy="254.91484" r="19.73228" fill="#ff6584" />
    <path
      d="M795.00425,397.4419l-27.85254-23.35968-27.37158-22.95636a4.00031,4.00031,0,0,0-5.15283.01l-27.28321,23.06165-6.66406,5.6333-10.04834-8.42737-30.53711-25.61084a3.99964,3.99964,0,0,0-5.15234.00989l-30.438,25.72833-30.917,26.13349a.99994.99994,0,0,0,.64746,1.76373l63.38672-.12213,20.064-.03864-.19629.166,59.80762-.11524,57.0669-.10992A.99989.99989,0,0,0,795.00425,397.4419Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#3f3d56"
    />
    <path
      d="M1056.13583,767.93324h-192a1,1,0,0,1,0-2h192a1,1,0,0,1,0,2Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#ccc"
    />
    <path
      d="M784.92026,238.37274a11.22094,11.22094,0,0,0-7.25-5.1,10.30111,10.30111,0,0,0-1.65967-.21l-76.19043-68.12a9.01491,9.01491,0,0,0-5.62988-2.69,7.66989,7.66989,0,0,0-1.27-.04,9.05493,9.05493,0,0,0-5.96,15.48l29.16992,25.43,47.8999,41.75a11.91555,11.91555,0,0,0,.3501,2.33,11.26607,11.26607,0,0,0,9.66992,8.37,11.09212,11.09212,0,0,0,6.05029-1,11.40659,11.40659,0,0,0,3.87988-2.97c.10987-.13.21-.25.31006-.39a11.29281,11.29281,0,0,0,.62989-12.84Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#ffb8b8"
    />
    <path
      d="M654.60043,304.02276l-.71-28.86-.6001-23.95-.41015-16.76-.81006-32.67a9.04565,9.04565,0,0,0-15.71-5.25,6.92326,6.92326,0,0,0-.75.96,8.93574,8.93574,0,0,0-1.56006,6.05l1.78027,47.67.89014,23.95,1.13965,30.55a12.0475,12.0475,0,0,0-.88965,1.41,11.248,11.248,0,0,0-.71,8.84,11.31144,11.31144,0,0,0,15.55957,6.63,11.30069,11.30069,0,0,0,2.78027-18.57Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#ffb8b8"
    />
    <path
      d="M723.20053,222.27276l-7.07031-19.15-11.43994-31.02a16.0055,16.0055,0,0,0-9.9502-9.68c-.17969-.06-.36963-.12-.5498-.17005a15.984,15.984,0,0,0-13.23975,1.82l-38.53027,24.44a16.09316,16.09316,0,0,0-5.27,21.75,276.323,276.323,0,0,0,15.73,24.19c4.49023,6.11,12.85009,6.76,14.30029,16.76h28.40967l28.76025-25.82Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#ccc"
    />
    <circle cx="101.16464" cy="326.68231" r="23.17166" fill="#a0616a" />
    <path
      d="M338.91551,417.19256q.3425.27261.66395.57794a10.38021,10.38021,0,0,1-.41708,15.48365,9.72179,9.72179,0,0,1-.81382.61338l1.56823,52.94493a8.06132,8.06132,0,0,1-5.545,7.867l-.09553.02052-42.98869,4.578a8.89852,8.89852,0,1,1-1.72218-17.71279l30.64427-2.69888,5.29962-45.851a9.87574,9.87574,0,0,1-.97155-.99,10.38,10.38,0,0,1,14.3778-14.83273Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#a0616a"
    />
    <polygon
      points="139.328 649.174 150.895 649.174 156.397 604.561 139.326 604.561 139.328 649.174"
      fill="#a0616a"
    />
    <path
      d="M317.24235,751.8583l22.778-.00093h.00092a14.5157,14.5157,0,0,1,14.51609,14.51587v.47171l-37.29436.00139Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#2f2e41"
    />
    <polygon
      points="24.366 633.182 34.304 639.099 61.857 603.582 47.189 594.849 24.366 633.182"
      fill="#a0616a"
    />
    <path
      d="M204.6271,734.88875l19.57164,11.65289.00079.00047a14.5157,14.5157,0,0,1,5.04582,19.89892l-.24134.40531L196.95952,747.767Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#2f2e41"
    />
    <path
      d="M334.4881,739.87708H321.53641a4.23772,4.23772,0,0,1-4.22149-3.79676L304.25773,613.34293a1.41541,1.41541,0,0,0-2.69716-.433l-23.5554,52.15872-41.70358,68.00266a4.26564,4.26564,0,0,1-5.32891,1.66575l-16.66024-7.33a4.24672,4.24672,0,0,1-1.93478-6.06413l38.33707-64.19208,11.75766-54.0852c-3.69795-23.455,12.155-58.326,12.31575-58.67614l.07855-.17044,43.66751-17.06765.219.2681c17.22117,56.51377,27.715,117.3322,19.96672,208.5678A4.27417,4.27417,0,0,1,334.4881,739.87708Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#2f2e41"
    />
    <path
      d="M273.10743,549.934l-.20339-.42749c-.09719-.20454-9.807-20.68365-17.292-42.57146a32.28512,32.28512,0,0,1,2.23858-25.87344,32.6374,32.6374,0,0,1,20.81263-16.00426h0A32.677,32.677,0,0,1,316.591,484.62709c5.93722,14.84708,4.045,30.69452,2.26429,43.15723l-.03547.24967-.22711.10872Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#3f3d56"
    />
    <path
      d="M310.12378,420.05358H273.32992V404.01523c8.07584-3.20838,15.97847-5.93693,20.75551,0a16.03844,16.03844,0,0,1,16.03835,16.03833Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#2f2e41"
    />
    <path
      d="M271.29289,401.18493c-21.99607,0-28.15279,27.57121-28.15279,43.12552,0,8.67446,3.923,11.777,10.08753,12.8268l2.17708-11.611,5.09905,12.11064c1.73162.00864,3.55077-.02488,5.43671-.05989l1.72886-3.55986,3.85527,3.496c15.44087.023,27.92061,2.27382,27.92061-13.20269C299.44521,428.75614,294.04582,401.18493,271.29289,401.18493Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#2f2e41"
    />
    <path
      d="M373.86417,767.93324h-192a1,1,0,1,1,0-2h192a1,1,0,0,1,0,2Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#ccc"
    />
    <path
      d="M377.47441,438.93324h-43a9.51081,9.51081,0,0,1-9.5-9.5v-43a9.51081,9.51081,0,0,1,9.5-9.5h43a9.51081,9.51081,0,0,1,9.5,9.5v43A9.51081,9.51081,0,0,1,377.47441,438.93324Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#fff"
    />
    <path
      d="M377.47441,438.93324h-43a9.51081,9.51081,0,0,1-9.5-9.5v-43a9.51081,9.51081,0,0,1,9.5-9.5h43a9.51081,9.51081,0,0,1,9.5,9.5v43A9.51081,9.51081,0,0,1,377.47441,438.93324Zm-43-60a7.50836,7.50836,0,0,0-7.5,7.5v43a7.50836,7.50836,0,0,0,7.5,7.5h43a7.50836,7.50836,0,0,0,7.5-7.5v-43a7.50836,7.50836,0,0,0-7.5-7.5Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#3f3d56"
    />
    <path
      d="M369.97441,421.43324h-28a3.00328,3.00328,0,0,1-3-3v-21a3.00328,3.00328,0,0,1,3-3h7.88232a2.98117,2.98117,0,0,1,2.55518,1.42724l2.19824,3.57276h15.36426a3.00328,3.00328,0,0,1,3,3v16A3.00328,3.00328,0,0,1,369.97441,421.43324Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#f50057"
    />
    <path
      d="M369.06364,432.04239q.20986.38415.38766.79029a10.38021,10.38021,0,0,1-6.39269,14.10854,9.72022,9.72022,0,0,1-.98807.24953l-19.09943,49.40482a8.06134,8.06134,0,0,1-8.16324,5.09887l-.096-.01816-41.39664-12.46211a8.89853,8.89853,0,1,1,5.28606-16.99313l29.29033,9.40382,22.6764-40.20175a9.87622,9.87622,0,0,1-.51127-1.28942,10.38005,10.38005,0,0,1,19.0069-8.0913Z"
      transform="translate(-180.86417 -106.46046)"
      fill="#a0616a"
    />
  </svg>
</template>

<script>
export default {
  name: "iCulture",
  props: [],
  methods: {},
  data: () => {
    return {};
  }
};
</script>

<style scoped>
.byourplanIllus {
  width: 100%;
  height: auto;
}
</style>
