<template>
  <svg
    class="byourplanIllus"
    xmlns="http://www.w3.org/2000/svg"
    id="bab7d7d3-5265-4add-b9d0-a4ec8bbdc3a5"
    data-name="Layer 1"
    width="965.82539"
    height="729.2137"
    viewBox="0 0 965.82539 729.2137"
  >
    <path
      d="M562.48086,240.95789H324.22375a4.41735,4.41735,0,0,1-4.41216-4.41216V177.44432a4.41735,4.41735,0,0,1,4.41216-4.41216H562.48086a4.41735,4.41735,0,0,1,4.41216,4.41216v59.10141A4.41735,4.41735,0,0,1,562.48086,240.95789ZM324.22375,174.797a2.65019,2.65019,0,0,0-2.64729,2.6473v59.10141a2.65019,2.65019,0,0,0,2.64729,2.64729H562.48086a2.65019,2.65019,0,0,0,2.64729-2.64729V177.44432a2.65019,2.65019,0,0,0-2.64729-2.6473Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#3f3d56"
    />
    <circle cx="244.15676" cy="119.33414" r="17.97281" fill="#f50057" />
    <path
      d="M402.75269,192.74542a2.99547,2.99547,0,0,0,0,5.99094H543.96762a2.99547,2.99547,0,0,0,0-5.99094Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#e6e6e6"
    />
    <path
      d="M402.75269,210.71823a2.99546,2.99546,0,1,0,0,5.99093H463.5179a2.99547,2.99547,0,1,0,0-5.99093Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#e6e6e6"
    />
    <path
      d="M459.044,451.6273H353.15171a4.41735,4.41735,0,0,1-4.41216-4.41216V336.93267a4.41735,4.41735,0,0,1,4.41216-4.41216H459.044a4.41735,4.41735,0,0,1,4.41216,4.41216V447.21514A4.41735,4.41735,0,0,1,459.044,451.6273ZM353.15171,334.28538a2.65019,2.65019,0,0,0-2.6473,2.64729V447.21514a2.65019,2.65019,0,0,0,2.6473,2.6473H459.044a2.65019,2.65019,0,0,0,2.6473-2.6473V336.93267a2.65019,2.65019,0,0,0-2.6473-2.64729Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#3f3d56"
    />
    <path
      d="M375.71524,355.26276a2.99547,2.99547,0,0,0,0,5.99094h60.76521a2.99547,2.99547,0,0,0,0-5.99094Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#e6e6e6"
    />
    <path
      d="M375.71524,372.91141a2.99547,2.99547,0,1,0,0,5.99093h60.76521a2.99546,2.99546,0,1,0,0-5.99093Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#e6e6e6"
    />
    <path
      d="M406.09806,428.88505A18.85509,18.85509,0,1,1,424.95316,410.03,18.87645,18.87645,0,0,1,406.09806,428.88505Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#f50057"
    />
    <path
      d="M632.51307,85.39315H738.40536a4.41735,4.41735,0,0,1,4.41216,4.41216V200.08778a4.41735,4.41735,0,0,1-4.41216,4.41216H632.51307a4.41735,4.41735,0,0,1-4.41216-4.41216V89.80531A4.41735,4.41735,0,0,1,632.51307,85.39315ZM738.40536,202.73508a2.65019,2.65019,0,0,0,2.64729-2.6473V89.80531a2.65019,2.65019,0,0,0-2.64729-2.6473H632.51307a2.65019,2.65019,0,0,0-2.64729,2.6473V200.08778a2.65019,2.65019,0,0,0,2.64729,2.6473Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#3f3d56"
    />
    <path
      d="M715.84182,181.75769a2.99547,2.99547,0,1,0,0-5.99094H655.07661a2.99547,2.99547,0,1,0,0,5.99094Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#e6e6e6"
    />
    <path
      d="M715.84182,164.10905a2.99547,2.99547,0,1,0,0-5.99094H655.07661a2.99547,2.99547,0,1,0,0,5.99094Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#e6e6e6"
    />
    <path
      d="M685.459,108.1354a18.8551,18.8551,0,1,1-18.85509,18.85509A18.87645,18.87645,0,0,1,685.459,108.1354Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#f50057"
    />
    <path
      d="M321.1723,313.55063a150.9528,150.9528,0,0,1,63.905-28.88887,148.76987,148.76987,0,0,1,70.5276,3.624,150.459,150.459,0,0,1,32.91242,14.261c10.559,6.17257,20.09991,13.77112,29.27982,21.81643,9.29936,8.15,18.29306,16.66283,28.00089,24.33558a156.87141,156.87141,0,0,0,97.60424,34.33533q4.39683.007,8.78748-.24217c1.58687-.08737,1.59706-2.56747,0-2.47954a154.34273,154.34273,0,0,1-100.89587-30.48842c-10.06169-7.52377-19.24871-16.1264-28.6278-24.46267-9.09313-8.0821-18.42973-15.944-28.74617-22.43723a151.85822,151.85822,0,0,0-167.66171,3.82159c-2.32795,1.61866-4.60186,3.31029-6.83919,5.05171-1.25779.979.50825,2.72239,1.7533,1.7533Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#3f3d56"
    />
    <circle cx="202.7686" cy="227.25672" r="12.88579" fill="#3f3d56" />
    <circle cx="682.14609" cy="398.34489" r="12.88579" fill="#3f3d56" />
    <path
      d="M866.73905,410.33439H656.482a4.41735,4.41735,0,0,1-4.41216-4.41216v-109.4A4.41735,4.41735,0,0,1,656.482,292.11h210.2571a4.41735,4.41735,0,0,1,4.41216,4.41216v109.4A4.41735,4.41735,0,0,1,866.73905,410.33439ZM656.482,293.8749a2.65019,2.65019,0,0,0-2.6473,2.64729v109.4a2.6502,2.6502,0,0,0,2.6473,2.6473h210.2571a2.6502,2.6502,0,0,0,2.6473-2.6473v-109.4a2.65019,2.65019,0,0,0-2.6473-2.64729Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#3f3d56"
    />
    <circle cx="574.65009" cy="245.47147" r="17.97281" fill="#f50057" />
    <path
      d="M733.246,318.88275a2.99547,2.99547,0,1,0,0,5.99093H846.461a2.99546,2.99546,0,1,0,0-5.99093Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#e6e6e6"
    />
    <path
      d="M733.246,336.85556a2.99547,2.99547,0,1,0,0,5.99093h60.76521a2.99546,2.99546,0,1,0,0-5.99093Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#e6e6e6"
    />
    <path
      d="M677.95176,365.58887c-2.27858,0-4.13282,1.34394-4.13282,2.99546s1.85424,2.99547,4.13282,2.99547H844.78493c2.27858,0,4.13282-1.34395,4.13282-2.99547s-1.85424-2.99546-4.13282-2.99546Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#e6e6e6"
    />
    <path
      d="M677.95176,383.56167c-2.27858,0-4.13282,1.344-4.13282,2.99547s1.85424,2.99547,4.13282,2.99547H844.78493c2.27858,0,4.13282-1.344,4.13282-2.99547s-1.85424-2.99547-4.13282-2.99547Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#e6e6e6"
    />
    <path
      d="M639.58045,495.73439c11.86184-5.14564,25.10541-.65952,36.28582,4.08316,5.7582,2.4426,11.45469,5.04977,17.41372,6.9785a99.25973,99.25973,0,0,0,15.3534,3.63845,108.49819,108.49819,0,0,0,31.5394.193,110.12369,110.12369,0,0,0,30.43734-8.85043,102.92059,102.92059,0,0,0,26.34241-17.2226q1.49733-1.35236,2.93632-2.76689c1.14171-1.11858-.61209-2.87139-1.7533-1.75329a99.99816,99.99816,0,0,1-25.69784,18.1408,107.64942,107.64942,0,0,1-29.85683,9.59155,105.43839,105.43839,0,0,1-31.46184.54312,99.05014,99.05014,0,0,1-15.40456-3.34883,162.96293,162.96293,0,0,1-17.00233-6.60322c-11.28483-4.84639-23.823-9.59207-36.22293-6.24973a30.21236,30.21236,0,0,0-4.16025,1.48544c-1.46008.63338-.199,2.77021,1.25147,2.141Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#3f3d56"
    />
    <path
      d="M634.99753,526.14562H535.66144l23.37712-40.49105a4.77368,4.77368,0,0,1,8.2685,0l9.98639,17.29705L594.2738,473.541a5.97673,5.97673,0,0,1,10.35221,0Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#f50057"
    />
    <path
      d="M635.09124,526.13485H529.199a4.41735,4.41735,0,0,1-4.41216-4.41216V462.62128a4.41735,4.41735,0,0,1,4.41216-4.41216H635.09124a4.41735,4.41735,0,0,1,4.41216,4.41216v59.10141A4.41735,4.41735,0,0,1,635.09124,526.13485ZM529.199,459.974a2.65019,2.65019,0,0,0-2.6473,2.64729v59.10141a2.65019,2.65019,0,0,0,2.6473,2.6473H635.09124a2.65019,2.65019,0,0,0,2.6473-2.6473V462.62128a2.65019,2.65019,0,0,0-2.6473-2.64729Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#3f3d56"
    />
    <path
      d="M842.78535,474.11958l-18.87517,9.5846c-6.737,3.421-13.23861,2.83636-15.72849-4.29741v0A12.48085,12.48085,0,0,1,817.675,463.025l17.939-3.3489,76.8295-25.49012,46.53995-33.00078,17.06067,27.18086L922.268,457.38374Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#ffb8b8"
    />
    <polygon
      points="813.808 706.872 799.366 704.465 800.971 677.187 816.215 677.187 813.808 706.872"
      fill="#ffb8b8"
    />
    <polygon
      points="953.968 680.229 939.992 684.589 929.131 659.516 942.738 652.646 953.968 680.229"
      fill="#ffb8b8"
    />
    <path
      d="M1063.27244,741.72054l-17.65033,7.22059L961.38188,638.2254,934.90638,768.196l-17.65033-3.20915C905.93362,652.935,896.701,538.643,908.02974,484.58727l65.38646-2.808,29.68465,94.67,14.44118,64.183Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#2f2e41"
    />
    <path
      d="M930.10041,814.32555h0a12.67185,12.67185,0,0,1-7.85521-.83484l-8.57872-3.86042c-7.19328-.36934-12.09123,1.06343-16.09631-4.163a10.49627,10.49627,0,0,1-1.22539-10.5816h0a10.56885,10.56885,0,0,1,8.63109-6.28021l11.783-7.42484.61847,5.56623c5.38534,3.05094,11.77909,1.733,18.5541-1.23694l3.69822,12.94376A12.67187,12.67187,0,0,1,930.10041,814.32555Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#2f2e41"
    />
    <path
      d="M1076.77382,787.77421h0a12.67184,12.67184,0,0,1-7.70062,1.76121l-9.3674-.86547c-6.92339,1.98652-11.09065,4.93215-16.57585,1.28954a10.49625,10.49625,0,0,1-4.5951-9.61024h0a10.56886,10.56886,0,0,1,6.124-8.74262l8.73335-10.84871,2.39246,5.06374c6.08422,1.13684,11.70352-2.18587,17.14694-7.19492l7.701,11.04139A12.67187,12.67187,0,0,1,1076.77382,787.77421Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#2f2e41"
    />
    <circle cx="832.26026" cy="253.57887" r="28.88236" fill="#ffb8b8" />
    <polygon
      points="867.561 314.553 829.853 306.53 825.04 275.241 851.515 269.625 867.561 314.553"
      fill="#ffb8b8"
    />
    <path
      d="M974.21849,485.7907H906.8263L922.01669,447.653c-1.10383-13.12273,4.45793-22.77459,12.41823-31.17762l10.09892-25.35467c16.31743-10.73928,28.61911-9.937,41.719,4.01144C1001.7845,417.12279,991.03363,450.18,974.21849,485.7907Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#f50057"
    />
    <path
      d="M943.73155,566.82178l-1.91658,21.0823c-.68407,7.52478-4.63563,12.72072-12.00148,11.0371h0a12.48085,12.48085,0,0,1-8.82809-16.74961l6.70041-16.97436L946.9407,486.593l-3.20915-56.96244h32.09151l-4.01144,60.97388Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#ffb8b8"
    />
    <path
      d="M978.22993,431.23513H941.32468l2.58722-24.07477a16.70084,16.70084,0,0,1,15.85293-15.99889h0a16.70082,16.70082,0,0,1,17.522,16.02556Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#f50057"
    />
    <circle cx="868.00636" cy="213.02522" r="10.85611" fill="#2f2e41" />
    <path
      d="M973.90739,295.76063a10.84756,10.84756,0,0,1,10.851-10.14657c-.22417-.02844-.44633-.063-.67491-.07771a10.85611,10.85611,0,0,0-1.39307,21.66748c.22858.0147.45334.00885.67931.00936A10.84757,10.84757,0,0,1,973.90739,295.76063Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#2f2e41"
    />
    <path
      d="M973.84613,300.39648c-9.68186-8.03176-37.45214,2.17626-39.24414,7.57611-13.09266,1.78427-23.00541,9.423-23.00541,23.00534v.00008c7.784,3.1888,16.63679,2.79583,26.20662,0,15.25511,12.432,16.30088,28.06039,7.02342,46.01083l11.41378.22825,14.1478-20.67751.67656,20.974,26.16309-.52476V346.93175a62.84141,62.84141,0,0,0-6.70965-28.79C986.44114,310.22094,980.114,305.596,973.84613,300.39648Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#2f2e41"
    />
    <ellipse cx="832.39577" cy="260.83952" rx="5.19142" ry="7.26799" fill="#ffb8b8" />
    <path
      d="M296.282,355.45552s3.125-54.0902,19.34846-41.79951-7.085,46.8158-7.085,46.8158Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#ffb9b9"
    />
    <path
      d="M235.92345,515.90013s36.03786,40.45724,15.87529,43.23814-28.09392-38.11362-28.09392-38.11362Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#ffb9b9"
    />
    <path
      d="M188.8971,324.39514h0A23.73388,23.73388,0,0,1,206.36,344.04548l11.46324,81.59129,16.0701,65.08391s8.035,7.23154,4.821,11.24907,4.01752,20.89113,4.01752,20.89113l-20.08762,6.428s-2.41052-16.87361-6.428-17.67711c-3.53071-.70614-43.05458-106.289-52.58144-131.86685-1.3136-3.52679-2.0569-5.53251-2.0569-5.53251S163.18494,320.37762,188.8971,324.39514Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#575a89"
    />
    <path
      d="M192.56618,749.88472l12.33837,21.86516c9.55483,20.39953,1.064,24.1051-22.3633,13.99322l-5.3402-25.818Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#ffb9b9"
    />
    <path
      d="M198.79161,778.94482s5.85718-9.53613,7.97566-7.93981,10.85643,8.51271,10.85643,8.51271L229.00529,790.419s38.45336,4.06549,14.01922,17.53864-37.93722,2.30193-37.93722,2.30193-34.2224,1.77972-33.67037-7.76833,11.589-24.65358,11.589-24.65358S196.91624,787.16361,198.79161,778.94482Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#2f2e41"
    />
    <path
      d="M137.81474,502.73183,149.382,642.69519s.38557,83.66956,12.72394,99.86367S174.82984,766.85,174.82984,766.85l25.44788-13.10952s-13.10951-21.84437-10.41049-25.18844a18.63842,18.63842,0,0,0,3.47016-7.19977l-6.94033-86.36858,13.10952-150.37385Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#2f2e41"
    />
    <path
      d="M155.55114,766.85"
      transform="translate(-117.0873 -85.39315)"
      fill="none"
      stroke="#3f3d56"
      stroke-miterlimit="10"
    />
    <polygon
      points="52.344 664.492 41.548 693.024 17.064 682.788 29.981 661.407 52.344 664.492"
      fill="#ffb9b9"
    />
    <path
      d="M151.42068,768.807s9.18277-5.73923,10.33062-3.44353,5.73923,12.05238,5.73923,12.05238l5.1653,14.34805s31.56575,20.08728,4.59138,21.23511-33.86143-14.348-33.86143-14.348S112.9679,785.4507,117.55931,777.4158s20.66121-16.36828,20.66121-16.36828S146.2554,775.1201,151.42068,768.807Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#2f2e41"
    />
    <path
      d="M235.75053,503.11741l6.16919,128.78171s-56.29381,116.44335-62.463,119.52794a15.48767,15.48767,0,0,0-7.71148,9.25378l-33.93051-8.48263,15.423-24.67674,33.93051-82.51283s5.398-2.31345,6.94033-10.02493,4.62689-7.71148-3.08459-9.25377-7.71148-9.25378-7.71148-9.25378L157.86458,499.26167Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#2f2e41"
    />
    <circle
      cx="207.9892"
      cy="270.23071"
      r="30.84592"
      transform="translate(-209.15563 346.85493) rotate(-80.78252)"
      fill="#ffb9b9"
    />
    <path
      d="M180.61345,272.15858s-2.31344,39.32855-5.398,43.18429,32.38822,3.08459,32.38822,3.08459-2.31344-33.15936,0-36.24395S180.61345,272.15858,180.61345,272.15858Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#ffb9b9"
    />
    <path
      d="M238.06118,511.601s-87.13995-7.71-103.32995,0c0,0,5.96-22.11,4.42-24.42-1.12-1.69,1.97-64.67,1-101-.55-20.5-5.28-40.52,8.47-55.74,4.68-5.17,9.9-9.51,15.41-11.63,0,0-1.54-14.65,6.17-15.42,0,0,29.31-3.09,40.1,6.17,3.45,2.94,5.62,11.9,5.85,19.62-5.34,26.26-3.45,55.4,1.45,85.63.3,1.92.63,3.85.96,5.78C223.76119,450.571,231.62118,481.481,238.06118,511.601Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#575a89"
    />
    <path
      d="M191.87478,279.3199c2.60865-.15566,5.15581.72594,7.74636,1.06987,9.26825,1.23048,18.91009-5.495,20.9565-14.61788a11.216,11.216,0,0,1,1.07186-3.49235c1.42642-2.32887,4.675-2.77291,7.353-2.23739s5.2165,1.78889,7.94378,1.932c4.211.22094,8.25369-2.37149,10.49785-5.94146a20.148,20.148,0,0,0,2.57662-12.16866l-2.01871,2.11872a10.25355,10.25355,0,0,1-.91406-5.57268,6.46661,6.46661,0,0,0-6.12523,1.56732c-1.78.18944-.43659-3.30164-1.759-4.50813a3.0592,3.0592,0,0,0-2.15226-.40265c-3.78679.03651-6.85011-2.85989-9.83416-5.19151a41.78926,41.78926,0,0,0-17.64976-8.05075c-4.276-.834-8.823-.968-12.87822.62424a27.50975,27.50975,0,0,0-8.69688,6.16918c-6.34882,6.10483-11.97077,13.27777-14.612,21.68017a36.4967,36.4967,0,0,0-.17481,21.16306c1.06428,3.59207,4.27174,15.894,8.96737,16.18043C188.07322,290.00142,184.5308,279.75806,191.87478,279.3199Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#2f2e41"
    />
    <path
      d="M204.64256,328.18108l11.05113,1.18389s10.84819,21.04649,10.06038,25.43646-2.50345,1.72473,2.37918,8.7483,18.73758,25.1781,20.45328,27.84325,48.48864-33.02154,45.58076-43.71392c0,0,14.77228,10.59758,21.101,12.00818,0,0-48.20327,63.21789-54.92055,71.30829s-12.1235,6.42885-21.35485,1.63192-55.93257-52.644-55.93257-52.644Z"
      transform="translate(-117.0873 -85.39315)"
      fill="#575a89"
    />
    <path
      d="M218.56118,420.59094l-38.41-38.41,9.72-3.17,27.73,35.8C217.90121,416.731,218.23123,418.661,218.56118,420.59094Z"
      transform="translate(-117.0873 -85.39315)"
      opacity="0.1"
      style="isolation:isolate"
    />
  </svg>
</template>

<script>
export default {
  name: "iParticipate",
  props: [],
  methods: {},
  data: () => {
    return {};
  }
};
</script>

<style scoped>
.byourplanIllus {
  width: 75%;
  height: 125px;
}
</style>
