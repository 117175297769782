<template>
  <div>
    <div class="navBar">
      <div>
        <b-navbar
          :class="
            this.$route.name !== 'home' && this.$route.name !== 'Profile'
              ? 'nBMenuSlim2'
              : 'nBMenuSlim'
          "
          toggleable="xl"
          type="dark"
          small
        >
          <div id="navBtns">
            <vs-button
              :class="
                this.$route.name !== 'home' && !this.$route.name !== 'Profile'
                  ? 'navBarBtn2'
                  : 'navBarBtn'
              "
              floating
              :to="this.$route.name === 'home' ? null : '/'"
              :color="
                this.$route.name !== 'home' && !this.$route.name !== 'Profile' ? '#f83255' : '#FFF'
              "
              size="large"
            >
              {{
                this.$route.name !== "home" && !this.$route.name !== "Profile" ? "BYP" : "BYourPlan"
              }}
              <template #animate>
                <i class="fa fa-home"></i>
              </template>
            </vs-button>
          </div>

          <div class="avataname">
            <vs-button transparent color="danger" v-if="this.$route.name !== 'search'">
              <i class="fas fa-bell"></i>
            </vs-button>
            <vs-tooltip bottom>
              <vs-button transparent color="danger" v-if="this.$route.name === 'Profile'">
                <i class="fas fa-ellipsis-v" />
              </vs-button>
              <template #tooltip>
                <div @click="showUserSettings" class="menuItems">
                  <i class="fas fa-user-cog" /> &nbsp; Ajustes de perfil
                </div>
                <div @click="onOpenSettings" class="menuItems">
                  <i class="fas fa-cog" /> &nbsp; Configuración
                </div>
                <div class="menuItems" style="color:red" @click="logout">
                  <i class="fas fa-power-off" /> &nbsp; Cerrar sessión
                </div>
              </template>
            </vs-tooltip>
            <vs-avatar
              style="z-index:10000;"
              size="50"
              history
              circle
              history-gradient
              badge-color="success"
              v-if="username && this.$route.name !== 'Profile'"
            >
              <img
                id="myAvatar"
                v-if="profileImg"
                :src="profileImg"
                fluid
                alt="Avatar image"
                @click="
                  () => {
                    this.toggleMenu();
                    this.$router.push({
                      path: '/profile',
                      query: {
                        t: new Date().getTime()
                      }
                    });
                  }
                "
              />
              <iMan
                v-else
                @click="
                  () => {
                    this.toggleMenu();
                    this.$router.push({
                      path: '/profile',
                      query: {
                        t: new Date().getTime()
                      }
                    });
                  }
                "
              />
              <!-- @click="() => this.$store.commit('showNotificationSB', true)"
                /> -->
              <template v-if="notifications.length > 0" #badge>
                {{ notifications.length }}
              </template>
            </vs-avatar>
            <vs-button
              v-if="!username"
              @click="
                () => {
                  this.toggleMenu();
                  if (username) {
                    this.$store.commit('showNotificationSB', true);
                  } else {
                    this.$router.push('/Login');
                  }
                }
              "
              class="navBarBtnLogin"
              color="#f5f5f5"
              size="large"
            >
              {{ !username ? "Login" : username }}
              <template #animate>
                <i class="fas fa-user"></i>
              </template>
            </vs-button>
          </div>
        </b-navbar>
      </div>
    </div>
  </div>
</template>

<script>
import { imageMix, followMix, chatMix, eventMix, authMix } from "@/common/mixin.js";
import axios from "axios";
import { mapState } from "vuex";
import { iMan } from "@/components/ILLUSTRATIONS/";

export default {
  name: "NavBar2",
  mixins: [imageMix, followMix, chatMix, eventMix, authMix],
  props: ["notifications", "wishList"],
  components: {
    iMan
  },
  computed: mapState(["showNotificationSB", "currentUser"]),
  watch: {
    currentUser(newValue, oldValue) {
      if (newValue) {
        if (newValue.profileImg) {
          this.profileImg = newValue ? newValue.profileImg : this.profileImg;
        }
        this.username = newValue.username;
      }
      this.$store.commit("showNotificationSB", false);
    }
  },
  data() {
    const { currentUser } = this.$store.state;
    return {
      username: currentUser.username || null,
      myUser: currentUser,
      filterName: "",
      profileImg: null,
      activeSidebar: false,
      active: "home",
      assistants: [],
      users: [],
      events: [],
      filteredFollowers: [],
      filteredEvents: [],
      activeTooltip1: false,
      searchTooltip: false
    };
  },
  methods: {
    toggleMenu() {
      // document.getElementById("toggleMenu").click();
    },

    showUserSettings() {
      this.$store.commit("showUserSettingsSB", true);
    },

    onOpenSettings() {
      this.$store.commit("showAccountSettingsSB", true);
    },

    async logout() {
      this.firebaseLogout();
      this.$store.commit("setUser", {});
      localStorage.removeItem("user");
      localStorage.setItem("privacity", true);
      this.$router.push("/");
    },

    goEvent(event) {
      this.$router.push(`/event/${event.id}`);
    },

    goProfile(user) {
      this.$router
        .push({
          name: "Profile",
          params: { creator: user },
          query: {
            t: new Date().getTime()
          }
        })
        .catch(error => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    },

    filterUser() {
      let arrayUser = this.users;
      if (this.filterName !== "") {
        arrayUser = arrayUser.filter(user => {
          return user.username.toLocaleLowerCase().includes(this.filterName.toLocaleLowerCase());
        });
      }
      this.filteredFollowers = arrayUser;

      let arrayEvent = this.events;
      if (this.filterName !== "") {
        arrayEvent = arrayEvent.filter(event => {
          return event.title.toLocaleLowerCase().includes(this.filterName.toLocaleLowerCase());
        });
      }
      this.filteredEvents = arrayEvent;
    },

    async getAssistants(event_id, event) {
      const body = { params: { event_id } };
      const apiClient = axios.create({
        baseURL: !window.location.host.includes("localhost")
          ? "https://api.bmyplan.com"
          : "http://localhost:8087" //
      });
      await apiClient
        .get("/participation", body)
        .then(response => {
          this.parseImages(response.data, true);
          this.assistants = response.data;
          this.$store.commit("setEvent", {
            ...event,
            assistants: this.assistants,
            private: false
          });
          this.$router.push({ name: "Event" });
        })
        .catch(error => {
          console.error(error);
        });
    },

    async getProfiles() {
      this.active3 = true;
      this.events = await this.getEvents();
      this.users = await this.getUsers();
      this.filteredEvents = this.events;
      this.filteredFollowers = this.users;
    },

    async onUnfollow(followed) {
      const follower = localStorage.getItem("user");
      try {
        await this.unfollow(followed, follower).then(response => {
          // this.isFollowed = false;
        });
      } catch (error) {
        console.error(error);
      }
    },
    async onFollow(followed) {
      const follower = localStorage.getItem("user");
      try {
        await this.follow(followed, follower).then(response => {
          // this.isFollowed = true;
        });
      } catch (error) {
        console.error(error);
      }
    },

    onOpenChat(destiny, profileImg) {
      this.searchTooltip = false;
      this.openChat(destiny, this.$store.state.currentUser.nick, profileImg);
    }
  },
  mounted() {
    const { currentUser } = this.$store.state;
    this.profileImg = currentUser ? currentUser.profileImg : this.profileImg;
    this.getProfiles();
  }
};
</script>

<style scoped>
@media (min-width: 992px) {
  .nBMenuSlim {
    max-height: 30px;
  }
}

@media (max-width: 991px) {
  .nBMenuSlim {
    padding: 0;
  }
}

.menuItems {
  text-align: start;
  padding: 0.5rem 1rem;
}

.avataname {
  display: flex;
}

.nBMenuSlim > .navBarBtn > .vs-button__content {
  color: var(--main-byp);
}
.nBMenuSlim2 > .navBarBtn > .vs-button__content {
  color: var(--main-byp-2);
}

.nBMenuSlim > .avataname > .navBarBtnLogin {
  color: var(--main-byp);
}
.nBMenuSlim2 > .avataname > .navBarBtnLogin {
  background-color: var(--main-byp);
}
.nBMenuSlim2 > .avataname > .navBarBtnLogin {
  color: var(--main-byp-2);
}

.nBMenuSlim {
  color: var(--main-byp);
  background-color: var(--main-byp-2);
  justify-content: space-between;
}

.nBMenuSlim2 {
  color: var(--main-byp-2);
  background-color: var(--main-byp);
  justify-content: space-between;
  padding: 0 0.25rem;
}

#myAvatar {
  height: 90%;
  width: 90%;
}

#langDropdown {
  margin-top: 0.75rem;
}

#navBtns {
  display: flex;
}

#navBtns > .vs-button > .vs-button--icon {
  color: var(--main-byp);
}

.wishEvents {
  display: flex;
  justify-content: space-evenly;
}

.navBar {
  z-index: 10;
}

.navBarBtn2 {
  padding: 0 0.75rem;
}
.navBarBtn {
  margin: 0;
}

.userListItem,
.userAB {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;
}

.userAvatars {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.con-content {
  margin-bottom: 1rem;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>

<style>
.nav-link.active {
  text-decoration: underline red;
}

.navBarBtn2 > .vs-button__content {
  font-weight: bolder;
  font-size: x-large;
  padding: 0 !important;
}
</style>
