<template>
  <div id="app" data-app>
    <NavBar2 :notifications="this.notifications" :wishList="this.wishList" />
    <router-view />
    <notificationSB :notifications="this.notifications" />
    <privacity />
    <ChatSideBar />
    <ChatListSB />
    <GlobalSettingsSB />
    <footer v-if="this.$route.name === 'home'" class="footer">
      ©ByourPlan
    </footer>
  </div>
</template>

<script>
import { imageMix, authMix } from "@/common/mixin.js";
// import NavBar from "./components/HEADERS/NavBar.vue";
import NavBar2 from "./components/HEADERS/SearchNavBar";
import notificationSB from "./components/SIDEBARS/NotificationsSB";
import ChatSideBar from "@/components/SIDEBARS/ChatSB.vue";
import ChatListSB from "@/components/SIDEBARS/ChatListSB.vue";
import GlobalSettingsSB from "@/components/SIDEBARS/GlobalSettingsSB.vue";
import privacity from "./views/Privacity";

export default {
  name: "App",
  mixins: [imageMix, authMix],
  components: {
    NavBar2, // cambiar el 2
    notificationSB,
    ChatListSB,
    ChatSideBar,
    GlobalSettingsSB,
    privacity
  },
  methods: {
    async initApp() {
      const user = await this.firebaseAuth(this.$store);
      if (user) {
        // console.log(user);
      } else {
        const currentUser = await this.firebaseCurrentUser();
        if (currentUser) {
          const user = await this.firebaseGetUser(currentUser.uid);
          user.id = currentUser.uid;
          this.$store.commit("setUser", user);
        }
      }

      // const subscriber = JSON.parse(localStorage.getItem("subscriber")) || null,
      //   apiClient = axios.create({
      //     baseURL: !window.location.host.includes("localhost")
      //       ? "https://api.bmyplan.com"
      //       : "http://localhost:8087" //
      //   });
      // if (subscriber && user) {
      //   const body = {
      //     params: { oldUsername: user, subscriber: localStorage.getItem("subscriber") }
      //   };
      //   await apiClient
      //     .put("/profile", body)
      //     .then(response => {
      //       this.$store.commit("setUser", this.parseImg(response.data));
      //       const currentUser = this.$store.state.currentUser,
      //         newUser = {
      //           user: `User-${currentUser.nick}`,
      //           avatar: currentUser.ProfileImg
      //         };
      //       this.$socket.emit("newUser", newUser);
      //     })
      //     .catch(error => {
      //       console.error(error);
      //     });
      // } else if (user) {
      //   const body = { params: { username: user } };
      //   await apiClient
      //     .get("/profile", body)
      //     .then(response => {
      //       this.$store.commit("setUser", this.parseImg(response.data));
      //       const currentUser = this.$store.state.currentUser,
      //         newUser = {
      //           user: `User-${currentUser.nick}`,
      //           avatar: currentUser.ProfileImg
      //         };
      //       this.$socket.emit("newUser", newUser);
      //     })
      //     .catch(error => {
      //       console.error(error);
      //     });
      // }

      // if (user) {
      //   const body = { params: { username: user } };
      //   await apiClient
      //     .get("/notifications", body)
      //     .then(response => {
      //       this.notifications = response.data;
      //       navigator.setAppBadge(this.notifications.length).catch(error => {});
      //     })
      //     .catch(error => {
      //       console.error(error);
      //     });

      //   const body2 = { params: { username: user } };
      //   await apiClient
      //     .get("/wishlist", body2)
      //     .then(response => {
      //       response.data.forEach(copyEntity => {
      //         const paso1 = new Uint8Array(copyEntity.image.data);
      //         const paso2 = paso1.reduce((data, byte) => data + String.fromCharCode(byte), "");
      //         copyEntity["Image"] = paso2;
      //       });
      //       this.wishList = response.data;
      //     })
      //     .catch(error => {
      //       console.error(error);
      //     });
      // }
    }
  },
  watch: {},
  data() {
    return {
      notifications: [],
      wishList: []
    };
  },
  // sockets: {
  //   connect: function() {
  //     console.log("socket connected");
  //   }
  // },
  created() {
    this.initApp();
  }
};
</script>

<style>
html {
  scroll-behavior: smooth;
  overflow: auto;
}

:root {
  --main-bg: #f5f5f5;
  --main-byp: #f83255;
  --main-byp-2: #f5f5f5;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(13deg, #e8fbff 31%, #dc3545 55%);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(13deg, #01242b 31%, #dc3545 55%);
}
::-webkit-scrollbar-track {
  background: #dc354600;
  border-radius: 7px;
  box-shadow: inset 7px 4px 12px #ff00aa00;
  z-index: -1;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px !important;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.footer {
  background-color: #dc3545;
  bottom: 0px;
  /* position: fixed; */
  width: 100%;
  border-radius: 1rem 1rem 0 0;
  z-index: 1;
  box-shadow: 20px -8px 20px -6px #dc3545;
  color: var(--main-bg);
}

/* // Extra small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap */
@media (max-width: 575px) {
}
/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
</style>
