<template>
  <svg
    class="byourplanIllus"
    xmlns="http://www.w3.org/2000/svg"
    id="a816e8d7-4997-4ebd-a7b7-7af2120ae04f"
    data-name="Layer 1"
    width="940.5"
    height="744.20516"
    viewBox="0 0 940.5 744.20516"
  >
    <title>BYourPlan Culture</title>
    <ellipse cx="399" cy="334.04865" rx="399" ry="296.15513" fill="#f2f2f2" />
    <ellipse
      cx="431.5"
      cy="296.65513"
      rx="399"
      ry="296.15513"
      fill="none"
      stroke="#3f3d56"
      stroke-miterlimit="10"
    />
    <path
      d="M292.35025,246.78294a54.53131,54.53131,0,0,1,36.24063,6.17093c5.6964,3.23056,11.07526,7.93046,12.71754,14.26989s-1.99841,14.25147-8.476,15.21372c-9.552,1.41895-14.469-13.54271-8.02522-20.73516s17.76364-7.256,27.07047-4.67982c16.95842,4.69414,31.2031,15.88935,46.15124,25.17245,73.90726,45.89791,166.94766,45.7488,253.47722,36.719,8.87942-.92661,18.353-2.1858,25.07253-8.06377s8.53011-18.08124,1.23255-23.224c-7.16311-5.04805-18.28223,1.00036-19.951,9.60317s4.112,17.3318,11.77384,21.585,16.77607,4.90418,25.53866,4.80421c37.38327-.42654,73.93275-12.85073,106.49512-31.2187,10.99633-6.20287,22.42844-14.14887,25.82933-26.30736S820.52605,238.171,808.332,241.44225c-7.96073,2.13558-12.3472,10.97669-12.98423,19.19424a43.00668,43.00668,0,0,0,3.16622,19.25039c4.29013,10.67064,12.096,19.46328,19.17215,28.52945a259.966,259.966,0,0,1,53.95521,174.50934c-1.62646,25.774-10.69487,55.5635-35.1453,63.87746-9.97935,3.39331-20.9158,2.45474-31.244.34991-8.60636-1.75393-18.76921-6.32492-19.12285-15.10106-.24868-6.1716,5.04757-11.46169,10.94109-13.3102,14.9483-4.68855,31.10812,10.22675,30.897,25.89167s-13.12319,29.1011-28.019,33.95391-31.21019,2.6192-46.25939-1.73462c-48.97521-14.16881-89.81485-49.48934-138.94821-63.09963-57.78822-16.00775-120.05068.1215-173.91479,26.47268"
      transform="translate(-129.75 -77.64742)"
      fill="none"
      stroke="#2f2e41"
      stroke-miterlimit="10"
    />
    <rect x="408.88513" y="163.38797" width="67.92295" height="58.85084" fill="#ff6584" />
    <rect x="408.94567" y="163.57676" width="67.92295" height="58.85084" opacity="0.1" />
    <path
      d="M503.17015,432.97542l-9.24453,75.47173s-12.73017,36.37191,12.124,42.58545,1.97015-49.55674,1.97015-49.55674l9.54762-69.25819Z"
      transform="translate(-129.75 -77.64742)"
      fill="#ffc1c7"
    />
    <path
      d="M525.5995,365.83893s-5.75889,71.37988-6.51664,71.37988c-.57741,0-5.37091.3516-11.17072.65318-1.81859.094-3.7342.18337-5.65129.25612-8.03213.3031-1.5155-11.06312-1.06085-11.82087s-1.667-7.729-5.15269-13.48792,1.2124-1.8186,4.698-6.36509.3031-2.42479-4.09184-6.062,2.57635-4.84959,6.21354-6.36509-.15155-1.97014-4.24339-4.54649-.6062-14.24566,0-37.88741a77.67014,77.67014,0,0,1,8.45193-32.88627,54.44582,54.44582,0,0,1,4.12669-6.97129l8.94143-.75775s4.09184,16.36737,4.39494,18.186S525.5995,365.83893,525.5995,365.83893Z"
      transform="translate(-129.75 -77.64742)"
      fill="#2f2e41"
    />
    <path
      d="M548.93814,241.26512s4.39494,37.58431-1.36394,41.06995-8.18369,14.24567-8.18369,14.24567l21.52005,11.21467,36.675-7.12283-1.364-18.48906s-17.57976-1.36395-13.94257-10.30537a104.22978,104.22978,0,0,0,5.30424-18.03441Z"
      transform="translate(-129.75 -77.64742)"
      fill="#ffc1c7"
    />
    <path
      d="M548.93814,241.26512s4.39494,37.58431-1.36394,41.06995-8.18369,14.24567-8.18369,14.24567l21.52005,11.21467,36.675-7.12283-1.364-18.48906s-17.57976-1.36395-13.94257-10.30537a104.22978,104.22978,0,0,0,5.30424-18.03441Z"
      transform="translate(-129.75 -77.64742)"
      opacity="0.1"
    />
    <path
      d="M573.33764,628.01982c-2.40746,7.471-1.06069,42.63277-.35049,57.7619q.33937,7.22967-.00608,14.46516l-1.61358,34.00924.0394,1.03055,1.93075,50.19323-18.186-2.12169-1.364-27.43049s-1.68828-6.56514-4.0706-16.44617c-6.47727-26.85762-18.086-78.20417-14.87311-88.72928,4.39494-14.39722-6.51663-68.0458-6.51663-68.0458l38.19051-6.81973S576.62208,617.82727,573.33764,628.01982Z"
      transform="translate(-129.75 -77.64742)"
      fill="#ffc1c7"
    />
    <path
      d="M663.35813,791.63487l-10.60848,6.062s-19.2468-7.42594-20.30765-7.729-2.27325-16.21581-2.27325-16.21581-2.5036-16.328-5.64522-33.03782c-2.21264-11.77237-4.74052-23.73269-6.9334-30.30993-5.30423-15.91272-4.698-68.95509-5.30423-72.44073s-19.24681-68.0458-19.24681-68.0458,40.46376-7.57748,45.61644-3.031,10.00228,59.40746,9.69918,71.07679,1.364,82.13991,1.364,82.13991.20764,10.2478.5319,21.17451c.23043,7.723.51836,15.78392.83205,20.80474a30.14858,30.14858,0,0,0,8.63833,19.2468Z"
      transform="translate(-129.75 -77.64742)"
      fill="#ffc1c7"
    />
    <path
      d="M646.53612,789.21008s-10.45693-13.791,13.94256-12.73018c0,0,2.27325,2.4248,6.66819,8.33524s33.03782,28.18823,18.6406,32.43162-32.92648,6.6258-38.53747,2.35485a62.95259,62.95259,0,0,1-25,1s1.70522-27.45124,3.06916-30.63379,2.87945-19.2468,2.87945-19.2468l16.51891,21.0654Z"
      transform="translate(-129.75 -77.64742)"
      fill="#2f2e41"
    />
    <circle cx="444.80003" cy="154.67627" r="31.06768" fill="#ffc1c7" />
    <path
      d="M525.5995,365.83893s-5.75889,71.37988-6.51664,71.37988c-.57741,0-5.37091.3516-11.17072.65318a5.74967,5.74967,0,0,1,1.16844-1.86558c3.78875-3.48564,5.60734-29.55218,5.60734-29.55218l-5.15269-46.98039,4.47072-40.99418c2.27931-4.47071-2.80367-6.744-2.80367-6.744l8.94143-.75775s4.09184,16.36737,4.39494,18.186S525.5995,365.83893,525.5995,365.83893Z"
      transform="translate(-129.75 -77.64742)"
      opacity="0.1"
    />
    <path
      d="M624.10677,289.60945s-28.79444-11.82087-30.91613-10.76-7.88058,5.30424-7.88058,5.30424-13.48792,14.09411-31.67388,10.00227c0,0-6.21353-3.031-6.062-11.82087,0,0-18.94371,10.15383-24.85415,16.822s-11.51777,12.57862-11.51777,12.57862l2.87944,47.73814,5.15269,46.98039s-1.8186,26.06654-5.60734,29.55218-8.63833,33.644-3.031,82.74611-5.91044,65.3179.75775,69.71284,57.58887,1.5155,86.9895,2.87944,47.73814-6.81973,46.22264-8.94142-8.18368-78.19962-3.18254-99.71967,1.21239-66.68185,1.21239-66.68185Z"
      transform="translate(-129.75 -77.64742)"
      fill="#2f2e41"
    />
    <path
      d="M615.77154,425.701s-7.88058,61.52916-13.33637,67.13649-7.42593,50.16294,2.12169,50.16294,18.186-49.25364,18.186-49.25364L637.29159,427.065Z"
      transform="translate(-129.75 -77.64742)"
      fill="#ffc1c7"
    />
    <path
      d="M546.21025,783.05511s19.70145-13.33637,30.15838,0c0,0,4.39494,29.70373,0,33.03782s-34.40177,8.94143-35.008,0,1.5155-13.03327,1.5155-13.03327l3.33409-12.124Z"
      transform="translate(-129.75 -77.64742)"
      fill="#2f2e41"
    />
    <path
      d="M621.45465,297.71736l-.98507-5.22846s22.12624,23.79329,25.76344,44.404,11.66932,41.97926,6.062,44.8587-10.15382,1.97015-7.57748,6.66819,3.33409,10.30537.75775,12.124-4.39494,3.33409-3.48564,7.12283.90929,11.51777-.9093,13.18482-6.36509,15.00342-6.36509,15.00342-18.48905-6.062-25.46034-1.97015S608.194,417.972,608.194,417.972s-1.51549-8.03213-3.78874-9.39608-3.63719-3.94029-.75774-6.66819,5.15268-2.72789,1.21239-6.51663-9.24453-2.1217-3.78874-6.062,8.33523-5.75888,4.698-9.54762-6.81973-5.91044-4.24339-9.24453S608.72448,294.38327,621.45465,297.71736Z"
      transform="translate(-129.75 -77.64742)"
      opacity="0.1"
    />
    <path
      d="M618.95408,290.51875l5.15269-.9093s22.12625,23.7933,25.76344,44.40405,11.66932,41.97925,6.062,44.8587-10.15382,1.97014-7.57748,6.66818,3.33409,10.30538.75775,12.124-4.39494,3.3341-3.48564,7.12284.9093,11.51777-.9093,13.18482-6.36509,15.00341-6.36509,15.00341-18.48905-6.062-25.46034-1.97014-1.06084-15.91272-1.06084-15.91272-1.5155-8.03213-3.78875-9.39608-3.63719-3.94029-.75774-6.66818,5.15268-2.72789,1.21239-6.51663-9.24452-2.1217-3.78874-6.062,8.33523-5.75889,4.698-9.54763-6.81973-5.91043-4.24339-9.24453S606.22391,287.18466,618.95408,290.51875Z"
      transform="translate(-129.75 -77.64742)"
      fill="#2f2e41"
    />
    <path
      d="M548.39289,196.62169a32.84253,32.84253,0,0,0-8.24278,9.25294,41.51485,41.51485,0,0,0-2.61386,6.36343c-2.45973,7.07907-8.5742,14.24875-5.98634,21.282.73844,2.00691,1.88982,4.12294,1.22785,6.15635-.5879,1.80585-2.40247,2.85968-3.71492,4.23234-2.817,2.9462-3.29049,7.36678-3.46257,11.43934-.31515,7.45825,3.78583,16.24736,8.74106,21.83041a30.50342,30.50342,0,0,1,2.98207,3.37593,7.91354,7.91354,0,0,1,.9341,6.50992l7.21854-1.50658a59.87382,59.87382,0,0,1,.24359,8.02037,7.48738,7.48738,0,0,0,7.93957-5.13115,21.4382,21.4382,0,0,0,1.98309,5.47942,28.2832,28.2832,0,0,0,4.79438-8.87908,25.386,25.386,0,0,0,.861-12.81883,54.35637,54.35637,0,0,0-3.108-9.1114c-2.55063-6.44608-4.63356-13.06564-6.71409-19.67843-1.08058-3.43451-2.1742-6.98068-1.8467-10.56624l2.56494-.279a12.24691,12.24691,0,0,1,.80956-4.1395,15.18732,15.18732,0,0,1,4.2896,1.61442l2.95685-7.50284c1.95151,2.02389,4.75216,2.94,7.43386,3.78435l10.193,3.20944.062-2.01725a52.31652,52.31652,0,0,0,14.50451,9.09816,3.29969,3.29969,0,0,1-.71633-2.6256c2.51185,4.49984,1.50736,10.10569-.04284,15.02044s-3.60686,9.91832-2.98258,15.03381c.46691,3.82593,2.4268,7.4849,2.06345,11.32206-.17893,1.88957-.918,3.68769-1.14779,5.57175-.54811,4.49346,1.81446,8.77244,4.095,12.68275l4.4346-6.09015a23.31962,23.31962,0,0,1,5.594-6.0299c2.359-1.56391,5.283-2.266,7.3387-4.21141,3.66981-3.47284,3.20387-9.38933,2.01045-14.29889-.63441-2.60987-1.40208-5.25676-1.16884-7.93248.39538-4.536,3.54732-8.289,5.32252-12.48181a21.66862,21.66862,0,0,0-1.2143-19.04889c-2.20273-3.77826-5.53113-6.82116-7.60691-10.67063-2.06636-3.832-2.85459-8.39151-5.68593-11.6987a19.73565,19.73565,0,0,0-4.76629-3.7975,62.13613,62.13613,0,0,0-26.43491-8.62427C562.66815,187.83381,555.18771,191.02049,548.39289,196.62169Z"
      transform="translate(-129.75 -77.64742)"
      fill="#ff6584"
    />
    <path
      d="M931.94781,697.09862a20.81252,20.81252,0,1,0,2.7716-39.91524l.52093,10.7122-5.06814-9.18045a20.734,20.734,0,0,0-10.68367,11.72261,20.40847,20.40847,0,0,0-1.19713,5.62986A20.80855,20.80855,0,0,0,931.94781,697.09862Z"
      transform="translate(-129.75 -77.64742)"
      fill="#f50057"
    />
    <path
      d="M957.02515,821.342c-1.78905-9.11027,5.96331-17.1868,13.62087-22.43651s16.605-10.40779,19.21775-19.31684c3.755-12.80387-7.43-24.52981-16.13564-34.64176a125.30044,125.30044,0,0,1-16.52359-24.55738c-1.81108-3.5325-3.47558-7.22528-3.95222-11.16626-.6864-5.67546,1.13694-11.32309,2.97391-16.73673q9.17924-27.05169,19.62843-53.65005"
      transform="translate(-129.75 -77.64742)"
      fill="none"
      stroke="#3f3d56"
      stroke-miterlimit="10"
    />
    <path
      d="M932.80493,694.34068a20.81252,20.81252,0,1,0,2.7716-39.91524l.52093,10.7122-5.06815-9.18045a20.734,20.734,0,0,0-10.68367,11.72261,20.40881,20.40881,0,0,0-1.19712,5.62986A20.80855,20.80855,0,0,0,932.80493,694.34068Z"
      transform="translate(-129.75 -77.64742)"
      fill="none"
      stroke="#3f3d56"
      stroke-miterlimit="10"
    />
    <path
      d="M954.48135,635.13714a20.78823,20.78823,0,0,1,14.97993-13.19764l1.71362,10.18378,3.177-10.69566a20.81,20.81,0,1,1-19.87058,13.70952Z"
      transform="translate(-129.75 -77.64742)"
      fill="#f50057"
    />
    <path
      d="M955.62418,631.45988a20.7882,20.7882,0,0,1,14.97993-13.19763l1.71361,10.18378,3.177-10.69567a20.81,20.81,0,1,1-19.87057,13.70952Z"
      transform="translate(-129.75 -77.64742)"
      fill="none"
      stroke="#3f3d56"
      stroke-miterlimit="10"
    />
    <path
      d="M970.07474,745.15333a20.81,20.81,0,0,0,18.419-37.02267l-2.44121,8.21926-1.73106-10.30382a.3621.3621,0,0,0-.053-.0201,20.81113,20.81113,0,1,0-14.1938,39.12733Z"
      transform="translate(-129.75 -77.64742)"
      fill="#f50057"
    />
    <path
      d="M971.065,741.42865A20.81,20.81,0,0,0,989.484,704.406l-2.44121,8.21926-1.731-10.30382a.36183.36183,0,0,0-.053-.0201,20.81113,20.81113,0,1,0-14.1938,39.12733Z"
      transform="translate(-129.75 -77.64742)"
      fill="none"
      stroke="#3f3d56"
      stroke-miterlimit="10"
    />
    <path
      d="M948.40782,779.716a20.80131,20.80131,0,1,0,4.01058-16.29737l9.27267,13.95654-12.66994-7.40768A20.61625,20.61625,0,0,0,948.40782,779.716Z"
      transform="translate(-129.75 -77.64742)"
      fill="#f50057"
    />
    <path
      d="M949.26494,776.958a20.80131,20.80131,0,1,0,4.01058-16.29737l9.27266,13.95655-12.66994-7.40769A20.61638,20.61638,0,0,0,949.26494,776.958Z"
      transform="translate(-129.75 -77.64742)"
      fill="none"
      stroke="#3f3d56"
      stroke-miterlimit="10"
    />
    <circle cx="138.59689" cy="145.83588" r="9.16306" fill="#f50057" />
    <circle cx="122.30701" cy="144.05417" r="9.16306" fill="#f50057" />
    <circle cx="108.56242" cy="154.74441" r="9.16306" fill="#f50057" />
    <circle cx="110.0896" cy="171.03428" r="9.16306" fill="#f50057" />
    <path
      d="M287.81705,257.06158c-1.17361-1.08469-2.89845-1.35142-4.37435-1.60037a6.50906,6.50906,0,0,1-4.0187-1.77819,4.92948,4.92948,0,0,1,0-7.78846l5.3879-4.78333a17.31229,17.31229,0,0,0,0-26.53057c-6.0814-5.35234-14.24329-8.00185-22.72525-8.00185A42.98781,42.98781,0,0,0,233.849,217.26573c-14.84788,13.123-14.84788,34.62133,0,47.74436,7.37948,6.526,17.33734,9.78,27.1885,9.85116h.30229c9.85116,0,19.56007-3.183,26.45944-9.31771,2.56059-2.2583,2.13383-6.50817.01779-8.482Zm-55.14163-23.4543a5.69021,5.69021,0,1,1,5.6902,5.6902A5.68409,5.68409,0,0,1,232.67542,233.60728Zm7.11275,22.40517a5.6902,5.6902,0,1,1,5.69021-5.6902A5.68409,5.68409,0,0,1,239.78817,256.01245Zm11.38041-28.62883a5.69021,5.69021,0,1,1,5.6902-5.69021A5.68409,5.68409,0,0,1,251.16858,227.38362Zm12.803,38.94233a8.53531,8.53531,0,1,1,8.5353-8.53531A8.53752,8.53752,0,0,1,263.97154,266.326Zm4.26765-36.98633a5.6902,5.6902,0,1,1,5.6902-5.6902A5.68409,5.68409,0,0,1,268.23919,229.33962Z"
      transform="translate(-129.75 -77.64742)"
      fill="#2f2e41"
    />
    <line
      x1="346"
      y1="743.45419"
      x2="940.5"
      y2="743.45419"
      fill="none"
      stroke="#2f2e41"
      stroke-miterlimit="10"
    />
    <path
      d="M395.2265,539.56326s-6.06025,4.51762-9.553,6.70065-6.63137,9.74435-4.91656,16.67558l.4004-.12443s.46822-3.43849,6.28759-5.90546,14.37932-6.00511,10.85474-15.22787Z"
      transform="translate(-129.75 -77.64742)"
      fill="#f50057"
    />
    <path
      d="M397.3031,535.29567s23.182-44.08528,44.12011-69.2521l9.019-13.01094,1.04965.552-7.97616,13.89429s-34.62137,65.20269-42.68362,70.34244Z"
      transform="translate(-129.75 -77.64742)"
      fill="#2f2e41"
    />
    <path
      d="M401.83311,537.51025l-4.57188-3.409-3.23593,5.83528a5.20682,5.20682,0,0,0,4.66121,2.28358Z"
      transform="translate(-129.75 -77.64742)"
      fill="#3f3d56"
    />
    <path
      d="M383,571c0,1.65685-.44772,3-1,3s-1-1.34315-1-3a5.60718,5.60718,0,0,1,1-3A5.60718,5.60718,0,0,1,383,571Z"
      transform="translate(-129.75 -77.64742)"
      fill="#f50057"
    />
    <ellipse cx="248.25" cy="500.35258" rx="1" ry="3" fill="#f50057" />
    <path
      d="M808.41788,226.39256l38.94782-7.15607s4.106-.82119,4.80982,1.99431l-44.81346,12.9044-.9385-6.21757Z"
      transform="translate(-129.75 -77.64742)"
      fill="#f50057"
    />
    <path
      d="M810.09761,226.08393c-13.86419.21929-11.24072,4.94248-12.8831,8.10993s-15.06914,3.4931-15.06914,3.4931l-.0037.001a10.05592,10.05592,0,1,0,10.24844,10.50159,9.86628,9.86628,0,0,0-.28382-2.83074c-1.19766-8.97014,16.66837-11.028,16.66837-11.028l-.65006-3.18707A5.38163,5.38163,0,0,1,810.09761,226.08393ZM782.43311,254.137a7.03877,7.03877,0,1,1,7.03877-7.03876A7.03876,7.03876,0,0,1,782.43311,254.137Z"
      transform="translate(-129.75 -77.64742)"
      fill="#2f2e41"
    />
    <path
      d="M809.66526,234.34523l39.5022,2.77822s4.17181.35925,4.55806-2.51709l-46.57637-8.15909.36449,6.60426Z"
      transform="translate(-129.75 -77.64742)"
      fill="#f50057"
    />
    <path
      d="M808.84517,229.6562l.29143-3.23961s-17.98406-.05726-17.79188-9.105a9.86677,9.86677,0,0,0-.0329-2.84474,10.25684,10.25684,0,1,0-9.01638,11.57665l.00379.00056s13.37963-1.17025,15.36423,1.79479-.09707,7.95081,13.70544,6.62616A5.38161,5.38161,0,0,1,808.84517,229.6562Zm-20.312-13.78012a7.03876,7.03876,0,1,1-7.77821-6.21191A7.03876,7.03876,0,0,1,788.53312,215.87608Z"
      transform="translate(-129.75 -77.64742)"
      fill="#2f2e41"
    />
  </svg>
</template>

<script>
export default {
  name: "iCulture",
  props: [],
  methods: {},
  data: () => {
    return {};
  }
};
</script>

<style scoped>
.byourplanIllus {
  width: 100%;
  height: auto;
}
</style>
