import axios from "axios";
import firebase from 'firebase';

const baseURL = !window.location.host.includes("localhost")
    ? "https://api.bmyplan.com"
    : "http://localhost:8087";
const apiClient = axios.create({ baseURL });

export const loadingMix = {
    data() {
        return {
            loading: null
        }
    },
    methods: {
        openLoading(color = '#d5397b', background = '#d5397b', type = 'corners', ref = null) {
            this.loading = this.$vs.loading({
                ref,
                text: "Más pieeedraa!",
                type,
                color,
                background
            });
        },
        closeLoading() {
            this.loading.close();
        },
    }
}

export const noficationsDialog = {
    methods: {
        notificateLoginSuccess(title, text, color = 'success', position = 'top-right', duration = 'none') {
            const notif = this.$vs.notification({
                duration,
                sticky: true,
                color,
                position,
                title,
                text
                // content: user
            });
        },
        notificateLoginFail(title, text, color = 'danger', position = 'top-right', sticky = true) {
            const noti = this.$vs.notification({
                sticky,
                color,
                position,
                title,
                text
            });
        },
        notificateLoginRequired(position = "top-center", color = "dark", content) {
            const noti = this.$vs.notification({
                color,
                icon: '<i class="fas fa-stop-circle"></i>',
                duration: 5000,
                position,
                sticky: true,
                clickClose: true,
                buttonClose: false,
                content,
                title: "Action not able without login",
                text: "Please, log in with your credentials to join this event :"
            });
        }
    }
}

export const imageMix = {
    methods: {
        parseImg(entity) {
            const copyEntity = Object.assign({}, entity[0]);
            if (copyEntity.ProfileImg) {
                const paso1 = new Uint8Array(copyEntity.ProfileImg.data);
                const paso2 = paso1.reduce((data, byte) => data + String.fromCharCode(byte), "");
                copyEntity["ProfileImg"] = paso2;
                this.profileImg = paso2;
                return { ...copyEntity, ...{ ProfileImg: paso2 } };
            }
            return copyEntity;
        },

        parseImages(aEntity, isParticipant = false, returnEntity = false) {
            let img = null;
            aEntity.forEach(entity => {
                if (entity.ProfileImg) {
                    const paso1 = new Uint8Array(entity.ProfileImg.data);
                    const paso2 = paso1.reduce((data, byte) => data + String.fromCharCode(byte), "");
                    entity.ProfileImg = paso2;
                    if (!isParticipant) {
                        img = paso2.toString();
                    }
                }
            });
            return returnEntity ? aEntity : img;
        },

        parseMedia(aEntity) {
            let imgData = [];
            aEntity.forEach(entity => {
                if (entity.img) {
                    const paso1 = new Uint8Array(entity.img.data);
                    const paso2 = paso1.reduce((data, byte) => data + String.fromCharCode(byte), "");
                    entity.img = paso2;
                    imgData.push({
                        img: paso2,
                        likes: entity.likes,
                        visits: entity.visits,
                        id: entity.id,
                        created_by: entity.created_by,
                        created_at: entity.created_at
                    });
                }
            });
            return aEntity;
        }
    }
}

export const voiceMix = {
    methods: {
        voiceOrder(oEvent, voice) {
            switch (oEvent) {
                case "crear":
                    voice.stop();
                    this.$router.push("/Create");
                    break;
                case "escoger":
                case "elegir":
                case "seleccionar":
                case "buscar":
                    voice.stop();
                    this.$router.push("/Search");
                    break;
                case "inicio":
                case "casa":
                case "home":
                case "portada":
                    voice.stop();
                    this.$router.push("/");
                    break;
                default:
                    break;
            }
        },
        voiceNavigation() {
            const SpeechRecognition = window.SpeechRecognition || webkitSpeechRecognition;
            const SpeechGrammarList = window.SpeechGrammarList || webkitSpeechGrammarList;
            const SpeechRecognitionEvent = window.SpeechRecognitionEvent || webkitSpeechRecognitionEvent;

            const voice = new SpeechRecognition();
            voice.continuous = true;
            voice.interimResults = true;
            const that = this;
            voice.onresult = event => {
                that.voiceOrder(event.results[0][0].transcript, voice);
            };
            voice.onstart = result => {
                console.log(result);
            };
            voice.start();
        },
    }
}

export const followMix = {
    methods: {
        follow(followed, follower) {
            const body = { followed, follower };
            return apiClient.post("/followers", body);
        },
        unfollow(followed, follower) {
            const body = { params: { followed, follower } };
            return apiClient.delete("/followers", body);
        },
        followers(followed_Id) {
            const body = { params: { followed_Id } };
            return apiClient.get("/followers", body);
        },
    }
}

export const chatMix = {

    methods: {
        openChatMix(chat) {
            this.$store.commit("showChatSB", { show: true, chat });
        },

        createChat(data) {
            console.log(data);
            return firebase.firestore().collection('chats').add(data);
        },

        async getChat(type, participants, chat_id = null) {
            console.log(chat_id);
            if (!chat_id) {


                let ref = firebase.firestore().collection('chats').where("type", "==", type);
                const aChats = [];
                const docs = async (ref) => {
                    await ref.get().then(doc => {
                        if (!doc.empty) {
                            doc.docs.forEach(d => {
                                const chat = d.data();
                                chat.id = d.id;
                                aChats.push(chat);
                            })
                        }
                    });
                };
                for (const participant of participants) {
                    let copyRef = null;
                    copyRef = ref.where("participants_id", "array-contains", participant);
                    await docs(copyRef);
                };
                const chats = aChats.filter(chat => {
                    return participants.every(participant => {
                        return chat.participants_id.includes(participant)
                    });
                })

                const resArr = [];
                chats.filter(function (item) {
                    let i = resArr.findIndex(x => x.id == item.id);
                    if (i < 0) {
                        resArr.push(item);
                    }
                    return null;
                });
                return resArr.length > 0 ? resArr : null;
            } else {
                let ref = firebase.firestore().collection('chats').doc(chat_id);
                const aChats = [];
                const docs = async (ref) => {
                    await ref.get().then(doc => {
                        if (doc.exists) {
                            const chat = doc.data();
                            chat.id = doc.id;
                            aChats.push(chat);
                        }
                    });
                };
                await docs(ref);
                const resArr = [];
                aChats.filter(function (item) {
                    let i = resArr.findIndex(x => x.id == item.id);
                    if (i < 0) {
                        resArr.push(item);
                    }
                    return null;
                });
                return resArr.length > 0 ? resArr : null;
            }
        },

        sendMsg(chat_id, data, msg, participants) {
            const { text } = msg.data;
            this.sendPush(text, participants);
            return firebase.firestore().collection("chats").doc(chat_id).set(data);
        },

        async sendPush(msg, participants, type = "Chat") {
            const aSubscribers = [];
            for (const participant_id of participants) {
                const subs = await authMix.methods.firebaseGetSubscriber(participant_id);
                aSubscribers.push(subs);
            };
            let title;
            switch (type) {
                case 'Chat':
                    title = 'New chat message';
                    break;
                case 'Follow':
                    title = 'New follower';
                    break;
                case 'Interaction':
                    title = 'New interaction';
                    break;
                case 'Reply':
                    title = 'New Reply';
                    break;
                case 'Media':
                    title = 'New Media';
                    break;
                default:
                    title = 'New chat message';
                    break;
            }

            const body = {
                msg, subscriber: aSubscribers, title
            };
            await apiClient.post("/pushnotification", body);
        },

        async receiveMsg(chat_id, store) {
            return new Promise(async (resolve, reject) => {
                await firebase.firestore().collection("chats").doc(chat_id)
                    .onSnapshot(async (doc) => {
                        const chat = doc.data();
                        chat.id = doc.id;
                        if (chat && chat.type) {
                            store.commit("setChat", chat);
                        }
                        resolve(chat);
                    }, reject(error => console.error(error)));
            });
        }
    }
}

export const authMix = {
    methods: {
        firebaseGoogleAuth(){
            const provider = new firebase.auth.GoogleAuthProvider();
            return firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION).then(() => {
                return firebase.auth().signInWithPopup(provider)
            });
        },
        firebaseFaceBookAuth(){
            const provider = new firebase.auth.FacebookAuthProvider();
            return firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION).then(() => {
                return firebase.auth().signInWithPopup(provider)
            });
        },

        async firebaseAuth(store) {
            await firebase.auth().onAuthStateChanged(async (currentUser) => {
                if (currentUser) {
                    console.log('user is logged');
                    const user = await this.firebaseGetUser(currentUser.uid);
                    user.id = currentUser.uid;
                    this.firebaseUpdateSubscriber(currentUser.uid);
                    store.commit("setUser", user);
                    return user;
                } else {
                    console.log('user is not logged');
                    return null;
                }
            });
        },

        firebaseLogout() {
            return firebase.auth().signOut()
        },

        firebaseLogin(email, password) {
            return firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION).then(() => {
                return firebase.auth().signInWithEmailAndPassword(email, password)
            });
        },

        firebaseRegister(email, password) {
            return firebase.auth().createUserWithEmailAndPassword(email, password)
        },

        firebaseSaveUser(user_id, data, sendVerification = false) {
            if (sendVerification) {
                firebase.auth().currentUser.updateProfile({ displayName: data.username })
                    .then(this.firebaseSendVerification());
            }
            // set(data, {merge:true})
            return firebase.firestore().collection('users').doc(user_id).set(data);
        },

        firebaseUpdateUser(user_id, data, sendVerification = false) {
            if (sendVerification) {
                firebase.auth().currentUser.updateProfile({ displayName: data.username })
                    .then(this.firebaseSendVerification());
            }
            // set(data, {merge:true})
            return firebase.firestore().collection('users').doc(user_id).update(data);
        },

        firebaseUpdateSubscriber(user_id) {
            const subscriber = localStorage.getItem("subscriber") || null;
            firebase.firestore().collection('users').doc(user_id).update('subscriber', subscriber);
        },

        firebaseGetSubscriber(user_id) {
            return firebase.firestore().collection('users').doc(user_id).get().then((doc) => {
                const user = doc.data();
                return { subscriber: user.subscriber, username: user.username };
            });
        },

        firebaseCurrentUser() {
            return firebase.auth().currentUser;
        },

        firebaseGetUser(user_id) {
            return firebase.firestore().collection('users').doc(user_id).get().then(doc => {
                return doc.data();
            });
        },

        firebaseSendVerification() {
            const user = firebase.auth().currentUser.uid;
            return firebase.auth().currentUser.sendEmailVerification({ url: `https://bmyplan.firebaseapp.com/validation/${user}` });
        },

        firebaseSendResetPassword(email) {
            return firebase.auth().sendPasswordResetEmail(email);
        },

        firebaseChangePassword(newPassword, user_id) {
            firebase.auth().currentUser.updatePassword(newPassword);
            // firebase.auth().confirmPasswordReset()
            return firebase.firestore().collection('users').doc(user_id).update({ password: newPassword, recovery: false });
        },

        firebaseGetProfileImg(user_id) {
            return firebase.storage().ref("users/" + user_id + "/profile.jpg").getDownloadURL().then(imgUrl => {
                return imgUrl;
            }).catch((error) => {
                return null;
                // return "https://upload.wikimedia.org/wikipedia/commons/3/33/Mr._Bean_2011.jpg";
            });
        },

        firebaseSetProfileImg(user_id, file) {
            return firebase.storage().ref("users/" + user_id + "/profile.jpg").put(file).then(async () => {
                const img = await this.firebaseGetProfileImg(user_id);
                firebase.auth().currentUser.updateProfile({ photoURL: img });
                firebase.firestore().collection('users').doc(user_id).update({ profileImg: img })
            });
        },

        getUsers() {
            return firebase.firestore().collection('users').get().then(doc => {
                const docs = [], ids = [];
                doc.docs.forEach((doc) => {
                    ids.push(doc.id);
                    docs.push(doc.data());
                });
                docs.forEach((doc, ind) => {
                    doc.id = ids[ind];
                });
                return docs;
            });
        },
    }
}

export const eventMix = {
    methods: {
        createEvent(data) {
            return firebase.firestore().collection('events').add(data);
        },

        getEventImg(event_id) {
            return firebase.storage().ref("events/" + event_id + "/event.jpg").getDownloadURL().then(imgUrl => {
                return imgUrl;
            });
        },

        setEventImg(event_id, file) {
            return firebase.storage().ref("events/" + event_id + "/event.jpg").put(file).then(async () => {
                const img = await this.getEventImg(event_id);
                firebase.firestore().collection('events').doc(event_id).update({ image: img }, { merge: true })
            });
        },

        addMomentImg(event_id, file) {
            const now = new Date().getTime();
            return firebase.storage().ref("events/" + event_id + "/moments/moment_" + now + ".jpg").put(file).then(async (data) => {
                return await data.ref.getDownloadURL();
            });
        },

        updateMomentEvent(id, comment_id, data) {
            return firebase.firestore().collection('eventsLog').doc(id)
                .collection('moments').doc(comment_id).update(data);
        },

        getMomentsImg(event_id) {
            const aImages = [];
            return firebase.storage().ref("events/" + event_id + "/moments").listAll().then((list) => {
                list.items.forEach(async (image) => {
                    const imgUrl = await image.getDownloadURL();
                    const metadata = await image.getMetadata();
                    aImages.push({ imgUrl, metadata })
                });
                return aImages;
            });
        },

        getEvents() {
            return firebase.firestore().collection('events').get().then(doc => {
                const docs = [], ids = [];
                doc.docs.forEach((doc) => {
                    ids.push(doc.id);
                    docs.push(doc.data());
                });
                docs.forEach((doc, ind) => {
                    doc.id = ids[ind];
                });
                return docs;
            });
        },

        getEvent(event_id) {
            return firebase.firestore().collection('events').doc(event_id).get().then(doc => {
                return doc.data();
            });
        },

        getFilteredEvents(filter, value, comparator = "==") {
            return firebase.firestore().collection('events').where(filter, comparator, value).get().then(doc => {
                const docs = [];
                doc.docs.forEach((doc) => {
                    docs.push(doc.data());
                });
                return docs;
            });
        },

        updateEvents(id, data) {
            return firebase.firestore().collection('events').doc(id)
                .update(data);
        },

        addCommentEvent(id, data) {
            return firebase.firestore().collection('eventsLog').doc(id)
                .collection('comments').add(data);
        },

        updateCommentEvent(id, comment_id, data) {
            return firebase.firestore().collection('eventsLog').doc(id)
                .collection('comments').doc(comment_id).update(data);
        },

        async getCommentsEvent(id) {
            const doc = await firebase.firestore().collection('eventsLog').doc(id).collection('comments').get();
            const docs = [], ids = [];
            doc.docs.forEach((doc) => {
                ids.push(doc.id);
                docs.push(doc.data());
            });
            docs.map(async (doc, ind) => {
                doc.id = ids[ind];
            });
            return docs;
        },

        addImageMomentEvent(id, data) {
            return firebase.firestore().collection('eventsLog').doc(id).collection('moments').add(data);
        },

        async getImagesMomentsEvent(id) {
            return await firebase.firestore().collection('eventsLog').doc(id).collection('moments').get().then((doc) => {
                const docs = [], ids = [];
                doc.docs.forEach((doc) => {
                    ids.push(doc.id);
                    docs.push(doc.data());
                });
                docs.forEach((doc, ind) => {
                    doc.id = ids[ind];
                });
                return docs;
            });
        },

        addAdsEvent(id, data) {
            return firebase.firestore().collection('eventsLog').doc(id).collection('ads').add(data);
        },

        async getAdsEvent(id) {
            const doc = await firebase.firestore().collection('eventsLog').doc(id).collection('ads').get();
            const docs = [], ids = [];
            doc.docs.forEach((doc) => {
                ids.push(doc.id);
                docs.push(doc.data());
            });
            docs.map((doc, ind) => {
                doc.id = ids[ind];
            });
            return docs;
        },

        addParticipantEvent(id, data) {
            return firebase.firestore().collection('eventsLog').doc(id).collection('assistants').add(data);
        },

        async getParticipantsEvent(id) {
            const doc = await firebase.firestore().collection('eventsLog').doc(id).collection('assistants').get();
            const docs = [], ids = [];
            doc.docs.forEach((doc) => {
                ids.push(doc.id);
                docs.push(doc.data());
            });
            docs.map((doc, ind) => {
                doc.id = ids[ind];
            });
            return docs;
        },

        addInteractionEvent(id, data) {
            return firebase.firestore().collection('eventsLog').doc(id).collection('interactions').add(data);
        },

        async getInteractionEvent(id, subtype = null) {
            const interactions = firebase.firestore().collection('eventsLog').doc(id).collection('interactions');
            const doc = subtype ? await
                interactions.where("subType", "==", subtype).get() : await
                interactions.get();
            const docs = [], ids = [];
            doc.docs.forEach((doc) => {
                ids.push(doc.id);
                docs.push(doc.data());
            });
            docs.map((doc, ind) => {
                doc.id = ids[ind];
            });
            return docs;
        },
    }
}