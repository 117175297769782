import Vue from 'vue';
import 'bootstrap/dist/css/bootstrap.css';
import BootstrapVue from 'bootstrap-vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vuesax from 'vuesax';
import VCalendar from 'v-calendar';
import Vuetify from 'vuetify';
import VueTimeline from "@growthbunker/vuetimeline";
import Chat from 'vue-beautiful-chat';
// import socketio from 'socket.io';
// import VueSocketIO from 'vue-socket.io';

import '@/firebase.js';

import 'vuetify/dist/vuetify.min.css'; // Vuetify styles
import 'vuesax/dist/vuesax.css'; // Vuesax styles
// import GoogleAuth from '@/config/google.js';

import App from './App.vue';
import router from './router';
import store from './store';

const gauthOption = {
  clientId: '50062472184-21od0nk1b3v3muq45nj1t4r025b2gj6e.apps.googleusercontent.com',
  scope: 'softonick7@gmail.com',
  prompt: 'select_account'
}
// Vue.use(GoogleAuth, gauthOption)

Vue.config.productionTip = false;
Vue.component('Icon', FontAwesomeIcon);
Vue.use(BootstrapVue);
Vue.use(Vuetify, {
  theme: "dark"
});
Vue.use(Vuesax, {});
Vue.use(VCalendar);
Vue.use(VueTimeline, {
  theme: "dark"
});
Vue.use(Chat);
// connection: !window.location.host.includes("localhost")
//     ? "https://api.bmyplan.com"
//     : "http://localhost:8087",
// Vue.use(new VueSocketIO({
//   debug: true,
//   connection: !window.location.host.includes("localhost")
//     ? "https://api.bmyplan.com"
//     : "http://localhost:8087",
//   vuex: {
//     store,
//     actionPrefix: 'SOCKET_'
//   },
//   options: {} //Optional options
// }))

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('jwt') == null) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (localStorage.getItem('jwt') == null) {
      next()
    }
    else {
      next({ name: 'userboard' })
    }
  } else {
    next()
  }
})

const statusPrompt = {
  get: () => {
    return localStorage.getItem('statusPrompt') || null;
  },
  set: (status) => {
    localStorage.setItem('statusPrompt', status);
    return;
  }
}
window.onload = (e) => {
  const buttonAdd = document.querySelector('#installBtn');
  let deferredPrompt;
  window.addEventListener('beforeinstallprompt', (e) => {
    deferredPrompt = e;
    // Show prompt modal if user previously not set to dismissed or accepted
    if (!statusPrompt.get() && document.getElementById("installNotification")) {

      document.getElementById("installNotification").click();
      document.addEventListener("DOMNodeInserted", function (event) {
        if ((typeof (event.srcElement.outerText) !== "undefined") && event.srcElement.outerText.includes('Install BYourPlan')) {
          const buttonAdd = document.querySelector('#installBtn');
          buttonAdd.addEventListener('click', promptInstall);
          document.removeEventListener("DOMNodeInserted", function (event) {
            if ((typeof (event.srcElement.outerText) !== "undefined") && event.srcElement.outerText.includes('Install BYourPlan')) {
              const buttonAdd = document.querySelector('#installBtn');
              buttonAdd.addEventListener('click', promptInstall);
            }
          }, false);
        }
      }, false);
    }
  });

  const promptInstall = () => {
    deferredPrompt.prompt();
    deferredPrompt.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          statusPrompt.set('accepted');
          console.log('User accepted the A2HS prompt');
        } else {
          statusPrompt.set('dismissed');
          console.log('User dismissed the A2HS prompt');
        }
        deferredPrompt = null;
      });
  }
}


new Vue({
  data: {/*/ currentRoute: window.location.pathname, */ },
  computed: {},
  router,
  store,
  vuetify: new Vuetify(),
  render(h) { return h(App); },
}).$mount('#app');
