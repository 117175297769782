import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentUser: {},
    currentEvent: {},
    currentChat: {},
    updatedChat: {},
    showChatListSB: false,
    showNotificationSB: false,
    showUserSettingsSB: false,
    showAccountSettingsSB: false,
    showEventSettingsSB: false,
    showGlobalSettingsSB: false,
    showPrivacity: false,
    showChatSB: false,
  },
  mutations: {
    setUser: (state, user) => {
      { state.currentUser = user }
    },
    setEvent: (state, event) => {
      { state.currentEvent = event }
    },
    setChat: (state, chat) => {
      if (chat.type) {
        { state.updatedChat = chat }
      }
    },
    showChatListSB: (state, show) => {
      { state.showChatListSB = show }
      if (show) {
        { state.showUserSettingsSB = !show }
        { state.showAccountSettingsSB = !show }
        { state.showEventSettingsSB = !show }
        { state.showChatSB = !show }
        { state.showNotificationSB = !show }
        { state.showGlobalSettingsSB = !show }
      }
    },
    showNotificationSB: (state, show) => {
      { state.showNotificationSB = show }
      if (show) {
        { state.showUserSettingsSB = !show }
        { state.showAccountSettingsSB = !show }
        { state.showEventSettingsSB = !show }
        { state.showChatSB = !show }
        { state.showChatListSB = !show }
        { state.showGlobalSettingsSB = !show }
      }
    },
    showUserSettingsSB: (state, show) => {
      { state.showUserSettingsSB = show }
      if (show) {
        { state.showAccountSettingsSB = !show }
        { state.showNotificationSB = !show }
        { state.showEventSettingsSB = !show }
        { state.showChatSB = !show }
        { state.showChatListSB = !show }
        { state.showGlobalSettingsSB = !show }
      }
    },
    showAccountSettingsSB: (state, show) => {
      { state.showAccountSettingsSB = show }
      if (show) {
        { state.showUserSettingsSB = !show }
        { state.showNotificationSB = !show }
        { state.showEventSettingsSB = !show }
        { state.showChatSB = !show }
        { state.showChatListSB = !show }
        { state.showGlobalSettingsSB = !show }
      }
    },
    showEventSettingsSB: (state, show) => {
      { state.showEventSettingsSB = show }
      if (show) {
        { state.showNotificationSB = !show }
        { state.showAccountSettingsSB = !show }
        { state.showUserSettingsSB = !show }
        { state.showChatSB = !show }
        { state.showChatListSB = !show }
        { state.showGlobalSettingsSB = !show }
      }
    },
    showGlobalSettingsSB: (state, show) => {
      { state.showGlobalSettingsSB = show }
      if (show) {
        { state.showNotificationSB = !show }
        { state.showUserSettingsSB = !show }
        { state.showAccountSettingsSB = !show }
        { state.showChatSB = !show }
        { state.showChatListSB = !show }
        { state.showEventSettingsSB = !show }
      }
    },
    showPrivacity: (state, show) => {
      { state.showPrivacity = show }
    },
    showChatSB: (state, data) => {
      { state.showChatSB = data.show }
      console.log(data)
      if (data.show) {
        { state.showChatListSB = !data.show }
      }

      if (data.chat) {
        { state.currentChat = data.chat }
      }
    },
    setUserImg: (state, img) => {
      {
        state.currentUser = {
          ProfileImg: img
        }
      }
    }
  },
  actions: {
    "SOCKET_success"(state, server) {
      console.log(server);

    },
    "SOCKET_info"(state, server) {
      console.log(server);
    }
  },
});
