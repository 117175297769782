<template>
  <div v-if="activeSidebar">
    <vs-sidebar
      class="sbMenu"
      reduce
      right
      hover-expand
      v-model="active"
      style="max-width: 400px; height: 90vh;"
      :open.sync="activeSidebar"
    >
      <ChatGroupUsersSelector
        :active="activeChatGUS"
        :participants="participants"
        @onClose="closeUsersGroupSelector"
      />
      <template #logo v-if="activeSidebar">
        <img :src="require('@/assets/android-chrome-512x512_green.png')" />
      </template>
      <div>
        <beautiful-chat
          @load="onLoad"
          style="position:relative !important; margin: 1rem; min-height: 600px;"
          :participants="participants"
          :titleImageUrl="titleImageUrl"
          :onMessageWasSent="onMessageWasSent"
          :messageList="messageList"
          :newMessagesCount="newMessagesCount"
          :isOpen="isChatOpen"
          :close="onCloseSB"
          :open="openChat"
          :showEmoji="true"
          :showFile="true"
          :showEdition="true"
          :showDeletion="true"
          :showTypingIndicator="showTypingIndicator"
          :showLauncher="false"
          :showCloseButton="true"
          :colors="colors"
          :alwaysScrollToBottom="true"
          :messageStyling="messageStyling"
          @onType="handleOnType"
          @edit="editMessage"
        >
          <template v-slot:header>
            <div style="display:block; width:100%">
              <div style="display:flex; justify-content:space-evenly">
                <i class="fas fa-comments"></i>
                <small>Chateando con </small>
                <div
                  style="display: flex;"
                  v-for="(participant, ind) in currentChat.participants"
                  :key="'parti_' + ind"
                >
                  <vs-avatar
                    v-if="participant.username !== currentUser.username"
                    circle
                    size="30"
                    color="rgb(205,55,95)"
                  >
                    <img v-if="participant.profileImg" :src="participant.profileImg" alt="" />
                    <template v-if="!participant.profileImg" #text>
                      {{ participant.username }}
                    </template>
                  </vs-avatar>
                  <small>{{
                    participant.username === currentUser.username ? "" : participant.username
                  }}</small>
                </div>
              </div>
              <div>
                <vs-button
                  color="#0b4952"
                  gradient
                  size="mini"
                  block
                  floating
                  @click="openUsersGroupSelector"
                >
                  <i class="fas fa-user-plus"></i>
                  <template #animate>
                    Añadir participante
                  </template>
                </vs-button>
              </div>
            </div>
          </template>
          <template v-slot:user-avatar="{ message, user }">
            <div
              style="border-radius:50%; color: pink; font-size: 15px; line-height:25px; text-align:center;background: tomato; width: 25px !important; height: 25px !important; min-width: 30px;min-height: 30px;margin: 5px; font-weight:bold"
              v-if="message.author !== 'me'"
            >
              <!-- {{ user.username }} -->
              <vs-avatar v-if="user.username" circle size="30" color="rgb(205,55,95)">
                <img v-if="user.profileImg" :src="user.profileImg" alt="" />
                <template v-if="!user.profileImg" #text>
                  {{ user.username }}
                </template>
              </vs-avatar>
            </div>
          </template>
          <!-- <template v-slot:text-message-toolbox="scopedProps">
            <button
              v-if="!scopedProps.me && scopedProps.message.type === 'text'"
              @click.prevent="like(scopedProps.message.id)"
            >
              👍
            </button>
          </template> -->
          <template v-slot:text-message-body="scopedProps">
            <p class="sc-message--text-content" v-html="scopedProps.messageText"></p>
            <p
              v-if="scopedProps.message.data.meta"
              class="sc-message--meta"
              :style="{ color: scopedProps.messageColors.color }"
            >
              {{ scopedProps.message.data.meta }}
            </p>
            <p
              v-if="scopedProps.message.isEdited || scopedProps.message.liked"
              class="sc-message--edited"
            >
              <template v-if="scopedProps.message.isEdited">✎</template>
              <template v-if="scopedProps.message.liked">👍</template>
            </p>
          </template>
          <template v-slot:system-message-body="{ message }">
            [System]: {{ message.text }}
          </template>
        </beautiful-chat>
      </div>
    </vs-sidebar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ChatGroupUsersSelector from "@/components/ChatGroupUsersSelector.vue";
import { loadingMix, noficationsDialog, authMix, chatMix } from "@/common/mixin.js";

export default {
  name: "ChatSideBar",
  mixins: [loadingMix, noficationsDialog, authMix, chatMix],
  components: {
    ChatGroupUsersSelector
  },
  methods: {
    onLoad() {
      const chatCnt = document.getElementsByClassName("sc-chat-window opened")[0];
      if (chatCnt) {
        chatCnt.style.position = "relative !important";
        chatCnt.style.style.bottom = "0";
      }
    },
    onCloseSB() {
      this.$store.commit("showChatSB", { show: false, chat: null });
      this.activeSidebar = false;
      this.participants = [];
      this.messageList = [];
    },
    // sendMessage(text) {
    //   if (text.length > 0) {
    //     this.newMessagesCount = this.isChatOpen ? this.newMessagesCount : this.newMessagesCount + 1;
    //     this.onMessageWasSent({ author: "support", type: "text", data: { text } });
    //     this.$socket.emit("messages", text);
    //   }
    // },

    openUsersGroupSelector() {
      this.activeChatGUS = true;
    },
    closeUsersGroupSelector() {
      this.activeChatGUS = false;
    },

    async onMessageWasSent(message) {
      // called when the user sends a message
      const { id, username, profileImg } = this.$store.state.currentUser;
      this.messageList = [...this.messageList, message];
      console.log(message);

      let copyMsg = Object.assign({}, message);
      copyMsg.author = id;
      this.currentChat.messages.push(copyMsg);
      this.messageList = this.currentChat.messages;
      console.log(this.currentChat);
      await this.sendMsg(
        this.currentChat.id,
        this.currentChat,
        copyMsg,
        this.currentChat.participants_id
      );
    },

    async onReceiveMsg() {
      const { id } = this.currentChat;
      const { currentUser } = this.$store.state;
      if (id) {
        this.currentChat = await this.receiveMsg(id, this.$store);
      }
      const { messages } = this.currentChat;
      this.messageList = [...messages];
      this.messageList.forEach(msg => {
        if (msg.author === currentUser.id) {
          msg.author = "me";
        }
      });
    },

    openChat() {
      // called when the user clicks on the fab button to open the chat
      this.isChatOpen = true;
      this.newMessagesCount = 2;
    },
    closeChat() {
      // called when the user clicks on the botton to close the chat
      this.isChatOpen = false;
    },
    handleScrollToTop() {
      // called when the user scrolls message list to top
      // leverage pagination for loading another page of messages
    },
    handleOnType() {
      console.log("Emit typing event");
    },

    editMessage(message) {
      const m = this.messageList.find(m => m.id === message.id);
      m.isEdited = true;
      m.data.text = message.data.text;
    },

    loadChat() {
      // const { currentUser } = this.$store.state;
      if (this.currentChat) {
        if (this.currentChat.length === 1) {
          this.currentChat = this.currentChat[0];
        }
        const { messages, participants } = this.currentChat;
        this.participants = participants;
        this.messageList = [...messages];
        console.log(messages);
      }

      // this.messageList.forEach(msg => {
      //   if (msg.author === currentUser.id) {
      //     msg.author = "me";
      //   }
      // });
    }
  },
  sockets: {
    // connect: function() {
    //   console.log("socket connected");
    // },
    // messages: function(data) {
    //   const author = data.msg.author,
    //     actualUser = localStorage.getItem("user");
    //   if (data.sofa) {
    //     if (author === `${this.$store.state.sofa}`) {
    //       if (author !== "me" && author !== actualUser) {
    //         this.messageList = [...this.messageList, data.msg];
    //       }
    //     }
    //   } else {
    //     if (data.room === `Event-${this.$store.state.selectedEvent}`) {
    //       if (author !== "me" && author !== actualUser) {
    //         this.messageList = [...this.messageList, data.msg];
    //       }
    //     }
    //   }
    // },
    // joinChat: function(data) {
    //   const ind = this.participants.findIndex(participant => participant.user === data.user);
    //   const ind2 = data.hasOwnProperty("participants")
    //     ? data.participants.findIndex(
    //         participant => participant.name === localStorage.getItem("user")
    //       )
    //     : -1;
    //   if (ind2 >= 0) {
    //     data.participants.splice(ind2, 1);
    //   }
    //   if (ind < 0 && data.hasOwnProperty("participants")) {
    //     this.participants = [...data.participants];
    //   }
    // },
    // disjoinChat: function(data) {
    //   const ind = this.participants.findIndex(participant => participant.id === data.user);
    //   this.participants.splice(ind, 1);
    // },
    // joinSofa: function(data) {
    //   const ind = this.participants.findIndex(participant => participant.id === data.user);
    //   if (data.user !== localStorage.getItem("user")) {
    //     this.participants.push({
    //       id: data.user,
    //       name: data.user,
    //       imageUrl: data.participantImg
    //     });
    //   }
    // }
  },
  computed: mapState(["showChatSB", "updatedChat"]),
  watch: {
    showChatSB(newValue) {
      this.activeSidebar = newValue;
      if (newValue) {
        const { currentChat, currentUser } = this.$store.state;
        this.currentChat = currentChat || {};
        this.currentUser = currentUser;
        this.loadChat();
        this.onReceiveMsg();
      }
    },
    updatedChat(newValue) {
      this.currentChat = newValue;
      this.loadChat();
    }
  },
  data() {
    const { currentUser, currentEvent, currentChat, showChatSB } = this.$store.state;
    return {
      activeChatGUS: false,
      events: [],
      event_id: currentEvent.id,
      active: "test",
      activeSidebar: showChatSB,
      currentUser,
      currentChat: {},
      profileImg: null,
      participants: [], //`name` is the user name, `id` is used to establish the author of a message, `imageUrl` is supposed to be the user avatar.
      titleImageUrl:
        "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.iconfinder.com%2Ficons%2F379512%2F2_chat_icon&psig=AOvVaw3eP2yyJuxNPTb1h50hmOOY&ust=1598562516753000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCKD_psrjuesCFQAAAAAdAAAAABAD",
      messageList: [
        // {
        //   type: "text",
        //   author: `me`,
        //   suggestions: ["some quick reply", "another one"],
        //   data: { text: `Hola Ivan, Que tal?` }
        // },
        // { type: "text", author: `Softonick`, data: { text: `Que pasa tio? Genial!` } }
      ], // the list of the messages to show, can be paginated and adjusted dynamically
      newMessagesCount: 0,
      isChatOpen: true, // to determine whether the chat window should be open or closed
      showTypingIndicator: "", // when set to a value matching the participant.id it shows the typing indicator for the specific user
      colors: {
        header: {
          bg: "#0b4952",
          text: "#ffffff"
        },
        launcher: {
          bg: "#4e8cff"
        },
        messageList: {
          bg: "#ffffff"
        },
        sentMessage: {
          bg: "#0b4952",
          text: "#ffffff"
        },
        receivedMessage: {
          bg: "#eaeaea",
          text: "#222222"
        },
        userInput: {
          bg: "#f4f7f9",
          text: "#565867"
        }
      }, // specifies the color scheme for the component
      alwaysScrollToBottom: true, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
      messageStyling: true // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown)
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.onLoad();
    });
  }
};
</script>

<style scoped>
@media (min-width: 992px) {
  .sbMenu {
    top: 30px;
    max-height: calc(100% - 54px);
  }
}

@media (max-width: 991px) {
  .sbMenu {
    top: 54px;
    max-height: calc(100% - 54px);
  }
}
</style>

<style>
.sc-chat-window.opened {
  width: 100%;
  height: calc(100% - 1px);
  max-height: 100%;
  position: fixed;
  right: 5px;
  bottom: 0px;
}

.sc-message {
  width: 90%;
}
</style>
