import firebase from 'firebase/app';
import 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyAe1MOUxSdMhvySNb4kv7uuMUkU7-jnz_E",
    authDomain: "bmyplan.firebaseapp.com",
    projectId: "bmyplan",
    storageBucket: "bmyplan.appspot.com",
    messagingSenderId: "50062472184",
    appId: "1:50062472184:web:243e6831dd48a91c791c0d",
    measurementId: "G-EZF04QMKS8"
};

firebase.initializeApp(firebaseConfig);

export default firebase;