<template>
  <div v-if="activeSidebar">
    <vs-sidebar
      class="sbMenu"
      reduce
      right
      hover-expand
      v-model="active"
      :open.sync="activeSidebar"
    >
      <template #logo v-if="activeSidebar">
        <img :src="require('@/assets/android-chrome-512x512_green.png')" />
      </template>
      <vs-row justify="space-evenly">
        <vs-avatar badge-color="success" badge-position="top-right">
          <img :src="profileImg || require('@/assets/Avatar-Sizes-Custom-1.jpg')" alt="Avatar" />
        </vs-avatar>
        <small>{{ this.username }}</small>
      </vs-row>
      <vs-sidebar-item id="profile" v-if="username">
        <template #icon>
          <i class="fas fa-user-cog"></i>
        </template>
        <span @click="goGeneral">
          General
        </span>
      </vs-sidebar-item>
      <vs-sidebar-item id="profile" v-if="username" to="profile">
        <template #icon>
          <i class="fas fa-user-secret"></i>
        </template>
        Privacidad
      </vs-sidebar-item>
      <vs-sidebar-item id="profile" v-if="username" to="privacitySettings">
        <template #icon>
          <i class="fas fa-id-badge"></i>
        </template>
        Perfil y etiquetados
      </vs-sidebar-item>
      <vs-sidebar-item id="profile" v-if="username" to="blocks">
        <template #icon>
          <i class="fas fa-ban"></i>
        </template>
        Bloqueos
      </vs-sidebar-item>
      <vs-sidebar-item id="settings" v-if="username" to="settings">
        <template #icon>
          <i class="fas fa-bell"></i>
        </template>
        Notificaciones y alertas
      </vs-sidebar-item>
      <vs-sidebar-item id="profile" v-if="username" to="suggestions">
        <template #icon>
          <i class="fas fa-envelope-open"></i>
        </template>
        Buzon de sugerencias
      </vs-sidebar-item>

      <template #footer>
        <vs-button block danger @click="onCloseSB">
          <i class="fas fa-times-circle"></i>
          <template #animate><i class="fas fa-times-circle"></i>Close </template>
        </vs-button>
      </template>
    </vs-sidebar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
  name: "globalSettingsSB",
  methods: {
    onCloseSB() {
      this.$store.commit("showGlobalSettingsSB", false);
      this.activeSidebar = false;
    },

    goGeneral() {
      this.$store.commit("showUserSettingsSB", true);
      this.$router.push({ name: "Profile" });
    },

    showPrivacityDialog() {
      this.$store.commit("showPrivacity", true);
    }
  },
  computed: mapState(["showGlobalSettingsSB", "currentUser"]),
  watch: {
    currentUser(newValue, oldValue) {
      const { ProfileImg, nick } = newValue;
      if (ProfileImg && ProfileImg.data) {
        const paso1 = new Uint8Array(ProfileImg.data);
        const paso2 = paso1.reduce((data, byte) => data + String.fromCharCode(byte), "");
        ProfileImg = paso2;
        this.profileImg = newValue ? ProfileImg : this.profileImg;
      } else if (ProfileImg) {
        this.profileImg = newValue ? ProfileImg : this.profileImg;
      }

      this.username = nick;

      this.$store.commit("showGlobalSettingsSB", false);
    },
    showGlobalSettingsSB(newValue, oldValue) {
      this.activeSidebar = newValue;
    }
  },
  data() {
    return {
      variant: "info",
      active: "test",
      activeSidebar: this.$store.state.showGlobalSettingsSB,
      myUser: this.$store.state.currentUser,
      profileImg: this.$store.state.currentUser.ProfileImg || null,
      username: localStorage.getItem("user") || this.$store.state.currentUser.nick || null
    };
  },
  mounted() {
    this.profileImg = this.$store.state.currentUser
      ? this.$store.state.currentUser.ProfileImg
      : this.profileImg;
  }
};
</script>

<style scoped>
@media (min-width: 992px) {
  .sbMenu {
    top: 30px;
    max-height: calc(100% - 54px);
  }
}

@media (max-width: 991px) {
  .sbMenu {
    top: 54px;
    max-height: calc(100% - 78px);
  }
}

.sbMenu {
  border-radius: 30px 0px 0px 30px;
  z-index: 1;
}
</style>
