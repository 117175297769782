<template>
  <svg
    class="byourplanIllus"
    xmlns="http://www.w3.org/2000/svg"
    id="ae72d77f-f397-4021-826a-c0dbbd2dad22"
    data-name="Layer 1"
    width="808.46973"
    height="567"
    viewBox="0 0 808.46973 567"
  >
    <path
      d="M664.875,720.92a90.42786,90.42786,0,0,1-19.39014,10.58c-1.7998.72-3.60986,1.39-5.45019,2H546.105c-.37988-.66-.75-1.33-1.12988-2-7.62012-13.64-14.53028-27.81-16.49024-43.22-2.35009-18.5,4.24024-39.45,20.53028-48.54a32.58509,32.58509,0,0,1,6.73974-2.82c.97022-.3,1.96-.54,2.96-.75a35.21625,35.21625,0,0,1,40.9502,45.78c13.81006-14.16,11.23-37.12,6.46973-56.31-4.77-19.19-10.54981-40.55-.83985-57.79,5.39014-9.59,14.87988-15.54,25.52-17.94.33008-.06994.66016-.13995.98-.2a51.406,51.406,0,0,1,36.93018,6.47c20.35986,12.82,30.80957,37.15,34.1997,60.97C708.395,655.49,696.29492,698.27,664.875,720.92Z"
      transform="translate(-195.76514 -166.5)"
      fill="#f2f2f2"
    />
    <path
      d="M566.085,648.39a79.1513,79.1513,0,0,1,6.83984,49.29,81.30506,81.30506,0,0,1-14.96,33.82c-.50976.68-1.02978,1.35-1.56,2h-3.71c.57032-.65,1.12989-1.32,1.66016-2a77.2942,77.2942,0,0,0,15.93994-61.25,78.02768,78.02768,0,0,0-14.54-33.33c.97022-.3,1.96-.54,2.96-.75A79.95893,79.95893,0,0,1,566.085,648.39Z"
      transform="translate(-195.76514 -166.5)"
      fill="#fff"
    />
    <path
      d="M655.94482,659.46a87.38853,87.38853,0,0,1-6.02978,24.62,75.87862,75.87862,0,0,1-11.78027,19.24c-9.92969,11.95-23.06983,20.94-37.33985,26.92q-1.56006.66-3.1499,1.26c-1.84033.72-3.7002,1.38-5.57031,2H581.94482c2.39014-.6,4.77-1.27,7.12012-2a118.81121,118.81121,0,0,0,16.31006-6.43,90.56485,90.56485,0,0,0,33.87988-27.79c9.48975-13.1,13.68018-28.85,14.39991-44.86.76025-17.13-1.46973-34.57-5.37989-51.24a214.81959,214.81959,0,0,0-18.06982-49.23,1.54519,1.54519,0,0,1,.60986-2.04,1.33644,1.33644,0,0,1,.98-.2,1.1452,1.1452,0,0,1,.75977.64c.99023,1.93005,1.96045,3.86,2.89013,5.81a218.02689,218.02689,0,0,1,17.03028,51.08C655.90479,624.28,657.74463,642.11,655.94482,659.46Z"
      transform="translate(-195.76514 -166.5)"
      fill="#fff"
    />
    <path
      d="M238.23486,449.5a42,42,0,1,1,42-42A42.04766,42.04766,0,0,1,238.23486,449.5Zm0-82a40,40,0,1,0,40,40A40.04528,40.04528,0,0,0,238.23486,367.5Z"
      transform="translate(-195.76514 -166.5)"
      fill="#3f3d56"
    />
    <path
      d="M408.23486,314.5a42,42,0,1,1,42-42A42.04766,42.04766,0,0,1,408.23486,314.5Zm0-82a40,40,0,1,0,40,40A40.04528,40.04528,0,0,0,408.23486,232.5Z"
      transform="translate(-195.76514 -166.5)"
      fill="#3f3d56"
    />
    <path
      d="M590.23486,250.5a42,42,0,1,1,42-42A42.04766,42.04766,0,0,1,590.23486,250.5Zm0-82a40,40,0,1,0,40,40A40.04521,40.04521,0,0,0,590.23486,168.5Z"
      transform="translate(-195.76514 -166.5)"
      fill="#3f3d56"
    />
    <path
      d="M784.23486,316.5a42,42,0,1,1,42-42A42.04766,42.04766,0,0,1,784.23486,316.5Zm0-82a40,40,0,1,0,40,40A40.04521,40.04521,0,0,0,784.23486,234.5Z"
      transform="translate(-195.76514 -166.5)"
      fill="#3f3d56"
    />
    <path
      d="M962.23486,417.5a42,42,0,1,1,42-42A42.04766,42.04766,0,0,1,962.23486,417.5Zm0-82a40,40,0,1,0,40,40A40.04521,40.04521,0,0,0,962.23486,335.5Z"
      transform="translate(-195.76514 -166.5)"
      fill="#3f3d56"
    />
    <path
      d="M432.23486,260.99774l5-1s-4.2,4.7-5,5h0a20.12445,20.12445,0,0,0,0,3c-9.6,41.2-45,23-45,23,12.9.3,14-5,14-5-7.5-1.1-9-6-9-6a3.09,3.09,0,0,0,4-1c-8.1-2.1-7-10-7-10a10.40021,10.40021,0,0,0,4.9,1.9c-.9-1.1-8.5-10.2-3.9-14.9,0,0,5.6,10,20.3,10.9l1-.2a7.8504,7.8504,0,0,1-.2-2.2,10.5,10.5,0,0,1,10.5-10.5c7,0,8.5,4,8.5,4l5-3C435.23486,254.99774,434.73486,259.99774,432.23486,260.99774Z"
      transform="translate(-195.76514 -166.5)"
      fill="#3f3d56"
    />
    <polygon
      points="555.799 539.633 566.808 545.029 592.86 505.136 576.613 497.172 555.799 539.633"
      fill="#ffb8b8"
    />
    <path
      d="M750.51832,701.16257l21.6793,10.62689.00088.00043a15.386,15.386,0,0,1,7.0428,20.58831l-.22009.449-35.49535-17.39948Z"
      transform="translate(-195.76514 -166.5)"
      fill="#2f2e41"
    />
    <polygon
      points="664.893 558.443 677.153 558.442 682.985 511.155 664.891 511.155 664.893 558.443"
      fill="#ffb8b8"
    />
    <path
      d="M857.53075,716.94044l24.1438-.001h.001A15.38605,15.38605,0,0,1,897.062,732.32569v.5l-39.53051.00147Z"
      transform="translate(-195.76514 -166.5)"
      fill="#2f2e41"
    />
    <circle cx="638.5588" cy="207.52824" r="24.56103" fill="#ffb8b8" />
    <path
      d="M858.78174,709.3457a4.50015,4.50015,0,0,1-4.49048-4.30371l-9.49365-126.36133a1.50039,1.50039,0,0,0-2.82837-.647L781.23779,693.97607a4.52273,4.52273,0,0,1-5.99878,1.937l-16.92993-8.46534a4.50886,4.50886,0,0,1-2.0227-6.01611L792.52515,608l24.00268-55.00537c9.26465-18.249,19.10279-27.65332,30.07642-28.75049,11.25805-1.124,23.28076,6.6167,36.76172,23.667l.10986.13916-.0022.17725-2,154.35742a4.47894,4.47894,0,0,1-4.08008,4.47412l-18.17138,2.26563Q859.00061,709.346,858.78174,709.3457Z"
      transform="translate(-195.76514 -166.5)"
      fill="#2f2e41"
    />
    <path
      d="M808.40139,561.87807A10.74267,10.74267,0,0,0,810.77,545.57663l3.70353-97.85548-18,2.24325-1.51775,95.19921a10.80091,10.80091,0,0,0,13.4456,16.71446Z"
      transform="translate(-195.76514 -166.5)"
      fill="#ffb8b8"
    />
    <path
      d="M819.78781,465.02187,795.61557,462.275a4.81688,4.81688,0,0,1-4.11084-6.02512l6.24058-23.43752a13.37737,13.37737,0,0,1,26.57458,3.09873l.82582,24.16161a4.81687,4.81687,0,0,1-5.3579,4.94913Z"
      transform="translate(-195.76514 -166.5)"
      fill="#f50057"
    />
    <path
      d="M923.91417,403.42193a10.52722,10.52722,0,0,0-.2393,1.64013l-42.95744,24.782L870.276,423.83311l-11.13116,14.57227,17.45034,12.43755a8,8,0,0,0,9.59819-.23384l44.29652-34.94583a10.4971,10.4971,0,1,0-6.57573-12.24133Z"
      transform="translate(-195.76514 -166.5)"
      fill="#ffb8b8"
    />
    <path
      d="M879.28131,431.01779l-13.88374,17.994a4.5,4.5,0,0,1-6.80392.37267l-15.71732-16.32121A12.49742,12.49742,0,0,1,858.203,413.31908l19.713,11.02279a4.5,4.5,0,0,1,1.36538,6.67592Z"
      transform="translate(-195.76514 -166.5)"
      fill="#f50057"
    />
    <path
      d="M890.60684,555.56344,863.953,425.73434a24.34536,24.34536,0,0,0-27.8501-19.1181l-8.88916,1.48156a23.83816,23.83816,0,0,0-15.47949,9.65845h0a33.29042,33.29042,0,0,0-4.97315,28.30975l14.7124,52.65515-10.91845,56.41211a3.99588,3.99588,0,0,0,4.48681,4.72034c5.46631-.7821,14.47657-1.89844,24.8252-2.50671l5.60644-21.62574,2.28028,21.28235c12.7124-.35583,26.5122.26709,37.89648,3.22461A4.00314,4.00314,0,0,0,890.60684,555.56344Z"
      transform="translate(-195.76514 -166.5)"
      fill="#f50057"
    />
    <path
      d="M843.77483,368.56374c6.31271,4.32051,14.60145,8.76174,21.04891,4.16075,4.23352-3.02109,5.56737-8.61545,3.85059-13.52487-3.08725-8.82857-11.56942-12.71531-19.68051-15.69458-10.54441-3.87306-22.02573-6.95424-32.91112-4.18055s-20.50561,13.31291-18.54058,24.37292c1.58022,8.89423,9.97,16.29492,8.779,25.24958-1.19867,9.01215-11.33379,13.7559-20.28149,15.36659s-18.96039,2.12821-25.39074,8.5552c-8.2031,8.19879-6.15465,22.85324,1.56413,31.50954s19.36684,12.58012,30.65381,15.24758c14.95454,3.53422,31.12905,5.3916,45.33208-.47379s25.25566-21.65433,21.14127-36.45976c-1.73791-6.25379-5.83191-11.53909-9.78854-16.68451s-7.9631-10.526-9.47238-16.83888c-1.25772-5.26073-.32682-11.36869,3.16182-15.31484a4.437,4.437,0,0,0,.59439-5.18643Z"
      transform="translate(-195.76514 -166.5)"
      fill="#2f2e41"
    />
    <path
      d="M601.67412,187.72272a10.59017,10.59017,0,0,1,10.55849,10.5585V219.396a10.59016,10.59016,0,0,1-10.55849,10.55849H580.55939a10.59017,10.59017,0,0,1-10.5585-10.55849V198.28122a10.59017,10.59017,0,0,1,10.5585-10.5585h21.11473m0-4.22272H580.55939a14.82446,14.82446,0,0,0-14.78122,14.78122V219.396a14.82446,14.82446,0,0,0,14.78122,14.78122h21.11473A14.82446,14.82446,0,0,0,616.45534,219.396V198.28122A14.82446,14.82446,0,0,0,601.67412,183.5Z"
      transform="translate(-195.76514 -166.5)"
      fill="#3f3d56"
    />
    <path
      d="M604.84144,198.28122a3.16053,3.16053,0,1,1,.01359,0Q604.84823,198.28123,604.84144,198.28122Z"
      transform="translate(-195.76514 -166.5)"
      fill="#3f3d56"
    />
    <path
      d="M591.11675,200.392a8.44657,8.44657,0,1,1-8.44657,8.44657,8.44657,8.44657,0,0,1,8.44657-8.44657m0-4.22272A12.66929,12.66929,0,1,0,603.786,208.83858,12.6693,12.6693,0,0,0,591.11675,196.16929Z"
      transform="translate(-195.76514 -166.5)"
      fill="#3f3d56"
    />
    <polygon
      points="163.168 553.731 151.235 550.919 156.4 503.554 174.012 507.704 163.168 553.731"
      fill="#ffb8b8"
    />
    <path
      d="M359.25123,732.51534l-38.47709-9.06507.11464-.48668a15.38605,15.38605,0,0,1,18.50437-11.4485l.00095.00023,23.50039,5.53669Z"
      transform="translate(-195.76514 -166.5)"
      fill="#2f2e41"
    />
    <polygon
      points="209.67 554.131 197.41 554.131 191.578 506.843 209.672 506.844 209.67 554.131"
      fill="#ffb8b8"
    />
    <path
      d="M408.56139,732.51534l-39.53052-.00146v-.5a15.38605,15.38605,0,0,1,15.38647-15.38623h.001l24.1438.001Z"
      transform="translate(-195.76514 -166.5)"
      fill="#2f2e41"
    />
    <path
      d="M471.64879,533.61586a10.74272,10.74272,0,0,0-5.23466-15.61877L430.51993,426.974l-19.97048,12.123,41.56244,85.6607a10.80091,10.80091,0,0,0,19.5369,8.85815Z"
      transform="translate(-195.76514 -166.5)"
      fill="#ffb8b8"
    />
    <path
      d="M438.14829,442.02715l-22.81487,8.44533a4.81688,4.81688,0,0,1-6.38562-3.5249l-4.99553-23.73408a13.37737,13.37737,0,0,1,25.11789-9.21423L440.69971,435.194a4.81687,4.81687,0,0,1-2.55142,6.83311Z"
      transform="translate(-195.76514 -166.5)"
      fill="#f50057"
    />
    <path
      d="M404.62053,703.65545q-.08971,0-.18017-.00293l-17.37744-.69531a4.47411,4.47411,0,0,1-4.31958-4.5586l1.33325-96a1.5,1.5,0,0,0-2.9917-.17773l-10.08081,96.10547a4.51379,4.51379,0,0,1-3.7356,3.96972l-.0603.00977-16.31372-1.28223A4.49954,4.49954,0,0,1,345.717,696.549l.97534-162.77929,80.051,5.0664L409.09343,699.64666A4.51488,4.51488,0,0,1,404.62053,703.65545Z"
      transform="translate(-195.76514 -166.5)"
      fill="#2f2e41"
    />
    <circle cx="194.22321" cy="200.60902" r="24.56103" fill="#ffb8b8" />
    <path
      d="M422.76311,546.0031q-.16918,0-.33984-.01171l-73.98365-5.41309a4.49887,4.49887,0,0,1-4.17089-4.583l1.95068-92.90429a41.90912,41.90912,0,1,1,83.6394,2.78613c-1.2937,14.31836-2.32275,76.87012-2.60742,95.70019a4.495,4.495,0,0,1-4.48828,4.42578Z"
      transform="translate(-195.76514 -166.5)"
      fill="#f50057"
    />
    <path
      d="M290.9178,394.69168a10.52739,10.52739,0,0,1,.2393,1.64013l42.95745,24.782,10.44141-6.01094,11.13116,14.57228-17.45034,12.43754a8,8,0,0,1-9.59819-.23384L284.34207,406.933a10.4971,10.4971,0,1,1,6.57573-12.24133Z"
      transform="translate(-195.76514 -166.5)"
      fill="#ffb8b8"
    />
    <path
      d="M334.70478,418.63666a4.49518,4.49518,0,0,1,2.21126-3.025l19.713-11.02278A12.49741,12.49741,0,0,1,371.95564,424.333l-15.71732,16.32122a4.5,4.5,0,0,1-6.80392-.37268l-13.88374-17.994A4.495,4.495,0,0,1,334.70478,418.63666Z"
      transform="translate(-195.76514 -166.5)"
      fill="#f50057"
    />
    <path
      d="M402.68084,386.55389a22.37115,22.37115,0,0,1-18.84815-10.584c-.21631-.33887-1.39844-2.07519-2.76733-4.085-3.84668-5.64844-4.74854-6.99219-4.825-7.18945-1.041-2.68555-4.345-3.72266-6.93335-4.12012-.48291-.07422-.97266-.13965-1.46412-.20605-3.6455-.48829-7.415-.99317-9.48168-5-1.51612-2.94043-3.99537-14.91407-2.09131-17.83985,2.387-3.66992,6.9707-2.47851,11.40356-1.32519,2.83643.73632,5.7688,1.499,8.10571,1.01269,10.12915-2.10742,21.60987-4.49609,31.74878,1.67871,8.06958,4.91309,12.92334,14.19239,12.667,24.2168-.27515,10.76465-4.97,19.26172-12.55884,22.73047l-1.57984.46-.88867.10937A21.99056,21.99056,0,0,1,402.68084,386.55389Z"
      transform="translate(-195.76514 -166.5)"
      fill="#2f2e41"
    />
    <path
      d="M963.70557,348.29873a27.54394,27.54394,0,0,0-11.05351,52.77163,24.39144,24.39144,0,0,1,.47952-6.31981c.5287-2.23775,3.54106-15.01262,3.54106-15.01262a10.51457,10.51457,0,0,1-.88527-4.35255c0-4.082,2.36071-7.1313,5.31159-7.1313,2.50825,0,3.7132,1.88119,3.7132,4.13124,0,2.52054-1.6107,6.28292-2.43448,9.7748a4.25805,4.25805,0,0,0,4.35255,5.29929c5.21323,0,8.7297-6.701,8.7297-14.64376,0-6.037-4.06976-10.5494-11.45926-10.5494-8.34854,0-13.56177,6.23374-13.56177,13.1929a7.939,7.939,0,0,0,1.81971,5.39766,1.34617,1.34617,0,0,1,.39345,1.53692c-.13525.50411-.43034,1.72135-.56559,2.21316a.94626.94626,0,0,1-1.37708.68854c-3.84844-1.5738-5.64356-5.77881-5.64356-10.52482,0-7.81984,6.6026-17.20119,19.68485-17.20119,10.51252,0,17.43481,7.61082,17.43481,15.77493,0,10.80762-6.01243,18.87336-14.86508,18.87336-2.97547,0-5.76651-1.61069-6.72555-3.4304,0,0-1.5984,6.3444-1.94267,7.57393a22.89215,22.89215,0,0,1-2.76645,5.90177,27.54626,27.54626,0,1,0,7.81983-53.96428Z"
      transform="translate(-195.76514 -166.5)"
      fill="#3f3d56"
    />
    <path
      d="M808.61161,257.96668l-35.42445-10.89856a3.62243,3.62243,0,0,0-4.64388,2.18985l-10.92275,35.50308a3.90737,3.90737,0,0,0,2.50783,4.75312l35.414,10.89535a3.70923,3.70923,0,0,0,4.5242-2.58967l10.92275-35.50308A3.40454,3.40454,0,0,0,808.61161,257.96668Zm-36.77914,27.41441-6.08077-1.87079,5.8167-18.90649,6.08078,1.87079Zm3.871-22.65176-.04359-.01341a3.45895,3.45895,0,1,1,.04359.01341Zm18.3525,29.489-6.08078-1.87079,3.18046-10.3377c.762-2.47665.39764-4.44106-1.80235-5.1179a3.47858,3.47858,0,0,0-3.80863,1.28522,4.2856,4.2856,0,0,0-.66972,1.42849l-3.31309,10.76878-6.08077-1.87079,5.8167-18.90649,6.08078,1.87079-.80948,2.63109a6.44223,6.44223,0,0,1,6.42948-1.3864c3.99163,1.228,6.20171,4.78812,4.45655,10.46054Z"
      transform="translate(-195.76514 -166.5)"
      fill="#3f3d56"
    />
    <path
      d="M265.69106,407.66486a27.4562,27.4562,0,1,0-31.74623,27.124v-19.185h-6.97314v-7.939h6.97314v-6.05017c0-6.88,4.10005-10.6834,10.37084-10.6834a42.19252,42.19252,0,0,1,6.147.53686v6.75742h-3.4639c-3.40874,0-4.47512,2.11559-4.47512,4.29v5.15048h7.6142l-1.21592,7.939h-6.39828v19.18625a27.46357,27.46357,0,0,0,23.1674-27.12648Z"
      transform="translate(-195.76514 -166.5)"
      fill="#3f3d56"
      fill-rule="evenodd"
    />
    <path
      d="M1003.76514,732.5a1.00308,1.00308,0,0,1-1,1h-806a1,1,0,1,1,0-2h806A1.00308,1.00308,0,0,1,1003.76514,732.5Z"
      transform="translate(-195.76514 -166.5)"
      fill="#3f3d56"
    />
  </svg>
</template>

<script>
export default {
  name: "iInteraction",
  props: [],
  methods: {},
  data: () => {
    return {};
  }
};
</script>

<style scoped>
.byourplanIllus {
  width: 100%;
  height: auto;
}
</style>
