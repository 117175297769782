<template>
  <div v-if="activeSidebar">
    <vs-sidebar
      class="sbMenu"
      reduce
      right
      hover-expand
      v-model="active"
      :open.sync="activeSidebar"
    >
      <template #logo v-if="activeSidebar">
        <img :src="require('@/assets/android-chrome-512x512_green.png')" />
      </template>
      <vs-row justify="space-evenly">
        <vs-avatar badge-color="success" badge-position="top-right">
          <img :src="profileImg || require('@/assets/Avatar-Sizes-Custom-1.jpg')" alt="Avatar" />
          <template v-if="notifications.length > 0" #badge>
            {{ notifications.length }}
          </template>
        </vs-avatar>
        <vs-button danger v-if="username" @click="logout">
          <i class="fas fa-door-closed" alt="Logout"></i>
          <template #animate>Logout </template>
        </vs-button>
        <vs-button success v-else to="login">
          <i class="fas fa-door-open" alt="Login"></i>
          <template #animate>Login </template>
        </vs-button>
      </vs-row>
      <vs-sidebar-item id="profile" v-if="username" to="profile">
        <template #icon>
          <i class="fas fa-id-badge"></i>
        </template>
        Profile
      </vs-sidebar-item>
      <vs-sidebar-item id="settings" v-if="username" @click="onCloseSB">
        <!-- to="settings" -->
        <template #icon>
          <i class="fas fa-user-cog"></i>
        </template>
        <vs-button @click="onOpenGlobalSettings" transparent>User settings</vs-button>
      </vs-sidebar-item>
      <vs-sidebar-group v-if="username">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="fas fa-thumbs-up"></i>
            </template>
            Social media
          </vs-sidebar-item>
        </template>

        <vs-sidebar-item id="Instagram">
          <template #icon>
            <i class="bx bxl-instagram"></i>
          </template>
          Instagram
        </vs-sidebar-item>
        <vs-sidebar-item id="twitter">
          <template #icon>
            <i class="bx bxl-twitter"></i>
          </template>
          Twitter
        </vs-sidebar-item>
        <vs-sidebar-item id="Facebook">
          <template #icon>
            <i class="bx bxl-facebook"></i>
          </template>
          Facebook
        </vs-sidebar-item>
      </vs-sidebar-group>
      <vs-sidebar-item id="chat" v-if="username">
        <template #icon>
          <i class="fas fa-comment-dots"></i>
        </template>
        <vs-button @click="onOpenChat" transparent>Chats</vs-button>
      </vs-sidebar-item>
      <vs-sidebar-item id="privacity" v-if="username" to="privacity">
        <template #icon>
          <i class="fas fa-info-circle"></i>
        </template>
        Privacity
      </vs-sidebar-item>
      <vs-sidebar-item v-if="username">
        <!-- <template #icon>
          <i class="fas fa-bell"></i>
        </template> -->
        Notificaciones
        <i class="fas fa-bell"></i>
        <div class="px-3 py-2" v-for="(notif, ind) in notifications" :key="ind">
          <vs-alert
            relief
            color="warn"
            flat
            closable
            v-model="activeSidebar"
            style="padding: 0px;
                   height:min-content;
                   padding-right: 1rem;"
          >
            <!-- <template #title> -->
            <i class="fas fa-plus-circle"></i>
            <a href="#">{{ notif.Msg }}</a>
            <!-- </template> -->
          </vs-alert>
        </div>
      </vs-sidebar-item>
      <template #footer>
        <vs-button block danger @click="onCloseSB">
          <i class="fas fa-times-circle"></i>
          <template #animate><i class="fas fa-times-circle"></i>Close </template>
        </vs-button>
      </template>
    </vs-sidebar>
  </div>
</template>

<script>
import { loadingMix, noficationsDialog, authMix } from "@/common/mixin.js";
import { mapState } from "vuex";

export default {
  name: "notificationSB",
  mixins: [loadingMix, noficationsDialog, authMix],
  props: ["notifications"],
  methods: {
    onCloseSB() {
      this.$store.commit("showNotificationSB", false);
      this.activeSidebar = false;
    },
    onOpenChat() {
      this.$store.commit("showChatListSB", true);
    },
    onOpenGlobalSettings() {
      this.$store.commit("showGlobalSettingsSB", true);
    },
    showPrivacityDialog() {
      this.$store.commit("showPrivacity", true);
    },
    logout() {
      this.firebaseLogout();
      localStorage.removeItem("user");
      localStorage.setItem("privacity", true);
      this.$router.go(this.$router.currentRoute);
    }
  },
  computed: mapState(["showNotificationSB", "currentUser"]),
  watch: {
    currentUser(newValue, oldValue) {
      const { profileImg, username } = newValue;
      if (profileImg) {
        this.profileImg = newValue ? profileImg : this.profileImg;
      }
      this.username = username;
      this.$store.commit("showNotificationSB", false);
    },
    showNotificationSB(newValue, oldValue) {
      this.activeSidebar = newValue;
    }
  },
  data() {
    const { showNotificationSB, currentUser } = this.$store.state;
    return {
      variant: "info",
      active: "test",
      activeSidebar: showNotificationSB,
      myUser: currentUser,
      profileImg: currentUser.profileImg || null,
      username: currentUser.username || null
    };
  },
  mounted() {
    this.profileImg = this.$store.state.currentUser
      ? this.$store.state.currentUser.ProfileImg
      : this.profileImg;
  }
};
</script>

<style scoped>
@media (min-width: 992px) {
  .sbMenu {
    top: 30px;
    max-height: calc(100% - 54px);
  }
}

@media (max-width: 991px) {
  .sbMenu {
    top: 54px;
    max-height: calc(100% - 78px);
  }
}

.sbMenu {
  border-radius: 30px 0px 0px 30px;
  z-index: 1;
}
</style>
