<template>
  <svg
    class="byourplanIllus"
    xmlns="http://www.w3.org/2000/svg"
    id="e048613e-aa78-44fc-a819-d6e20e102ddb"
    data-name="Layer 1"
    width="852"
    height="663.77323"
    viewBox="0 0 852 663.77323"
  >
    <path
      d="M423.65393,672.90162l-.09044-15.217-1.34188-225.78654-.05022-8.44917-.73855-124.26879a272.62993,272.62993,0,0,0-34.63122-18.45963C339.89749,259.95,284.12691,251.38508,237.94713,273.709a109.52338,109.52338,0,0,0-53.40834,59.44242c-.27262.68268-.52409,1.344-.75428,2.00511-7.7017,21.96757-7.645,45.82581,3.62984,66.42489,20.18374,37.0194,65.32174,50.69169,105.412,63.75549,40.069,13.06393,84.50723,34.33852,94.49837,75.29206a75.09186,75.09186,0,0,0-129.03564,22.32686c-.73275,2.04755-1.40152,4.116-1.985,6.20524a71.19,71.19,0,0,0-2.50515,15.38143c-1.9562,39.705,28.47751,75.28,64.58788,91.94309,32.65286,15.04478,69.29847,17.91307,105.40353,19.35859Z"
      transform="translate(-174 -118.11338)"
      fill="#f0f0f0"
    />
    <path
      d="M423.56408,657.68457l.09043,15.21707a164.61759,164.61759,0,0,0-90.66324-92.38412,166.33637,166.33637,0,0,0-76.68737-11.35644c.5841-2.08919,1.25249-4.15771,1.98545-6.20518a177.2633,177.2633,0,0,1,30.43742.45757,169.023,169.023,0,0,1,96.84089,43.33192A172.664,172.664,0,0,1,423.56408,657.68457Z"
      transform="translate(-174 -118.11338)"
      fill="#fff"
    />
    <path
      d="M422.172,423.44887l.05022,8.44923a211.52024,211.52024,0,0,0-27.29014-22.22788c-30.13293-20.74243-64.17233-36.41739-98.72143-48.15208a458.51852,458.51852,0,0,0-109.44714-22.95265,3.28146,3.28146,0,0,1-2.97893-3.40887,2.8112,2.8112,0,0,1,.754-2.00512,2.3283,2.3283,0,0,1,2.01872-.608c4.59976.377,9.178.79684,13.77757,1.32289A463.34148,463.34148,0,0,1,311.913,360.765c34.68464,13.03214,68.98651,29.85492,98.30165,52.70924C414.34137,416.62094,418.34132,419.93866,422.172,423.44887Z"
      transform="translate(-174 -118.11338)"
      fill="#fff"
    />
    <circle cx="48.75937" cy="104.77148" r="12.76975" fill="#f0f0f0" />
    <path
      d="M746.26258,275.8507h-3.6471V175.93939a57.82594,57.82594,0,0,0-57.82589-57.826H473.11384a57.826,57.826,0,0,0-57.82606,57.82582V724.06061a57.826,57.826,0,0,0,57.82589,57.826H684.78931a57.826,57.826,0,0,0,57.82611-57.82578V346.96928h3.64716Z"
      transform="translate(-174 -118.11338)"
      fill="#3f3d56"
    />
    <path
      d="M730.3088,176.34336v547.32c0,.8-.02,1.59-.07031,2.38a43.16785,43.16785,0,0,1-43.11963,40.8H474.42843a43.1639,43.1639,0,0,1-43.11963-40.96q-.06006-1.09506-.06006-2.22v-547.32a43.18554,43.18554,0,0,1,43.17969-43.19h25.81006a20.51955,20.51955,0,0,0,18.99023,28.27h121.27a20.52028,20.52028,0,0,0,18.98975-28.27h27.63037A43.19429,43.19429,0,0,1,730.3088,176.34336Z"
      transform="translate(-174 -118.11338)"
      fill="#fff"
    />
    <path
      d="M726.38075,589.11338H435.1695a3.86293,3.86293,0,0,1-3.8588-3.85821V227.982a3.86294,3.86294,0,0,1,3.8588-3.85821H726.38075a3.86288,3.86288,0,0,1,3.8588,3.85821V585.25517A3.86288,3.86288,0,0,1,726.38075,589.11338Z"
      transform="translate(-174 -118.11338)"
      fill="#f0f0f0"
    />
    <path
      d="M705.0085,249.05339H457.13839a3.86227,3.86227,0,0,0-3.85987,3.86v258.15a3.86016,3.86016,0,0,0,3.85987,3.85H705.0085a3.86058,3.86058,0,0,0,3.86036-3.85v-258.15A3.86269,3.86269,0,0,0,705.0085,249.05339Z"
      transform="translate(-174 -118.11338)"
      fill="#fff"
    />
    <circle cx="278.83858" cy="83" r="10" fill="#f50057" />
    <path
      d="M500.40375,527.64281c-17.07585-27.502-11.19192-62.63744-8.31751-94.88158A433.10685,433.10685,0,0,0,480.6263,288.53865a111.21365,111.21365,0,0,1,26.90727,56.249c2.8189,17.45425,1.42608,35.41182,4.61932,52.80147,6.84724,37.28854,34.4214,70.91706,30.15389,108.58811-.94327,8.32666-4.11641,17.28242-11.50927,21.22813-8.34491,4.45382-18.53065.91127-27.284-2.67387"
      transform="translate(-174 -118.11338)"
      fill="#f0f0f0"
    />
    <path
      d="M471.58584,539.05812a9.99606,9.99606,0,0,0-14.172,14.10109l.03435.03434L471.58584,567.329l14.13761-14.13542a9.99606,9.99606,0,0,0-14.13761-14.13542Z"
      transform="translate(-174 -118.11338)"
      fill="#f50057"
    />
    <path
      d="M515.58584,539.05812a9.99606,9.99606,0,0,0-14.172,14.10109l.03435.03434L515.58584,567.329l14.13761-14.13542a9.99606,9.99606,0,0,0-14.13761-14.13542Z"
      transform="translate(-174 -118.11338)"
      fill="#f50057"
    />
    <path
      d="M559.58584,539.05812a9.99606,9.99606,0,0,0-14.172,14.10109l.03435.03434L559.58584,567.329l14.13761-14.13542a9.99606,9.99606,0,0,0-14.13761-14.13542Z"
      transform="translate(-174 -118.11338)"
      fill="#f50057"
    />
    <path
      d="M637.3066,208.95578H496.29182a7.71642,7.71642,0,1,1,0-15.43283H637.3066a7.71642,7.71642,0,1,1,0,15.43283Z"
      transform="translate(-174 -118.11338)"
      fill="#f50057"
    />
    <path
      d="M726.37862,656.12339h-291.21a3.86227,3.86227,0,0,0-3.85986,3.86v65.9a43.1639,43.1639,0,0,0,43.11963,40.96H687.11886a43.16785,43.16785,0,0,0,43.11963-40.8v-66.06A3.86228,3.86228,0,0,0,726.37862,656.12339Z"
      transform="translate(-174 -118.11338)"
      fill="#f0f0f0"
    />
    <path
      d="M705.0085,681.05339H457.13839a3.86227,3.86227,0,0,0-3.85987,3.86v76.4a42.9523,42.9523,0,0,0,21.14991,5.53H687.11886a43.007,43.007,0,0,0,21.75-5.87v-76.06A3.86269,3.86269,0,0,0,705.0085,681.05339Z"
      transform="translate(-174 -118.11338)"
      fill="#fff"
    />
    <circle cx="278.83858" cy="515" r="10" fill="#e4e4e4" />
    <path
      d="M637.3066,640.95578H496.29182a7.71642,7.71642,0,1,1,0-15.43283H637.3066a7.71642,7.71642,0,1,1,0,15.43283Z"
      transform="translate(-174 -118.11338)"
      fill="#e4e4e4"
    />
    <path
      d="M557.59835,766.84336H484.32882c7.46-14.68,21.23-26.63995,37.46-27.27a33.03868,33.03868,0,0,1,7.2998.54c1.00977.18,2,.42,2.99024.68A35.234,35.234,0,0,1,557.59835,766.84336Z"
      transform="translate(-174 -118.11338)"
      fill="#f2f2f2"
    />
    <path
      d="M674.78878,766.84336H575.29854a143.77748,143.77748,0,0,0,3.89014-13.9c4.46973-19.31,9.02-40.99,25.50977-51.97,9.18017-6.12,20.35009-7.12,30.93994-4.42.32031.08.65039.17.97021.27a51.42664,51.42664,0,0,1,30.02,22.55C675.0588,733.51341,676.93868,750.33335,674.78878,766.84336Z"
      transform="translate(-174 -118.11338)"
      fill="#f2f2f2"
    />
    <path
      d="M533.1086,755.05339a77.21519,77.21519,0,0,1-1.0498,11.79h-2.86035a77.90735,77.90735,0,0,0-.10987-26.73c1.00977.18,2,.42,2.99024.68A82.00342,82.00342,0,0,1,533.1086,755.05339Z"
      transform="translate(-174 -118.11338)"
      fill="#fff"
    />
    <path
      d="M636.97872,697.74339c.00977,2.16,0,4.33-.06006,6.5a217.2606,217.2606,0,0,1-8,53.33q-1.28979,4.65-2.79,9.27h-2.93994c1.79981-5.48,3.37012-11.03,4.73-16.59a214.76966,214.76966,0,0,0,6.23975-52.16,1.54952,1.54952,0,0,1,1.48-1.54,1.35047,1.35047,0,0,1,.97021.27A1.10321,1.10321,0,0,1,636.97872,697.74339Z"
      transform="translate(-174 -118.11338)"
      fill="#fff"
    />
    <circle cx="515.79213" cy="592.06554" r="6" fill="#f2f2f2" />
    <circle cx="371.97293" cy="154.19775" r="6" fill="#f50057" />
    <circle cx="482.97293" cy="189.19775" r="6" fill="#e4e4e4" />
    <circle cx="479.97293" cy="311.19775" r="6" fill="#e4e4e4" />
    <path
      d="M627.41866,492.49339l-4.06006-13a75.023,75.023,0,0,0-2.6499-8.92c-4.58008-13.84-14.64014-41.75-26.49023-70.91l-13.94-13.94-3.85986-2.52-3.6499-2.37006-1.57031,1.45-14.96973,13.82-.10986.91-9.78028,83.12s-.02978.23-.08008.68c-.10986.97-.33984,2.95-.63964,5.78-.57032,5.32-1.49024,14.61005-2.40039,26.32h90.57031Z"
      transform="translate(-174 -118.11338)"
      fill="#3f3d56"
    />
    <polygon
      points="389.236 315.262 385.435 338.977 411.157 349.587 389.236 315.262"
      opacity="0.2"
    />
    <path
      d="M609.28878,327.26341a34.342,34.342,0,0,0-59.66992,23.2v25.63a4.62177,4.62177,0,0,0,4.61963,4.62H613.7087a4.6197,4.6197,0,0,0,4.60986-4.62v-25.63A34.28073,34.28073,0,0,0,609.28878,327.26341Z"
      transform="translate(-174 -118.11338)"
      fill="#2f2e41"
    />
    <circle cx="416.53674" cy="235.8684" r="25.18304" fill="#ffb8b8" />
    <path
      d="M609.28878,327.26341a26.90031,26.90031,0,0,0-10.01026-1.92005h-5.12988a27.13066,27.13066,0,0,0-22.08008,11.37c-.16992.22-.31982.45-.47021.67a26.95477,26.95477,0,0,0-4.61963,15.13v.51H577.8088l3.6997-10.35.74024,10.35h5.59961l1.86035-5.22.3701,5.22c11.71057,4.328,23.86023,4.05072,36.36963,0l0-.50994A27.19935,27.19935,0,0,0,609.28878,327.26341Z"
      transform="translate(-174 -118.11338)"
      fill="#2f2e41"
    />
    <path
      d="M593.94845,378.93339a1.32915,1.32915,0,0,0-.07959-.17c-3.39014-6.46-7.64014-16.35-7.88037-25.74-.14014-5.61,1.16015-11.05,4.93994-15.46l.72021-.85H562.33858v44.01l8.85987,1.56,5.22021.92,12.54981,2.22a4.54534,4.54534,0,0,0,.82031.07,4.6,4.6,0,0,0,3.71972-1.9,4.483,4.483,0,0,0,.87989-2.88A4.35287,4.35287,0,0,0,593.94845,378.93339Z"
      transform="translate(-174 -118.11338)"
      fill="#2f2e41"
    />
    <path
      d="M546.95,478.977l0,0a46.258,46.258,0,0,0,55.52705,11.22348l5.481-2.71256Z"
      transform="translate(-174 -118.11338)"
      opacity="0.2"
    />
    <path
      d="M830.95335,554.8051a11.83881,11.83881,0,0,1,14.454-10.98315l15.656-22.06354,15.72841,6.2439-22.54684,30.84747a11.903,11.903,0,0,1-23.29152-4.04468Z"
      transform="translate(-174 -118.11338)"
      fill="#a0616a"
    />
    <path
      d="M848.12763,535.83047l27.83577-46.81574-11.34084-30.24225,15.4458-41.18995.56963.24438c.37349.16,9.14985,4.07636,10.45058,18.10155l17.75,55.78535L861.46621,553.17Z"
      transform="translate(-174 -118.11338)"
      fill="#3f3d56"
    />
    <path
      d="M696.94976,363.72115a11.83883,11.83883,0,0,1,9.83274,15.25992l20.787,17.315-7.44128,15.19857-29.01189-24.86436a11.903,11.903,0,0,1,5.83339-22.90909Z"
      transform="translate(-174 -118.11338)"
      fill="#a0616a"
    />
    <path
      d="M714.53974,382.31108l44.52344,31.37207,31.02855-8.96867,39.87244,18.58422-.28769.549c-.18842.36-4.77159,8.8073-18.85537,9.01975l-56.99073,13.38376L696.2208,394.2691Z"
      transform="translate(-174 -118.11338)"
      fill="#3f3d56"
    />
    <polygon
      points="622.01 645.697 606.531 645.696 599.167 585.993 622.012 585.994 622.01 645.697"
      fill="#a0616a"
    />
    <path
      d="M590.79853,641.27357h34.52764a0,0,0,0,1,0,0v18.79535a0,0,0,0,1,0,0H576.67939a0,0,0,0,1,0,0v-4.67621A14.11914,14.11914,0,0,1,590.79853,641.27357Z"
      fill="#2f2e41"
    />
    <polygon
      points="683.874 645.697 668.396 645.696 661.032 585.993 683.877 585.994 683.874 645.697"
      fill="#a0616a"
    />
    <path
      d="M652.66324,641.27357h34.52764a0,0,0,0,1,0,0v18.79535a0,0,0,0,1,0,0H638.5441a0,0,0,0,1,0,0v-4.67621A14.11914,14.11914,0,0,1,652.66324,641.27357Z"
      fill="#2f2e41"
    />
    <polygon
      points="689.379 427.774 688.117 516.153 686.854 632.307 659.078 629.782 645.19 469.438 627.515 629.782 598.839 629.782 595.951 468.176 606.051 432.825 689.379 427.774"
      fill="#2f2e41"
    />
    <path
      d="M777.28268,551.47731l-4.23146-.18314L792.706,443.01934l-8.04757-32.72642a7.162,7.162,0,0,1,4.43887-8.41385l14.47957-5.42936,5.11538-14.06795,40.36035,4.349.99252,10.9945c4.37359,1.40269,40.38952,13.39722,37.90448,27.06423-2.45269,13.48966-16.93457,55.93036-17.67,58.08119l-2.53618,60.8652-.312.156c-.37552.18775-28.19453,13.86632-58.41656,13.864C798.37218,557.75534,787.42534,556.058,777.28268,551.47731Z"
      transform="translate(-174 -118.11338)"
      fill="#3f3d56"
    />
    <circle
      cx="827.29074"
      cy="346.3602"
      r="29.05964"
      transform="translate(-47.44258 788.02191) rotate(-61.33683)"
      fill="#a0616a"
    />
    <path
      d="M850.82893,374.49076c-5.53807,5.89924-15.823,2.73239-16.54464-5.3268a9.73539,9.73539,0,0,1,.01225-1.88032c.37258-3.56988,2.435-6.81089,1.941-10.58018a5.54824,5.54824,0,0,0-1.01541-2.59731c-4.41313-5.90958-14.77265,2.64319-18.93763-2.70657-2.55386-3.28035.44818-8.4451-1.51162-12.11145-2.58661-4.839-10.248-2.4519-15.05258-5.102-5.34563-2.94852-5.0259-11.15024-1.507-16.13894,4.2914-6.08394,11.81559-9.33032,19.246-9.79819s14.80969,1.54075,21.74668,4.24417c7.88181,3.07163,15.69777,7.31668,20.54813,14.24718,5.89855,8.42824,6.46619,19.75921,3.51621,29.61444C861.47577,362.34986,855.35136,369.6734,850.82893,374.49076Z"
      transform="translate(-174 -118.11338)"
      fill="#2f2e41"
    />
    <path
      d="M1025,780.11338H175a1,1,0,0,1,0-2h850a1,1,0,0,1,0,2Z"
      transform="translate(-174 -118.11338)"
      fill="#3f3d56"
    />
    <path
      d="M640.00647,490.15436H624.43308a2.00269,2.00269,0,0,1-2.00046-1.81438l-3.11727-31.95153H645.1242l-3.11727,31.95113A2.00274,2.00274,0,0,1,640.00647,490.15436Z"
      transform="translate(-174 -118.11338)"
      fill="#f50057"
    />
    <path
      d="M645.083,460.4082H619.35657a2.01224,2.01224,0,0,1-2.00988-2.00987v-4.82371a2.01225,2.01225,0,0,1,2.00988-2.00987H645.083a2.01224,2.01224,0,0,1,2.00987,2.00987v4.82371A2.01223,2.01223,0,0,1,645.083,460.4082Z"
      transform="translate(-174 -118.11338)"
      fill="#3f3d56"
    />
    <path
      d="M634.5809,473.18a11.557,11.557,0,0,0-17.71319.53492L591.441,466.57676l-8.1065,14.39377,36.04907,9.57615A11.61962,11.61962,0,0,0,634.5809,473.18Z"
      transform="translate(-174 -118.11338)"
      fill="#ffb8b8"
    />
    <path
      d="M593.23849,465.37339l-12.06983-7.69-3.43994-1.62-15.55029-7.33c.46-1.58,1.78027-6.3,3.03027-12.28,2.91992-13.99,5.41016-34.88-4.64014-38.54a14.10092,14.10092,0,0,0-4.4497-.9,11.636,11.636,0,0,0-5.86036,1.34c-7.52978,3.93-10.52,14.99-10.62988,15.4l-9.31006,25.55c-.85986,1.18-9.71,13.44-8.27978,22.52a9.52255,9.52255,0,0,0,4,6.52,171.68868,171.68868,0,0,0,20.21972,12.47q2.44483,1.26,4.78028,2.33c8.71972,3.98,16.40967,6.01,22.86963,6.01a25.54926,25.54926,0,0,0,6.27-.73l25.09033-.57.15967-.72.06006-.28,3.33007-15.4.93994-4.36005Z"
      transform="translate(-174 -118.11338)"
      fill="#3f3d56"
    />
  </svg>
</template>

<script>
export default {
  name: "iFollow",
  props: [],
  methods: {},
  data: () => {
    return {};
  }
};
</script>

<style scoped>
.byourplanIllus {
  width: 100%;
  height: auto;
}
</style>
