var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"navBar"},[_c('div',[_c('b-navbar',{class:this.$route.name !== 'home' && this.$route.name !== 'Profile'
            ? 'nBMenuSlim2'
            : 'nBMenuSlim',attrs:{"toggleable":"xl","type":"dark","small":""}},[_c('div',{attrs:{"id":"navBtns"}},[_c('vs-button',{class:this.$route.name !== 'home' && !this.$route.name !== 'Profile'
                ? 'navBarBtn2'
                : 'navBarBtn',attrs:{"floating":"","to":this.$route.name === 'home' ? null : '/',"color":this.$route.name !== 'home' && !this.$route.name !== 'Profile' ? '#f83255' : '#FFF',"size":"large"},scopedSlots:_vm._u([{key:"animate",fn:function(){return [_c('i',{staticClass:"fa fa-home"})]},proxy:true}])},[_vm._v(" "+_vm._s(this.$route.name !== "home" && !this.$route.name !== "Profile" ? "BYP" : "BYourPlan")+" ")])],1),_c('div',{staticClass:"avataname"},[(this.$route.name !== 'search')?_c('vs-button',{attrs:{"transparent":"","color":"danger"}},[_c('i',{staticClass:"fas fa-bell"})]):_vm._e(),_c('vs-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('div',{staticClass:"menuItems",on:{"click":_vm.showUserSettings}},[_c('i',{staticClass:"fas fa-user-cog"}),_vm._v(" Ajustes de perfil ")]),_c('div',{staticClass:"menuItems",on:{"click":_vm.onOpenSettings}},[_c('i',{staticClass:"fas fa-cog"}),_vm._v(" Configuración ")]),_c('div',{staticClass:"menuItems",staticStyle:{"color":"red"},on:{"click":_vm.logout}},[_c('i',{staticClass:"fas fa-power-off"}),_vm._v(" Cerrar sessión ")])]},proxy:true}])},[(this.$route.name === 'Profile')?_c('vs-button',{attrs:{"transparent":"","color":"danger"}},[_c('i',{staticClass:"fas fa-ellipsis-v"})]):_vm._e()],1),(_vm.username && this.$route.name !== 'Profile')?_c('vs-avatar',{staticStyle:{"z-index":"10000"},attrs:{"size":"50","history":"","circle":"","history-gradient":"","badge-color":"success"},scopedSlots:_vm._u([(_vm.notifications.length > 0)?{key:"badge",fn:function(){return [_vm._v(" "+_vm._s(_vm.notifications.length)+" ")]},proxy:true}:null],null,true)},[(_vm.profileImg)?_c('img',{attrs:{"id":"myAvatar","src":_vm.profileImg,"fluid":"","alt":"Avatar image"},on:{"click":function () {
                  this$1.toggleMenu();
                  this$1.$router.push({
                    path: '/profile',
                    query: {
                      t: new Date().getTime()
                    }
                  });
                }}}):_c('iMan',{on:{"click":function () {
                  this$1.toggleMenu();
                  this$1.$router.push({
                    path: '/profile',
                    query: {
                      t: new Date().getTime()
                    }
                  });
                }}})],1):_vm._e(),(!_vm.username)?_c('vs-button',{staticClass:"navBarBtnLogin",attrs:{"color":"#f5f5f5","size":"large"},on:{"click":function () {
                this$1.toggleMenu();
                if (_vm.username) {
                  this$1.$store.commit('showNotificationSB', true);
                } else {
                  this$1.$router.push('/Login');
                }
              }},scopedSlots:_vm._u([{key:"animate",fn:function(){return [_c('i',{staticClass:"fas fa-user"})]},proxy:true}],null,false,2534249907)},[_vm._v(" "+_vm._s(!_vm.username ? "Login" : _vm.username)+" ")]):_vm._e()],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }