<template>
  <vs-dialog scroll overflow-hidden not-close auto-width prevent-close v-model="this.active">
    <template #header>
      <h3>
        Privacity Policy
      </h3>
    </template>
    <div class="con-content">
      <h4>
        POLÍTICA DE PRIVACIDAD
      </h4>
      <p>
        El presente Política de Privacidad establece los términos en que B YOUR PLAN usa y protege
        la información que es proporcionada por sus usuarios al momento de utilizar su sitio web.
        Esta compañía está comprometida con la seguridad de los datos de sus usuarios. Cuando le
        pedimos llenar los campos de información personal con la cual usted pueda ser identificado,
        lo hacemos asegurando que sólo se empleará de acuerdo con los términos de este documento.
        Sin embargo esta Política de Privacidad puede cambiar con el tiempo o ser actualizada por lo
        que le recomendamos y enfatizamos revisar continuamente esta página para asegurarse que está
        de acuerdo con dichos cambios.
      </p>
      <p><strong>Información que es recogida</strong></p>
      <p>
        Nuestro sitio web podrá recoger información personal por ejemplo: Nombre,&nbsp; información
        de contacto como&nbsp; su dirección de correo electrónica e información demográfica. Así
        mismo cuando sea necesario podrá ser requerida información específica para procesar algún
        pedido o realizar una entrega o facturación.
      </p>
      <p><strong>Uso de la información recogida</strong></p>
      <p>
        Nuestro sitio web emplea la información con el fin de proporcionar el mejor servicio
        posible, particularmente para mantener un registro de usuarios, de pedidos en caso que
        aplique, y mejorar nuestros productos y servicios. &nbsp;Es posible que sean enviados
        correos electrónicos periódicamente a través de nuestro sitio con ofertas especiales, nuevos
        productos y otra información publicitaria que consideremos relevante para usted o que pueda
        brindarle algún beneficio, estos correos electrónicos serán enviados a la dirección que
        usted proporcione y podrán ser cancelados en cualquier momento.
      </p>
      <p>
        B YOUR PLAN está altamente comprometido para cumplir con el compromiso de mantener su
        información segura. Usamos los sistemas más avanzados y los actualizamos constantemente para
        asegurarnos que no exista ningún acceso no autorizado.
      </p>
      <p><strong>Cookies</strong></p>
      <p>
        Una cookie se refiere a un fichero que es enviado con la finalidad de solicitar permiso para
        almacenarse en su ordenador, al aceptar dicho fichero se crea y la cookie sirve entonces
        para tener información respecto al tráfico web, y también facilita las futuras visitas a una
        web recurrente. Otra función que tienen las cookies es que con ellas las web pueden
        reconocerte individualmente y por tanto brindarte el mejor servicio personalizado de su web.
      </p>
      <p>
        Nuestro sitio web emplea las cookies para poder identificar las páginas que son visitadas y
        su frecuencia. Esta información es empleada únicamente para análisis estadístico y después
        la información se elimina de forma permanente. Usted puede eliminar las cookies en cualquier
        momento desde su ordenador. Sin embargo las cookies ayudan a proporcionar un mejor servicio
        de los sitios web, estás no dan acceso a información de su ordenador ni de usted, a menos de
        que usted así lo quiera y la proporcione directamente, <a href="" target="_blank"></a>.
        Usted puede aceptar o negar el uso de cookies, sin embargo la mayoría de navegadores aceptan
        cookies automáticamente pues sirve para tener un mejor servicio web. También usted puede
        cambiar la configuración de su ordenador para declinar las cookies. Si se declinan es
        posible que no pueda utilizar algunos de nuestros servicios.
      </p>
      <p><strong>Enlaces a Terceros</strong></p>
      <p>
        Este sitio web pudiera contener en laces a otros sitios que pudieran ser de su interés. Una
        vez que usted de clic en estos enlaces y abandone nuestra página, ya no tenemos control
        sobre al sitio al que es redirigido y por lo tanto no somos responsables de los términos o
        privacidad ni de la protección de sus datos en esos otros sitios terceros. Dichos sitios
        están sujetos a sus propias políticas de privacidad por lo cual es recomendable que los
        consulte para confirmar que usted está de acuerdo con estas.
      </p>
      <p><strong>Control de su información personal</strong></p>

      <p>
        En cualquier momento usted puede restringir la recopilación o el uso de la información
        personal que es proporcionada a nuestro sitio web.&nbsp; Cada vez que se le solicite
        rellenar un formulario, como el de alta de usuario, puede marcar o desmarcar la opción de
        recibir información por correo electrónico. &nbsp;En caso de que haya marcado la opción de
        recibir nuestro boletín o publicidad usted puede cancelarla en cualquier momento.
      </p>
      <p>
        Esta compañía no venderá, cederá ni distribuirá la información personal que es recopilada
        sin su consentimiento, salvo que sea requerido por un juez con un orden judicial.
      </p>
      <b>Condiciones de Facebook</b>
      <p>
        a. Nuestra Plataforma es el conjunto de API, SDK, herramientas, plugins, código, tecnología,
        contenido y servicios que permite a otras personas, incluidos desarrolladores de
        aplicaciones y operadores de sitios web, desarrollar funciones, obtener datos de Facebook y
        cualquier otro Producto de Facebook, o proporcionarnos datos a nosotros. b. Para usar la
        Plataforma (lo que incluye emplearla para Tratar Datos de la plataforma), aceptas las
        presentes Condiciones de la plataforma (“Condiciones”), así como el resto de las condiciones
        y políticas que sean de aplicación. Estas pueden incluir las Condiciones del servicio de
        Facebook, las Condiciones de uso de Instagram, las Condiciones comerciales de Facebook, las
        Condiciones de las herramientas para empresas y cualesquiera otras condiciones de Productos
        de Facebook que sean de aplicación. c. También debes cumplir los requisitos aplicables
        incluidos en nuestras Políticas para desarrolladores, así como los que figuran en nuestro
        sitio para desarrolladores, incluidos los que encontrarás en la sección Documentación (en
        conjunto, “Documentación para desarrolladores”). d. Las presentes Condiciones entrarán en
        vigor en la fecha en la que las aceptes o en la que accedas o uses la Plataforma, lo que
        ocurra primero, y mantendrán su vigencia hasta que dejes de acceder a dicha Plataforma y
        dejes de usarla, salvo que finalicen antes, en los términos que se describen más adelante.
        Si aceptas estas Condiciones, usas la Plataforma o accedes a ella en nombre de una entidad,
        declaras y garantizas que tienes autoridad para vincular dicha entidad a las presentes
        Condiciones y aceptas, en su nombre, que esta quedará sujeta a las Condiciones (en aras de
        la claridad, se interpretará que todas las referencias a “ti”, “tú” o “tu persona” que se
        hagan en estas Condiciones se refieren a dicha entidad). Para mayor claridad, se considerará
        que las presentes Condiciones funcionan como actualización y reemplazo de la Política de la
        plataforma de Facebook y la Política de la plataforma de Instagram, y se interpretará que
        cualquier referencia a la Política de la plataforma de Facebook, la Política de la
        plataforma de Instagram o la Política de la plataforma que pueda hacerse en condiciones,
        políticas o acuerdos ya existentes se referirá a las presentes Condiciones. e. Si no cumples
        las presentes Condiciones, o cualesquiera otras condiciones o políticas aplicables,
        podríamos suspender o inhabilitar tu Aplicación o tu cuenta, tal y como se describe a
        continuación. f. Los términos en mayúscula que de otra manera no se definan en el presente
        documento (incluida la sección 12, “Glosario”), tienen los significados definidos en
        nuestras otras condiciones y políticas, incluidas nuestras Condiciones del servicio y las
        Condiciones comerciales de Facebook. El término “incluido/a(s)” y la expresión “lo que
        incluye” significan “sin carácter limitativo”.
      </p>
      <b>
        Derechos de Propiedad Intelectual de Facebook
      </b>
      <p>
        a. La Licencia que te concedemos. Supeditada al cumplimiento por tu parte de las presentes
        Condiciones y de cualesquiera otras condiciones y políticas aplicables, te concedemos una
        licencia limitada, no exclusiva, no sublicenciable (excepto a Proveedores de servicios, tal
        y como se describe más adelante), no asignable e intransferible para usar la Plataforma,
        acceder a ella y llevar a cabo una integración con ella, pero solo en la medida en que lo
        permitan las presentes Condiciones y el resto de las condiciones y políticas que sean de
        aplicación. No venderás ni transferirás la Plataforma a nadie, ni concederás licencias de
        esta. Excepto en los términos indicados expresamente en las presentes Condiciones, no
        usarás, modificarás ni traducirás la Plataforma, no accederás a ella, no crearás obras
        derivadas a partir de ella, no llevarás a cabo ninguna integración con ella, no le aplicarás
        ingeniería inversa ni explotarás la Plataforma ni cualquiera de sus elementos de ningún otro
        modo. Las Empresas de Facebook se reservan todos los derechos, títulos e intereses (incluido
        el derecho a ejercer estos derechos) que no se concedan de forma expresa en las presentes
        Condiciones. b. La Licencia que nos concedes. i. De Tu contenido: 1. Nos concedes una
        licencia internacional no exclusiva, transferible, sublicenciable y gratuita para: alojar,
        usar, distribuir, modificar, ejecutar, copiar, exhibir o mostrar públicamente, y traducir
        cualquier información, dato u otro contenido que proporciones o se facilite en tu nombre
        (incluido por medio de tus Proveedores de servicios o a través de tu Aplicación) en relación
        con la Plataforma (en conjunto, “Tu contenido”), así como para crear obras derivadas a
        partir de Tu contenido, con cualquier fin empresarial asociado a la operación, la prestación
        o la mejora de la Plataforma o de cualquier otro Producto de Facebook. Esta licencia
        permanecerá en vigor aunque dejes de usar la Plataforma. La licencia que nos concedes
        incluye, entre otros: el derecho a incorporar Tu contenido en otras partes de los Productos
        de Facebook; el derecho a emplear tu nombre, tus marcas comerciales o tus logotipos para
        atribuir la autoría de Tu contenido; el derecho a usar Tu contenido con fines promocionales;
        y el derecho a analizar Tu contenido (lo que incluye hacerlo para garantizar que cumplas las
        presentes Condiciones y cualesquiera otras condiciones y políticas aplicables). 2. Si
        utilizas las Herramientas para empresas de Facebook con el fin de enviarnos Datos de
        herramientas para empresas, las Condiciones de las herramientas para empresas regirán el uso
        que hagamos de esos datos, en lugar de la licencia de Tu contenido que hemos mencionado
        anteriormente. 3. Si eras propietario de Tu contenido antes de proporcionárnoslo, seguirás
        siéndolo después de hacerlo, de conformidad con cualquier derecho concedido en las presentes
        Condiciones o cualesquiera otras condiciones o políticas aplicables, así como con cualquier
        acceso que proporciones a otros al compartirlo mediante la Plataforma. ii. De tu Aplicación:
        1. Nos concedes una licencia internacional no exclusiva, transferible, sublicenciable y
        gratuita para alojar, usar, distribuir, modificar, ejecutar, copiar, exhibir o mostrar
        públicamente, y traducir tu Aplicación, así como para crear obras derivadas a partir de
        ella, con cualquier fin empresarial relacionado con la operación, la prestación o la mejora
        de la Plataforma. Esta licencia permanecerá en vigor aunque dejes de usar la Plataforma. La
        anterior licencia incluye, entre otros, el derecho a enlazar a tu Aplicación, hacer
        “framing” de ella, ubicar contenido (incluidos anuncios) en torno a ella y analizarla
        (incluido para evaluar el cumplimiento por tu parte de las presentes Condiciones y el resto
        de las condiciones y políticas aplicables). 2. Tal y como se establece entre las partes y en
        relación con la Plataforma, no quedamos sujetos a ninguna de las condiciones o políticas
        asociadas a tu Aplicación o Tu contenido (incluso en el caso de que contractualmente las
        aceptemos con un clic o un toque). Esas condiciones y políticas se consideran nulas, se
        rechazan en las presentes Condiciones y se excluyen de estas. 3. Nada de lo dispuesto en
        estas Condiciones se interpretará como una garantía o un acuerdo respecto a que no
        desarrollaremos (o no hayamos desarrollado) aplicaciones, productos, funciones o servicios
        similares a tu Aplicación o que compitan con ella. iii. De tu Nombre, tus Marcas comerciales
        y tus Logotipos: nos concedes una licencia internacional no exclusiva, transferible,
        sublicenciable y gratuita para usar tu Nombre, tus Marcas comerciales y tus Logotipos con
        fines de distribución, marketing y promoción en relación con el uso que hagas de los
        Productos de Facebook, en cualesquiera formatos o medios. Esta licencia permanecerá en vigor
        para materiales e instancias existentes aunque dejes de usar la Plataforma. c. Proteger los
        Derechos de terceros i. No proporcionarás ni promocionarás contenido en tu Aplicación que
        infrinja o vulnere de otro modo los derechos de cualquier persona o tercero. ii. Obtendrás
        todos los derechos necesarios de todos los titulares pertinentes (y manifestarás y
        garantizarás que los tienes o los has obtenido) para (1) conceder las licencias, los
        derechos y los permisos incluidos en las presentes Condiciones (incluidos los dispuestos en
        la sección 2.b, “La Licencia que nos concedes”); (2) mostrar, distribuir y entregar toda la
        información, todos los datos y cualquier otro contenido de tu Aplicación; y (3) operar tu
        Aplicación de cualquier otro modo. Esto incluye cumplir todas las obligaciones relativas a
        licencias, informes y pagos respecto a terceros. iii. Si tu Aplicación incluye contenido que
        tus Usuarios u otros terceros han enviado o proporcionado, debes contar con un proceso
        adecuado de notificación y eliminación de contenido, y cumplir todas las leyes y normas
        aplicables para responder a notificaciones de supuestas infracciones. Sin perjuicio de dicho
        cumplimiento, en los Estados Unidos debes cumplir todos los requisitos de la Digital
        Millennium Copyright Act.
      </p>
      <p>
        3. Uso de datos a. Prácticas prohibidas. No llevarás a cabo, ni ayudarás o apoyarás a otras
        personas para que lleven a cabo, ninguna de las siguientes prácticas prohibidas (en
        conjunto, “Prácticas prohibidas”): i. Tratar Datos de la plataforma para discriminar a
        personas o fomentar su discriminación en función de atributos personales como raza, etnia,
        color, nacionalidad, religión, edad, sexo, orientación sexual, identidad de género,
        situación familiar, discapacidad, condición médica o genética, u otras categorías protegidas
        por la legislación o las normativas aplicables, o la política de Facebook. ii. Tratar Datos
        de la plataforma para determinar la idoneidad de personas, lo que incluye hacerlo para fines
        relacionados con la vivienda, el empleo, los seguros, las oportunidades educativas, los
        créditos, los beneficios gubernamentales o la condición de inmigrante. Por “determinar la
        idoneidad” nos referimos a decidir si proporcionar, rechazar o eliminar un beneficio en
        concreto (por ejemplo, una vivienda o una beca) y a definir las condiciones bajo las que se
        proporciona, rechaza o elimina ese beneficio. iii. Tratar Datos de la plataforma con el fin
        de ejecutar, facilitar o proporcionar herramientas para vigilancia. La vigilancia incluye el
        Tratamiento de datos de la plataforma en relación con personas, grupos o eventos con fines
        relacionados con las autoridades policiales o la seguridad nacional. iv. Vender, licenciar o
        comprar Datos de la plataforma. v. Incluir o facilitar de cualquier otro modo Datos de la
        plataforma en un motor de búsqueda o un directorio sin nuestro previo consentimiento expreso
        por escrito. vi. Intentar decodificar, eludir, reidentificar o descifrar los Datos del
        usuario que se te hayan proporcionado, o quitarles su anonimato, revertir su cifrado con
        hash o aplicarles ingeniería inversa. vii. Cambiar la función principal o el Tratamiento de
        datos de tu Aplicación para que los Usuarios crean que es una Aplicación desconocida o
        diferente, o cambiar de forma significativa el alcance del Tratamiento de Datos previamente
        recopilados por la plataforma, salvo que en cada caso vuelvas previamente a enviar la
        Aplicación, se revise y reciba nuestra aprobación. viii. Tratar listas de amigos de Facebook
        para establecer conexiones sociales en tu Aplicación, salvo que cada persona de esas
        conexiones te conceda acceso a esa información con ese fin. b. Condiciones adicionales para
        Datos restringidos de la plataforma i. No solicitarás Datos restringidos de la plataforma
        salvo que sea necesario para mejorar de forma significativa la calidad de la experiencia del
        Usuario pertinente en el producto o servicio específico para los que el Usuario haya
        compartido los datos. ii. Debe quedar claro para el Usuario por qué solicitas sus Datos
        restringidos de la plataforma con el fin de mejorar la calidad de su experiencia. iii. En
        aras de la claridad, el Tratamiento que hagas de los Datos restringidos de la plataforma
        deberá cumplir con las indicaciones establecidas en la Documentación para desarrolladores
        aplicable y en otras disposiciones de las presentes Condiciones (incluidas las Prácticas
        prohibidas). c. Compartir Datos de la plataforma. Solo podrás compartir Datos de la
        plataforma conforme a las presentes Condiciones (incluidas las secciones 3.a [“Prácticas
        prohibidas”], 3.b [“Condiciones adicionales para Datos restringidos de la plataforma”], 4
        [“Política de privacidad”] y 5 [”Proveedores de servicios y Proveedores de tecnología”]),
        las leyes y normas aplicables, y el resto de las condiciones y políticas pertinentes, y
        únicamente bajo las siguientes circunstancias: i. Con respecto a los Datos de la plataforma
        recopilados como Proveedor de tecnología, solamente como se describe más adelante en la
        Sección 5.b (“Proveedores de tecnología”). ii. Con respecto a los Datos de la plataforma no
        recopilados como Proveedor de tecnología, 1. cuando lo exija una ley o normativa aplicable
        (debes conservar una prueba del requerimiento o la solicitud, sea legal o regulatoria, y
        proporcionárnosla si te la pedimos); 2. con tu Proveedor de servicios; 3. cuando un Usuario
        te indique explícitamente que compartas los datos con terceros (debes conservar una prueba
        de la indicación expresa del Usuario y proporcionárnosla si te la pedimos); o 4. únicamente
        en lo referente a Datos de la plataforma que no sean Datos restringidos de la plataforma, y
        con otros terceros, siempre y cuando: a. primero les prohíbas contractualmente usar los
        Datos de la plataforma de una forma que infrinja las presentes Condiciones y cualesquiera
        otras condiciones o políticas aplicables (debes conservar una prueba de la prohibición
        contractual y proporcionárnosla si te la pedimos); y b. te asegures de que cualquier tercero
        cumpla las presentes Condiciones, así como todas las políticas y condiciones aplicables como
        si actuara en tu lugar, y te responsabilices de sus actos y omisiones, incluidos los casos
        en que incumpla dichas condiciones y políticas. d. Retención, Eliminación y Accesibilidad de
        los Datos de la plataforma i. A menos que una ley o normativa aplicable requiera que
        conserves Datos de la plataforma, debes hacer lo siguiente (y debes, dentro de lo razonable,
        asegurarte de que también lo hagan tus Proveedores de servicios): 1. Intentar, dentro de lo
        razonable, mantener los Datos de la plataforma actualizados, incluidos aquellos que se han
        modificado o eliminado. Debes actualizar los Datos de la plataforma con rapidez una vez que
        el Usuario o nosotros te lo solicitemos. Debes proporcionar a los Usuarios un método que
        esté indicado claramente y al que puedan acceder con facilidad para solicitar que se
        modifiquen o eliminen sus Datos de la plataforma. 2. Eliminar todos los Datos de la
        plataforma tan pronto como sea razonablemente posible en los siguientes casos: a. Cuando
        conservar los Datos de la plataforma ya no sea necesario para un fin empresarial legítimo
        conforme a las presentes Condiciones y el resto de las condiciones y políticas aplicables;
        b. Cuando dejes de operar el producto o servicio a través del cual se hayan recopilado los
        Datos de la plataforma; c. Cuando te solicitemos que elimines los Datos de la plataforma
        para proteger a los Usuarios (lo que determinaremos bajo nuestro exclusivo criterio); d.
        Cuando un Usuario solicite que se eliminen sus Datos de la plataforma o ya no tenga una
        cuenta contigo (salvo que los Datos de la plataforma se hayan combinado u ocultado, o se
        haya eliminado cualquier referencia personal incluida en ellos, de forma que no se puedan
        asociar a un Usuario, un navegador o un dispositivo específicos) o, en el caso de los
        Proveedores de tecnología, cuando un Usuario o el Cliente solicite que sus Datos de la
        plataforma se eliminen o el Cliente ya no tenga una cuenta contigo; e. Cuando así lo
        requiera una ley o normativa aplicable; o f. Cuando lo requiera la sección 7 (“Derechos de
        revisión de cumplimiento y suspensión y resolución de las presentes Condiciones”). ii. Si
        una ley o normativa aplicable requiere que conserves Datos de la plataforma, debes mantener
        una prueba de la solicitud o el requerimiento regulatorios o legales pertinentes, y
        proporcionárnosla si te la pedimos. iii. Si has recibido Datos de la plataforma por error,
        debes informarnos de ello de inmediato, eliminarlos y proporcionarnos una prueba de su
        eliminación si te la pedimos. e. Excepciones a las Restricciones. Lo dispuesto anteriormente
        en esta sección (sección 3.a-d) no se aplica a determinados Datos de la plataforma que se
        describen aquí. 4. Política de privacidad a. Si utilizas la Plataforma para Tratar Datos de
        la plataforma, proporcionarás una política de privacidad pública y de fácil acceso, y la
        cumplirás. b. Esta política deberá cumplir las leyes y normas aplicables, y en ella deberás
        explicar de forma clara y precisa qué datos Tratas, cómo y con qué fines lo haces, y cómo
        los Usuarios pueden solicitar la eliminación de esos datos. c. Solo podrás Tratar Datos de
        la plataforma de la forma que hayas descrito claramente en tu política de privacidad y de
        conformidad con todas las leyes y normativas aplicables, las presentes Condiciones y el
        resto de las condiciones y políticas pertinentes. d. Tu política de privacidad no
        reemplazará, modificará o entrará en contradicción con estas Condiciones o cualesquiera
        otras condiciones y políticas aplicables. e. Deberás mantener vigentes todas tus políticas
        de privacidad mientras uses la Plataforma y proporcionárnoslas si te las pedimos. f.
        Mantendrás enlaces disponibles públicamente a tus políticas de privacidad en el campo
        “política de privacidad” de la configuración de tu panel de aplicaciones, así como en
        cualquier tienda de aplicaciones que te permita hacerlo, si corresponde, y te asegurarás de
        que los enlaces se mantengan actualizados y al día. 5. Proveedores de servicios y
        Proveedores de tecnología a. Proveedores de servicios i. No usarás un Proveedor de servicios
        en relación con el uso que hagas de la Plataforma o el Tratamiento que lleves a cabo de los
        Datos de la plataforma a menos que dicho Proveedor de servicios primero acepte por escrito
        lo siguiente: 1. Usar la Plataforma y Tratar los Datos de la plataforma solo para ti y según
        tus instrucciones con el fin de proporcionar los servicios que hayas solicitado conforme a
        las presentes Condiciones, el resto de las condiciones y políticas aplicables, y tu política
        de privacidad, y no para otros individuos o entidades ni con otros fines, incluidos los
        intereses del propio Proveedor de servicios; y 2. En caso de que el Proveedor de servicios
        contrate a otro Proveedor de servicios (“Subproveedor de servicios”), asegúrate de que el
        Proveedor de servicios pida al Subproveedor de servicios que se comprometa por escrito a
        cumplir los requisitos anteriores. ii. Debes asegurarte de que cualquier Proveedor de
        servicios y Subproveedor de servicios cumpla las presentes Condiciones, así como el resto de
        las políticas y condiciones aplicables, como si actuara en tu lugar. Tú te responsabilizas
        de sus actos y omisiones, incluido el incumplimiento por su parte de las condiciones y
        políticas mencionadas. iii. Cuando dejes de usar un Proveedor de Servicios o Subproveedor de
        Servicios, deberás asegurarte de que deje de usar la Plataforma y de Tratar Datos de la
        plataforma de inmediato, y deberás eliminar rápidamente todos los Datos de la plataforma que
        tenga o controle. iv. Si te lo solicitamos, deberás proporcionarnos una lista de tus
        Proveedores de servicios y Subproveedores de servicios con la información de contacto
        actualizada de cada uno, los tipos y los volúmenes de Datos de la plataforma compartidos y
        una prueba de los acuerdos por escrito que hayas establecido con tus Proveedores de
        servicios para demostrar el cumplimiento de lo dispuesto en esta sección. v. Es posible que
        te prohibamos emplear cualquier Proveedor de servicios o Subproveedor de servicios en
        relación con el uso que hagas de la Plataforma o el Tratamiento que lleves a cabo de los
        Datos de la plataforma si consideramos que (1) ha infringido las presentes Condiciones u
        otras condiciones o políticas aplicables, o (2) tiene un efecto negativo en la Plataforma,
        otros Productos de Facebook, los Datos de la plataforma o las personas que usan los
        Productos de Facebook. En caso de que tomemos esta medida, te informaremos de ello. Al
        recibir dicha notificación, deberás dejar de usar, con la mayor brevedad posible, ese
        Proveedor de servicios o Subproveedor de servicios en relación con el uso que hagas de la
        Plataforma o el Tratamiento que lleves a cabo de los Datos de la plataforma. vi. Podemos
        solicitar que tu Proveedor de servicios o Subproveedor de servicios acepte las presentes
        Condiciones u otras condiciones o políticas aplicables para poder acceder a los Productos de
        Facebook, la Plataforma o los Datos de la plataforma. b. Proveedores de tecnología i. Si
        eres Proveedor de tecnología, deberás cumplir el resto de las disposiciones de las presentes
        Condiciones, además de las de esta sección en relación con el uso que hagas de la Plataforma
        y el Tratamiento que lleves a cabo de los Datos de la plataforma como Proveedor de
        tecnología. En caso de existir discrepancias entre las condiciones, se aplicarán las que
        sean más restrictivas para ti o las que ofrezcan más protección para nosotros. ii. Tú, como
        Proveedor de tecnología, entiendes y aceptas lo siguiente: 1. Solo usarás la Plataforma y
        Tratarás Datos de la plataforma en nombre de tu Cliente y bajo las instrucciones de este, en
        cuyo nombre accederás a dichos datos para ayudarlo a usar la Plataforma o Tratar Datos de la
        plataforma de conformidad con las presentes Condiciones y el resto de las condiciones y
        políticas aplicables (“Objetivo del cliente”) y no con el fin de satisfacer tus propios
        intereses o los de otro Cliente o entidad (por ejemplo, no Tratarás Datos de la plataforma
        para crear o ampliar perfiles de usuario a fin de cumplir tus propios objetivos o los de
        otro Cliente); 2. Te asegurarás de que los Datos de la plataforma que conserves en nombre de
        un Cliente se mantengan independientes de los de otros Clientes; 3. Conservarás una lista
        actualizada de tus Clientes y su información de contacto, y nos la proporcionarás si te la
        pedimos; 4. Solo compartirás Datos de la plataforma conforme a las presentes Condiciones
        (incluidas las secciones 3.a [“Prácticas prohibidas”], 3.b [“Condiciones adicionales para
        datos de la plataforma restringidos”] y 5 [“Proveedores de servicios”]), las leyes y
        normativas aplicables, y el resto de las condiciones y políticas aplicables, y únicamente
        bajo las siguientes circunstancias: a. con el Cliente correspondiente, siempre y cuando
        primero le prohíbas contractualmente Tratar Datos de la plataforma de una forma que infrinja
        las presentes Condiciones o cualesquiera otras condiciones o políticas aplicables; b. en la
        medida en que lo exija una ley o normativa aplicable (debes conservar una prueba del
        requerimiento o la solicitud regulatorios o legales pertinentes, y proporcionárnosla si te
        la pedimos); c. con tu Proveedor de servicios solo en la medida necesaria para cumplir el
        Objetivo del cliente correspondiente; o d. con el Proveedor de servicios de tu Cliente solo
        en la medida necesaria para cumplir el Objetivo del cliente y cuando este te indique
        expresamente que compartas los datos con ese Proveedor de servicios (debes conservar una
        prueba de la indicación expresa del Cliente y proporcionárnosla si te la pedimos). 5.
        Podemos solicitar que tus Clientes acepten las presentes Condiciones u otras condiciones o
        políticas aplicables para poder acceder a los Productos de Facebook, la Plataforma o los
        Datos de la plataforma a través de tu Aplicación. 6. Cancelarás con rapidez el uso por parte
        de un Cliente de los Productos de Facebook, la Plataforma o los Datos de la plataforma a
        través de tu Aplicación si lo solicitamos porque consideramos que dicho Cliente (a) ha
        infringido las presentes Condiciones u otras condiciones y políticas aplicables, o (b) tiene
        un efecto negativo en la Plataforma, otros Productos de Facebook, los Datos de la plataforma
        o las personas que usan los Productos de Facebook. 6. Seguridad de los datos a. Requisitos
        para la seguridad de los datos i. Siempre tienes que implementar y mantener medidas de
        seguridad administrativas, físicas y técnicas para lo siguiente: 1. Cumplir los estándares
        del sector o superarlos, dada la confidencialidad de los Datos de la plataforma. 2. Cumplir
        las leyes y la normativa aplicables, incluidas las leyes, normas y normativas relacionadas
        con la privacidad y seguridad de los datos. 3. Deben estar diseñadas para evitar cualquier
        Tratamiento (lo que incluye, para mayor claridad, acceder, destruir, perder, alterar,
        divulgar, distribuir o comprometer) no autorizado (incluida la infracción de estas
        Condiciones o cualesquiera otras condiciones o políticas aplicables) de los Datos de la
        plataforma. ii. Debes ofrecer a las personas una forma, disponible públicamente, de informar
        de vulnerabilidades de seguridad en tu Aplicación. Además, debes encargarte con rapidez de
        las deficiencias identificadas. iii. No debes solicitar, recopilar, almacenar, almacenar en
        caché, intercambiar o usar credenciales de inicio de sesión de Facebook o Instagram de otros
        Usuarios. iv. No debes transferir ni compartir identificadores de usuario o tu identificador
        de acceso y clave secreta, salvo que un Proveedor de servicios te ayude a desarrollar,
        ejecutar y operar tu Aplicación. b. Informes de incidentes i. Si ocurre cualquiera de los
        siguientes incidentes, debes avisarnos, con rapidez y en un plazo de 24 horas a partir de
        que te enteres de la existencia del incidente, y proporcionarnos la información que
        solicitamos sobre: 1. Cualquier Tratamiento (lo que incluye, para mayor claridad, acceder,
        destruir, perder, alterar, divulgar, distribuir o comprometer) no autorizado (incluida la
        infracción de estas Condiciones o cualesquiera otras condiciones o políticas aplicables) de
        los Datos de la plataforma. 2. Cualquier incidente que se pueda considerar, dentro de lo
        razonable, que puede comprometer la seguridad, confidencialidad o integridad de tus Sistemas
        de TI o los de tu Proveedor de servicios o Subproveedor de servicios. ii. Debes empezar a
        resolver el incidente de inmediato y cooperar con nosotros dentro de lo razonable, lo que
        incluye informarnos con el mayor detalle posible de la repercusión del incidente en los
        Datos de la plataforma y las medidas correctivas tomadas, y mantenernos al corriente del
        cumplimiento por tu parte en cuanto a cualquier notificación u otros requisitos estipulados
        en las leyes y normas aplicables. 7. Derechos de revisión de cumplimiento y suspensión y
        resolución de las presentes Condiciones a. Revisión de la aplicación. Podemos solicitarte
        que nos envíes tu Aplicación para que la revisemos o aprobemos (“Revisión de la
        aplicación”). Independientemente de la aprobación (a nuestra entera discreción) de tu
        Aplicación (incluido su acceso a cualquier Dato de la plataforma), te asegurarás de que esta
        cumpla las presentes Condiciones y el resto de las condiciones y políticas aplicables. Es
        posible que decidamos (bajo nuestro criterio exclusivo) revisar el cumplimiento de dichas
        condiciones y políticas por parte de tu Aplicación de vez en cuando. Cooperarás en la
        realización de nuestras revisiones y proporcionarás cualquier información que solicitemos en
        relación con ellas. Es posible que verifiquemos los datos que nos proporciones durante estas
        revisiones o los que se encuentren disponibles en tu panel de aplicaciones, que deberás
        actualizar para garantizar que sean completos y precisos. b. Supervisión periódica.
        Nosotros, o profesionales externos que trabajen siguiendo nuestras instrucciones (incluidos
        auditores, abogados, asesores u otros analistas forenses informáticos) (en conjunto,
        “Auditores externos”), podremos supervisar periódicamente tu Aplicación, su acceso a la
        Plataforma y el Tratamiento que haga de los Datos de la plataforma con medidas técnicas y
        operativas. c. Derechos de Auditoría i. Nosotros o los Auditores externos podremos llevar a
        cabo una Auditoría un máximo de una vez al año (salvo que exista un Requisito indispensable)
        para garantizar que tu Tratamiento de los Datos de la plataforma y el de tu Aplicación
        cumplan y hayan cumplido las presentes Condiciones y el resto de las condiciones y políticas
        aplicables. ii. Las Auditorías se realizarán durante el horario comercial normal pasados 10
        días laborables después de enviarte un aviso por escrito (un mensaje de correo electrónico
        será suficiente), a menos que determinemos, bajo nuestro criterio exclusivo, que un
        Requisito indispensable requiere un acceso más inmediato. iii. Cooperarás con la realización
        de las Auditorías, lo que incluye (1) proporcionar todo el acceso necesario físico y remoto
        a tus Sistemas de TI y registros, y (2) proporcionar la información y la asistencia que se
        soliciten, dentro de lo razonable (lo que incluye poner al personal que está al tanto de tu
        Tratamiento de los Datos de la plataforma o el de tu Aplicación a nuestra disposición para a
        que pueda responder a nuestras preguntas). iv. También intentarás, dentro de lo razonable en
        términos empresariales, obtener el permiso y la cooperación de tus Proveedores de servicios
        para que llevemos a cabo estas Auditorías sobre sus Sistemas de TI, sus Registros y el
        personal pertinente. v. Resolverás cualquier incumplimiento que una Auditoría revele tan
        pronto como resulte razonablemente factible (según determinemos en función de los hechos y
        las circunstancias), tras lo cual podremos realizar Auditorías de seguimiento para
        garantizar la adecuada resolución del incumplimiento. vi. Si una Auditoría revela cualquier
        incumplimiento tuyo o de tu Proveedor de servicios, deberás reembolsarnos todos los costes y
        gastos razonables asociados a la Auditoría y cualquier Auditoría de seguimiento. vii. Cuando
        finalicen las presentes Condiciones, nuestros derechos de Auditoría en virtud de esta
        sección prevalecerán durante un (1) año después de que demuestres positivamente que has
        dejado de Tratar todos los Datos de la plataforma y que se han eliminado todas las
        implementaciones de estos que tú o tus Proveedores de servicios tenéis o controláis. Para
        evitar dudas, lo dispuesto en esta sección no limita cualquier otro derecho o recurso que
        tengamos por ley o equidad, o en virtud de las presentes Condiciones u otras condiciones o
        políticas aplicables. d. Certificaciones. De vez en cuando, podremos solicitar (por escrito
        o a través de tu panel de aplicaciones, la Plataforma o cualquier Producto de Facebook)
        información, certificaciones y declaraciones relacionadas con el uso que hagas de la
        Plataforma o el Tratamiento que lleves a cabo de los Datos de la plataforma, que nos
        proporcionarás en el plazo y la forma solicitados. Esto puede incluir certificar: (i) tu
        cumplimiento de las presentes Condiciones y el resto de las condiciones y políticas
        aplicables, y (ii) el objetivo o uso de los Datos de la plataforma que hayas solicitado o a
        los que tengas acceso, y que cada uno de estos objetivos o usos cumplan estas Condiciones y
        el resto de las condiciones y políticas aplicables. Tu representante autorizado deberá
        proporcionar todas estas certificaciones y declaraciones. e. Suspensión y resolución i.
        Podemos tomar medidas coercitivas contra ti y contra tu Aplicación si consideramos, a
        nuestra entera discreción, que: 1. No has respondido a nuestras solicitudes relacionadas con
        la supervisión o la auditoría con rapidez. 2. Tú o tu Aplicación habéis infringido o podéis
        haber infringido las presentes Condiciones o cualesquiera otras condiciones o políticas
        aplicables, o tenéis un efecto negativo en la Plataforma, otros Productos de Facebook, los
        Datos de la plataforma o las personas que usan los Productos de Facebook. 3. Es necesario
        para cumplir con las leyes o normativas aplicables, o lo requiere o solicita una orden
        judicial o una autoridad gubernamental. 4. Es necesario para proteger las Empresas de
        Facebook de una responsabilidad legal o regulatoria. ii. Podemos tomar medidas coercitivas
        en cualquier momento (con o sin aviso previo), lo que incluye hacerlo mientras investigamos
        tu Aplicación. Las medidas coercitivas pueden aplicarse tanto de forma automática como
        manual. Pueden incluir suspender o eliminar tu Aplicación, eliminar tu acceso y el de tu
        Aplicación a la Plataforma, solicitar que dejes de Tratar y eliminar Datos de la plataforma,
        rescindir nuestros acuerdos contigo o cualquier otra medida que consideremos apropiada,
        incluida la resolución de otros acuerdos establecidos contigo o la inhabilitación de tu
        capacidad para usar los Productos de Facebook. iii. Podemos suspender o cancelar el acceso
        de tu Aplicación a API, permisos o funciones de la Plataforma (con o sin aviso previo) si
        dicha Aplicación no ha accedido a ellos ni los ha usado durante 90 días. 8. Notificación Una
        vez que aceptes las presentes Condiciones, cualquier notificación por escrito, solicitud o
        comunicación de nuestra parte hacia ti puede enviarse mediante correo electrónico o correo
        postal (por ejemplo, a la dirección de correo electrónico o dirección postal que se indica
        en la cuenta de tu Aplicación con nosotros), o mediante notificaciones en los Productos de
        Facebook (por ejemplo, en tu cuenta con nosotros). Mantendrás tu información de contacto
        actualizada, incluido tu nombre, el nombre de tu empresa y tu dirección de correo
        electrónico. 9. Indemnización Además de lo dispuesto en la sección “Indemnización” de
        nuestras Condiciones comerciales de Facebook y sin carácter limitativo de su alcance, si
        alguien interpone una reclamación, acción legal o disputa contra las Empresas de Facebook en
        relación con tu uso de la Plataforma, tu Tratamiento de los Datos de la plataforma, Tu
        contenido o Aplicación, tu nombre o logotipo, tus productos o servicios, o tus acciones en
        relación con la Plataforma, indemnizarás y eximirás a las Empresas de Facebook de toda
        responsabilidad ante daños, pérdidas y gastos de cualquier tipo (incluidos costes y
        honorarios judiciales razonables) relacionados con dicha reclamación, causa o disputa. 10.
        Transferencias internacionales Esta sección se aplicará en la medida en que tu Tratamiento
        de los Datos de la plataforma incluya datos personales controlados por Facebook Ireland
        Limited (“Datos de Facebook Ireland”) y la transferencia de dichos Datos de Facebook Ireland
        a un territorio fuera del Espacio Económico Europeo que no haya recibido una decisión de
        adecuación positiva por parte de la Comisión Europea en virtud del artículo 25(6) de la
        directiva 95/46/CE (cada una, una “Transferencia de datos del EEE”). En estos casos
        cumplirás lo siguiente: a. Si te acoges al Escudo de la privacidad UE-EE. UU. (el “Escudo de
        la privacidad”) y este te certifica para recibir categorías de datos que incluyen los Datos
        de Facebook Ireland, cumplirás los Principios del Escudo de la privacidad. Si tu
        certificación del Escudo de la privacidad no cubre la Transferencia de datos del EEE o no
        puedes cumplir los Principios del Escudo de la privacidad (o si finaliza tu certificación
        del Escudo de la privacidad en relación con la Transferencia de datos del EEE), nos lo
        notificarás de inmediato, dejarás de usar Plataforma (y acceder a ella) y de Tratar los
        Datos de la plataforma, y tomarás medidas razonables y adecuadas para resolver cualquier
        incumplimiento. b. Si la Transferencia de datos del EEE no está cubierta por el Escudo de la
        privacidad, tu uso de Datos de Facebook Ireland está sujeto a las Cláusulas. En estos casos,
        Facebook Ireland Limited es el “exportador de datos” y tú eres el “importador de datos”, tal
        y como se definen en las Cláusulas. Seleccionas la opción (iii) de la cláusula II(h) y
        aceptas los principios relativos al tratamiento de datos del anexo A de las Cláusulas. A
        efectos del anexo B de las Cláusulas, se aplicará lo siguiente: i. “Personas Interesadas” se
        refiere a las personas que visitan o usan la Aplicación y los productos y servicios de
        Facebook Ireland Limited, acceden a estos o interactúan con estos. ii. “Objetivo de la
        transferencia” hace referencia a la prestación de la Aplicación y otros productos o
        servicios por tu parte a los Usuarios en virtud de los términos y condiciones y la política
        de privacidad aplicables tuyas o de tu Cliente. iii. “Categorías de datos” se refiere a los
        Datos de Facebook Ireland, que incluyen información del perfil, fotos y vídeos, información
        de ubicación, comunicaciones entre Usuarios, información sobre el uso de la Aplicación y
        otros productos y servicios, información de pago, información del dispositivo, información
        sobre visitas a sitios web o Aplicaciones externos que usan un botón “Me gusta” o “Comentar”
        u otro tipo de integración del servicio, información de socios externos o de las Empresas de
        Facebook, o que, de otro modo, se estipule en la Política de datos. iv. “Destinatarios” hace
        referencia a tu persona y a los Usuarios de tu Aplicación y otros productos y servicios. v.
        “Datos confidenciales” se refiere a los datos de carácter personal sobre origen racial o
        étnico, opiniones políticas, creencias religiosas o filosóficas, afiliación sindical, datos
        genéticos o biométricos destinados a identificar de forma específica a una persona física,
        datos relativos a la salud o a la vida u orientación sexual de una persona física, condenas
        penales o presuntas infracciones penales. vi. “Puntos de contacto para consultas relativas a
        la protección de datos” se refiere a los representantes de Facebook Ireland Limited y a los
        tuyos con responsabilidades en cuanto a la privacidad de los datos. 11. Disposiciones
        generales a. De acuerdo con nuestras Condiciones del servicio, no transferirás ninguno de
        tus derechos ni ninguna de tus obligaciones en virtud de las presentes Condiciones a ningún
        tercero sin nuestro consentimiento previo por escrito. La transferencia puede incluir la
        asignación, adquisición, fusión, modificación del control u otras formas de transferencia.
        Toda transferencia no permitida se considerará nula. Para cualquier transferencia permitida,
        puedes seguir realizando el Tratamiento de los Datos de la plataforma para Tu Aplicación
        conforme a las presentes Condiciones, y solo cuando hayas vuelto a enviar tu Aplicación y
        hayas recibido nuestra aprobación a través del proceso de Revisión de la aplicación. b.
        También debes cumplir todas las leyes y normativas aplicables (incluida la Children’s Online
        Privacy Protection Act [“COPPA”] y la Video Privacy Protection Act [”VPPA”]). c. Si hay
        algún conflicto entre las presentes Condiciones y cualesquiera otras condiciones en internet
        que resulten aplicables, se aplicarán las condiciones que sean más restrictivas para ti y tu
        Aplicación, o que ofrezcan más protección para nosotros. Si has aceptado con anterioridad
        nuestras Condiciones complementarias de productos ampliados de la plataforma o la Enmienda
        para proveedores de tecnología de dichas Condiciones complementarias, las presentes
        Condiciones prevalecen y las reemplazan. d. Nos reservamos el derecho a modificar estas
        Condiciones en cualquier momento. Si sigues usando la Plataforma o accediendo a ella después
        de que se realice alguna modificación en las presentes Condiciones, consideraremos que
        aceptas los cambios realizados. e. Podemos cambiar, suspender o interrumpir la
        disponibilidad de la Plataforma en cualquier momento. Asimismo, podemos imponer límites
        sobre funciones y servicios concretos, o restringir tu acceso a partes o a la totalidad de
        nuestros sitios web o API sin previo aviso y sin incurrir en ninguna responsabilidad. f. Si
        optamos por proporcionarte ayuda o modificaciones relacionadas con la Plataforma, podemos
        dejar de hacerlo en cualquier momento sin previo aviso. g. No garantizamos que la Plataforma
        será siempre gratuita. h. Podemos publicar un comunicado de prensa o realizar declaraciones
        o divulgaciones públicas sobre nuestra relación contigo o tu uso de la Plataforma. i. Cuando
        las presentes Condiciones finalicen, todos los derechos que tengas en virtud de ellas
        cesarán inmediatamente y dejarás de usar la Plataforma de inmediato. Las siguientes
        secciones continuarán en vigencia después de que finalicen estas Condiciones: sección 2.b,
        sección 2.c, sección 3, sección 4, sección 5, sección 6, sección 7, sección 9, sección 10,
        sección 11 y sección 12. 12. Glosario a. “Aplicación” es cualquier integración técnica con
        la Plataforma o a la que le hayamos asignado un número de identificación de Aplicación. Se
        consideran parte de tu Aplicación cualesquiera códigos, API, SDK, herramientas, plugins,
        bots, sitios web, aplicaciones, especificaciones y otras tecnologías que proporciones tú o
        se faciliten en tu nombre en relación con la Plataforma. b. “Auditoría” es una revisión,
        inspección o auditoría de tus Registros o tus Sistemas de TI y los de tus Proveedores de
        servicios. c. “Cláusulas” son las cláusulas contractuales estándar anexadas a la Decisión
        2004/915/CE de la Comisión Europea. d. “Cliente” es el Usuario de la Aplicación del
        Proveedor de tecnología. e. “Desarrollador” es la persona o entidad que crea u opera una
        Aplicación. f. “Documentación para desarrolladores” son los documentos definidos en la
        sección 1.c (“Introducción”). g. “Sistemas de TI” son sistemas de tecnología de la
        información (reales y virtuales), redes, tecnologías e instalaciones (incluido el acceso
        físico y remoto a centros de datos y nubes) que realizan el Tratamiento de los Datos de la
        plataforma. h. “Requisito indispensable” es cuando concurran cualesquiera de las siguientes
        opciones: i. lo requieren leyes, normas o normativas aplicables, o lo requiere o solicita
        una orden judicial o una autoridad gubernamental; ii. sospechamos que tú o tu Aplicación
        habéis realizado un Tratamiento de los Datos de la plataforma que infringe estas Condiciones
        u otras condiciones o políticas aplicables; iii. emprendes una transacción de cambio de
        control o transferencia (o una solicitud de transferencia) de cualesquiera de tus derechos u
        obligaciones en virtud de las presentes Condiciones u otras condiciones o políticas
        aplicables; iv. determinamos, bajo nuestro criterio exclusivo, que es necesario para
        garantizar que tú y tu Aplicación hayáis eliminado los Datos de la plataforma conforme a
        estas Condiciones y el resto de las condiciones y políticas aplicables; v. determinamos, a
        nuestra entera discreción, que es necesario para garantizar la resolución adecuada de
        cualquier incumplimiento revelado mediante una Auditoría. i. “Plataforma” es el conjunto de
        API, SDK, herramientas, plugins, código, tecnología, contenido y servicios que permite a
        otras personas, incluidos desarrolladores de aplicaciones y operadores de sitios web,
        desarrollar funciones, obtener datos de Facebook y cualquier otro Producto de Facebook, o
        proporcionarnos datos a nosotros. j. “Datos de la plataforma” es cualquier información, dato
        o contenido que obtengas de nosotros, a través de la Plataforma o tu Aplicación, ya sea de
        forma directa o indirecta, antes, después o en la fecha en la que aceptas las presentes
        Condiciones, incluidos datos anónimos, agrupados o derivados de esos datos. Los Datos de la
        plataforma incluyen identificadores de usuarios, de aplicaciones, de páginas y de acceso, y
        claves secretas de aplicaciones. k. “Tratar” es cualquier operación o conjunto de
        operaciones realizadas a datos o conjuntos de datos, ya sean automatizadas o no, incluido su
        uso, recopilación, almacenamiento, uso compartido o transmisión. l. “Prácticas prohibidas”
        son las definidas en la sección 3.a (“Prácticas prohibidas”). m. “Registros” son libros
        contables, acuerdos, registros de acceso, informes de terceros, políticas, procesos y otros
        registros relacionados con el Tratamiento de los Datos de la plataforma. n. “Datos
        restringidos de la plataforma” son Datos de la plataforma que (i) pueden usarse, dentro de
        lo razonable, para identificar a un Usuario o un dispositivo específicos, (ii) son
        accesibles mediante los permisos mencionados aquí o (iii) nosotros definimos como
        Restringidos. Sin perjuicio de lo anterior, los Datos restringidos de la plataforma no
        incluyen datos a los que se puede acceder mediante los permisos mencionados aquí. o. “SDK”
        se refiere a cualquier código de objeto, código fuente o documentación que te proporcionemos
        y que te ayude a crear Aplicaciones o contenido para utilizarlos con la Plataforma. p.
        “Proveedor de servicios” es una entidad que usas para obtener servicios relacionados con la
        Plataforma o cualquier Dato de la plataforma. q. “Proveedor de tecnología” es un
        Desarrollador de una Aplicación cuyo principal objetivo es permitir a sus Usuarios acceder a
        la Plataforma o a los Datos de la plataforma y usarlos. r. “Auditores externos” son los
        definidos en la sección 7.b (“Supervisión periódica”). s. “Usuario” es el usuario final de
        una Aplicación (ya sea una persona o una entidad). t. “Tu contenido” es el definido en la
        sección 2.b (“La licencia que nos concedes”).
      </p>
      <p>
        B YOUR PLAN Se reserva el derecho de cambiar los términos de la presente Política de
        Privacidad en cualquier momento.
      </p>
    </div>
    <template #footer>
      <div class="con-footer">
        <vs-button block success @click="okCookies" transparent>
          Ok
        </vs-button>
      </div>
    </template>
  </vs-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Privacity",
  methods: {
    okCookies() {
      localStorage.setItem("privacity", true);
      this.active = false;
      if (window.location.pathname === "/privacity") {
        this.$router.push("/");
      }
    }
  },
  computed: mapState(["showPrivacity"]),
  watch: {},
  data: () => {
    return {
      active: !localStorage.getItem("privacity") || window.location.pathname === "/privacity"
    };
  }
};
</script>

<style scoped>
.con-content {
  text-align: justify;
}
</style>
