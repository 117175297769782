import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: __dirname, // process.env.BASE_URL,
  routes: [{
    path: '/',
    name: 'home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ './views/Home.vue')
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
  },
  {
    path: '/Register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ './views/Register.vue'),
  },
  {
    path: '/ProfileTest',
    name: 'ProfileTest',
    component: () => import(/* webpackChunkName: "profile" */ './views/Profile.vue'),
  },
  {
    path: '/Profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile2" */ './views/Profile2.vue'),
  },
  {
    path: '/Search',
    name: 'Search',
    component: () => import(/* webpackChunkName: "eventList" */ './views/EventsList.vue'),
  },
  {
    path: '/Event/:id?',
    name: 'Event',
    component: () => import(/* webpackChunkName: "event" */ './views/EventDetail.vue'),
  },
  {
    path: '/Privacity',
    name: 'Privacity',
    component: () => import(/* webpackChunkName: "privacity" */ './views/Privacity.vue'),
  },
  {
    path: '/Cookies',
    name: 'Cookies',
    component: () => import(/* webpackChunkName: "privacity" */ './views/Privacity.vue'),
  },
  {
    path: '/Settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "settings" */ './views/Settings.vue'),
  },
  {
    path: '/suggestions',
    name: 'Suggestions',
    component: () => import(/* webpackChunkName: "suggestions" */ './views/Suggestions.vue'),
  },
  {
    path: '/blocks',
    name: 'Blocks',
    component: () => import(/* webpackChunkName: "blocks" */ './views/Blocks.vue'),
  },
  {
    path: '/faqs',
    name: 'Faqs',
    component: () => import(/* webpackChunkName: "faqs" */ './views/Faqs.vue'),
  },
  {
    path: '/problems',
    name: 'problems',
    component: () => import(/* webpackChunkName: "problems" */ './views/Problems.vue'),
  },
  {
    path: '/privacitySettings',
    name: 'PrivacitySettings',
    component: () => import(/* webpackChunkName: "privacitySettings" */ './views/PrivacitySettings.vue'),
  },
  {
    path: '/Validation/:id',
    name: 'Validation',
    component: () => import(/* webpackChunkName: "validation" */ './views/Validation.vue')
  },
  {
    path: '/invite/:id',
    name: 'Invite',
    component: () => import(/* webpackChunkName: "invite" */ './views/Invite.vue')
  },
  {
    path: '/ayuntamiento/:id?',
    name: 'Ayuntamiento',
    component: () => import(/* webpackChunkName: "invite" */ './views/Ayuntamiento.vue')
  },
  {
    path: '/ayuntamiento/:id?/group/:group_id?',
    name: 'AyuntamientoGroup',
    component: () => import(/* webpackChunkName: "invite" */ './views/Group.vue')
  }
  ],
});
