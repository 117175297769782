<template>
  <div v-if="activeSidebar">
    <vs-sidebar class="sbMenu" right v-model="active" :open.sync="activeSidebar">
      <template #logo v-if="activeSidebar">
        <img :src="require('@/assets/android-chrome-512x512_green.png')" />
      </template>

      <template #header>
        <vs-button
          v-if="addChat || addGroup"
          mini
          circle
          transparent
          icon
          floating
          @click="
            () => {
              addChat = false;
              addGroup = false;
            }
          "
        >
          <i class="fas fa-chevron-left"></i>
        </vs-button>
        <h4>Salas de Chat</h4>
      </template>
      <div class="addChatsBtn">
        <vs-button
          color="#659DBD"
          v-if="!addChat && !addGroup"
          size="mini"
          block
          circle
          icon
          floating
          @click="addChat = true"
        >
          <i style="font-size: 0.75rem;" class="fas fa-user-plus fa-2x"></i>
          <template #animate>
            Nuevo Chat individual
          </template>
        </vs-button>
        <vs-button
          color="#55BCC9"
          v-if="!addChat && !addGroup"
          size="mini"
          block
          circle
          icon
          floating
          @click="addGroup = !addGroup"
        >
          <i style="font-size: 0.75rem;" class="fas fa-users"></i>
          <template #animate>
            Nuevo Chat grupal
          </template>
        </vs-button>
      </div>
      <div class="searchBar">
        <vs-input
          @input="filterUser(addChat)"
          label-placeholder="Buscar usuario"
          v-model="filterName"
        />
      </div>
      <div style="text-align: justify;" v-if="this.addChat || this.addGroup">
        <div class="chatList" v-for="(follower, ind) in filteredFollowers" :key="'newchat_' + ind">
          <vs-checkbox v-if="addGroup" :val="follower" success v-model="users">
            <template #icon>
              <i class="fas fa-comment-dots"></i>
            </template>
          </vs-checkbox>
          <div @click="onOpenChat(follower, 'follower')" class="chatListItem">
            <vs-avatar color="rgb(205,55,95)">
              <img v-if="follower.authorImg" :src="follower.authorImg" />
              <template v-else #text>
                {{ follower.username }}
              </template>
            </vs-avatar>
            <div class="chatLIText">
              <h6>{{ follower.username }}</h6>
            </div>
          </div>
        </div>
      </div>
      <div
        @click="onOpenChat(chat, 'chat')"
        v-else
        class="chatListItem"
        v-for="(chat, index) in filteredChats"
        :key="'chat_' + index"
      >
        <div v-if="chat.type === 'single'">
          <div
            style="width: 100%; display: flex;"
            v-for="(part, ind) in chat.participants"
            :key="'part_' + ind"
          >
            <vs-avatar style="margin-right: 1rem;" color="#01e7a6">
              <img :src="part.profileImg" alt="" />
            </vs-avatar>
            <div class="chatLIText">
              <h6>{{ part.username }}</h6>
            </div>
          </div>
        </div>
        <div class="participantGroup" v-else>
          <vs-avatar-group max="5">
            <vs-avatar
              v-for="(part, ind) in chat.participants"
              :key="'part_' + ind"
              style="margin-right: 1rem;"
              color="#01e7a6"
            >
              <img :src="part.profileImg" alt="" />
            </vs-avatar>
          </vs-avatar-group>
          <h6>
            <div
              class="chatLIText"
              v-for="(part, ind) in chat.participants"
              :key="'part_text_' + ind"
            >
              {{ part.username }}
            </div>
          </h6>
        </div>
      </div>
      <vs-button v-if="addGroup" size="mini" block circle success floating @click="createGroup">
        <i class="fas fa-check-circle"></i>
        <template #animate>Crear Grupo </template>
      </vs-button>
      <template #footer>
        <vs-button block danger @click="onCloseSB">
          <i class="fas fa-times-circle"></i>
          <template #animate><i class="fas fa-times-circle"></i>Close </template>
        </vs-button>
      </template>
    </vs-sidebar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { loadingMix, noficationsDialog, authMix, chatMix } from "@/common/mixin.js";

export default {
  name: "chatListSB",
  mixins: [loadingMix, noficationsDialog, authMix, chatMix],
  methods: {
    filterUser(general = false) {
      let array = general ? this.followers : this.chats;
      if (this.filterName !== "") {
        array = array.filter(chat => {
          return chat.participants.some(participant =>
            participant.username.toLocaleLowerCase().includes(this.filterName.toLocaleLowerCase())
          );
        });
      }
      if (general) {
        this.filteredFollowers = array;
      } else {
        this.filteredChats = array;
      }
    },
    async getChatList() {
      const { id } = this.$store.state.currentUser;
      if (id) {
        const chatResponseSingle = await this.getChat("single", [id]);
        const chatResponseGroup = await this.getChat("group", [id]);
        this.chats = [...chatResponseSingle, ...chatResponseGroup];
        const copyChats = this.chats ? [...this.chats] : [];
        copyChats.forEach(xat => {
          let parts = xat.participants.filter(user => user.id !== id);
          xat.participants = parts;
        });
        this.filteredChats = copyChats;
      }
    },
    async getFollowing() {
      const { followDetail } = this.$store.state.currentUser;
      this.filteredFollowers = followDetail;
    },
    onCloseSB() {
      this.$store.commit("showChatListSB", false);
      this.activeSidebar = false;
    },

    async createGroup() {
      const { id, username, profileImg } = this.$store.state.currentUser;
      const aParticipants_ids = [],
        aParticipants_info = [{ id, username, profileImg: profileImg || null }];
      this.users.forEach(user => {
        aParticipants_ids.push(user.created_by);
        aParticipants_info.push({
          id: user.created_by,
          username: user.username,
          profileImg: user.authorImg || null
        });
      });
      const participants_id = [...[id], ...aParticipants_ids];
      const chat = await this.getChat("group", participants_id);
      if (!chat) {
        const data = {
          created_at: new Date(),
          participants_id,
          participants: aParticipants_info,
          messages: [],
          type: "group"
        };
        const newChat = await this.createChat(data);
        data.id = newChat.id;
        this.users = [];
        this.openChatMix(data);
      } else {
        this.users = [];
        this.openChatMix(chat);
      }
    },

    async onOpenChat(dstChat, type) {
      this.addChat = false;
      if (!this.addGroup) {
        this.activeSidebar = false;
        let chat;
        switch (type) {
          case "chat":
            chat = await this.getChat(null, null, dstChat.id);
            this.openChatMix(chat);
            break;
          case "follower":
            const { id, username, profileImg } = this.$store.state.currentUser;
            chat = await this.getChat("single", [id, dstChat.created_by]);
            if (!chat) {
              const data = {
                created_at: new Date(),
                participants_id: [id, dstChat.created_by],
                participants: [
                  { id, username, profileImg: profileImg || null },
                  {
                    id: dstChat.created_by,
                    username: dstChat.username,
                    profileImg: dstChat.authorImg || null
                  }
                ],
                messages: [],
                type: "single"
              };
              const newChat = await this.createChat(data);
              data.id = newChat.id;
              this.openChatMix(data);
            } else {
              this.openChatMix(chat);
            }
            break;

          default:
            break;
        }
      }
    }
  },
  computed: mapState(["showChatListSB"]),
  watch: {
    showChatListSB(newValue, oldValue) {
      this.activeSidebar = newValue;
      if (newValue) {
        this.getChatList();
        this.getFollowing();
      }
    }
  },
  data() {
    const { showChatListSB, currentUser } = this.$store.state;
    return {
      filteredFollowers: [],
      filteredChats: [],
      filterName: "",
      variant: "info",
      active: "test",
      salas: [],
      chats: [],
      users: [],
      followers: [],
      addChat: false,
      addGroup: false,
      activeSidebar: showChatListSB,
      myUser: currentUser,
      profileImg: currentUser.profileImg || null,
      username: currentUser.username || null
    };
  },
  mounted() {}
};
</script>

<style scoped>
@media (min-width: 992px) {
  .sbMenu {
    top: 30px;
    max-height: calc(100% - 54px);
  }
}

@media (max-width: 991px) {
  .sbMenu {
    top: 54px;
    max-height: calc(100% - 78px);
  }
}

.sbMenu {
  border-radius: 30px 0px 0px 30px;
  z-index: 1;
}

.chatList {
  padding: 0.5rem;
  width: 100%;
  display: inline-flex;
  justify-content: flex-start;
}

.chatListItem {
  padding: 0.5rem;
  width: 100%;
  display: flex;
  text-align: justify;
  justify-content: flex-start;
  cursor: pointer;
}

.chatListItem > .chatLIText {
  margin: 0.5rem;
}

.addChatsBtn {
  display: inline-flex;
  width: 100%;
  justify-content: space-evenly;
}

.searchBar {
  margin: 0 auto;
}

.participantGroup {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
</style>
