<template>
  <vs-dialog scroll overflow-hidden v-model="active" v-on:close="onClose">
    <template #header>
      <h3>Crear grupo</h3>
    </template>
    <div>
      <div class="chatList" v-for="(follower, ind) in filteredFollowers" :key="'newchat_' + ind">
        <vs-checkbox v-if="addGroup" :val="follower" success v-model="users">
          <template #icon>
            <i class="fas fa-comment-dots"></i>
          </template>
        </vs-checkbox>
        <div @click="onOpenChat(follower, 'follower')" class="chatListItem">
          <vs-avatar color="rgb(205,55,95)">
            <img v-if="follower.authorImg" :src="follower.authorImg" />
            <template v-else #text>
              {{ follower.username }}
            </template>
          </vs-avatar>
          <div class="chatLIText">
            <h6>{{ follower.username }}</h6>
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="con-footer">
        <vs-button block success floating size="mini" @click="onOpenChat">
          Crear grupo
        </vs-button>
      </div>
    </template>
  </vs-dialog>
</template>

<script>
import { loadingMix, noficationsDialog, authMix, chatMix } from "@/common/mixin.js";

export default {
  name: "ChatGroupUsersSelector",
  mixins: [loadingMix, noficationsDialog, authMix, chatMix],
  props: ["active", "participants"],
  methods: {
    async onOpenChat() {
      const { id } = this.$store.state.currentUser;
      const aParticipants_ids = [],
        aParticipants_info = [];
      this.users.forEach(user => {
        aParticipants_ids.push(user.created_by);
        aParticipants_info.push({
          id: user.created_by,
          username: user.username,
          profileImg: user.authorImg || null
        });
      });
      this.$props.participants.forEach(user => {
        aParticipants_ids.push(user.id);
        aParticipants_info.push({
          id: user.id,
          username: user.username,
          profileImg: user.profileImg || null
        });
      });
      const participants_id = [...aParticipants_ids];
      const chat = await this.getChat("group", participants_id);
      this.$store.commit("showChatSB", { show: true, chat: null });
      if (!chat) {
        const data = {
          created_at: new Date(),
          participants_id,
          participants: aParticipants_info,
          messages: [],
          type: "group"
        };
        const newChat = await this.createChat(data);
        data.id = newChat.id;
        this.users = [];
        this.openChatMix(data);
      } else {
        this.users = [];

        this.openChatMix(chat, true);
      }
    },
    async getFollowing() {
      const { followDetail } = this.$store.state.currentUser;
      const ids = [];
      this.$props.participants.forEach(participant => {
        ids.push(participant.id);
      });
      this.filteredFollowers = followDetail.filter(follow => {
        return !ids.includes(follow.created_by);
      });
    },
    onClose() {
      this.$emit("onClose", true);
    }
  },
  watch: {},
  mounted() {
    this.getFollowing();
  },
  computed: {
    getActive: () => {
      return this.isOpen;
    }
  },
  data: () => {
    const isOpen = false;
    return {
      addGroup: true,
      users: [],
      isOpen,
      filteredFollowers: []
    };
  }
};
</script>

<style scoped>
.chatList {
  padding: 0.5rem;
  width: 100%;
  display: inline-flex;
  justify-content: flex-start;
}

.chatListItem {
  padding: 0.5rem;
  width: 100%;
  display: flex;
  text-align: justify;
  justify-content: flex-start;
  cursor: pointer;
}

.chatListItem > .chatLIText {
  margin: 0.5rem;
}
</style>
