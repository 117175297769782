<template>
  <svg
  class="byourplanIllus"
    xmlns="http://www.w3.org/2000/svg"
    id="e79c079d-1564-4f8d-8abe-3d75200accaa"
    data-name="Layer 1"
    width="827.8999"
    height="666.5"
    viewBox="0 0 827.8999 666.5"
  >
    <path
      d="M335.21814,555.90723a7.03378,7.03378,0,0,1-2.1438-.33692,6.95171,6.95171,0,0,1-4.09155-3.457l-6.76355-13.11816a7.00731,7.00731,0,0,1,3.014-9.4292l109.33728-56.37158A7.00756,7.00756,0,0,1,444,476.2085l6.76343,13.11816a7.00721,7.00721,0,0,1-3.01416,9.4292L338.41235,555.12744A6.95569,6.95569,0,0,1,335.21814,555.90723Z"
      transform="translate(-186.05005 -116.75)"
      fill="#f50057"
    />
    <path
      d="M374.87971,504.79687a7.03706,7.03706,0,0,1,.74314,1.2945l11.55364,25.23071a6.99947,6.99947,0,0,1-3.5732,9.237l-38.859,13.582a17.48362,17.48362,0,0,1-21.47645-8.84216l.44928-.21893-.44888.21865a17.46934,17.46934,0,0,1,7.25337-22.95166l35.831-19.812a7.01189,7.01189,0,0,1,8.52709,2.262Z"
      transform="translate(-186.05005 -116.75)"
      fill="#2f2e41"
    />
    <path
      d="M832.44995,438.75H645.26a17.65489,17.65489,0,0,1-11.57837-4.373L556.5791,366.375A14.47132,14.47132,0,0,0,546.99,362.75H480.45V133.25a16.51868,16.51868,0,0,1,16.5-16.5h319a16.51867,16.51867,0,0,1,16.5,16.5Z"
      transform="translate(-186.05005 -116.75)"
      fill="#e6e6e6"
    />
    <circle cx="370.8999" cy="71.5" r="35" fill="#fff" />
    <path
      d="M692.94995,169.5h-65a4.5,4.5,0,0,0,0,9h65a4.5,4.5,0,0,0,0-9Z"
      transform="translate(-186.05005 -116.75)"
      fill="#fff"
    />
    <path
      d="M787.30982,198H627.09009c-2.01025,0-3.64014,2.02-3.64014,4.5s1.62989,4.5,3.64014,4.5H787.30982c2.01025,0,3.64013-2.02,3.64013-4.5S789.32007,198,787.30982,198Z"
      transform="translate(-186.05005 -116.75)"
      fill="#fff"
    />
    <path
      d="M774.72,258.25h-236.54c-1.91992,0-3.48,2.02-3.48,4.5s1.56006,4.5,3.48,4.5H774.72c1.91992,0,3.48-2.02,3.48-4.5S776.63989,258.25,774.72,258.25Z"
      transform="translate(-186.05005 -116.75)"
      fill="#fff"
    />
    <path
      d="M774.72,286.25h-236.54c-1.91992,0-3.48,2.02-3.48,4.5s1.56006,4.5,3.48,4.5H774.72c1.91992,0,3.48-2.02,3.48-4.5S776.63989,286.25,774.72,286.25Z"
      transform="translate(-186.05005 -116.75)"
      fill="#fff"
    />
    <path
      d="M774.72,314.25h-236.54c-1.91992,0-3.48,2.02-3.48,4.5s1.56006,4.5,3.48,4.5H774.72c1.91992,0,3.48-2.02,3.48-4.5S776.63989,314.25,774.72,314.25Z"
      transform="translate(-186.05005 -116.75)"
      fill="#fff"
    />
    <path
      d="M774.72,342.25h-236.54c-1.91992,0-3.48,2.02-3.48,4.5s1.56006,4.5,3.48,4.5H774.72c1.91992,0,3.48-2.02,3.48-4.5S776.63989,342.25,774.72,342.25Z"
      transform="translate(-186.05005 -116.75)"
      fill="#fff"
    />
    <path
      d="M987.45,438.75H645.26a17.37609,17.37609,0,0,1-9.585-2.86279l-.2251-.14795V202.25a16.51868,16.51868,0,0,1,16.5-16.5h319a16.51867,16.51867,0,0,1,16.5,16.5Z"
      transform="translate(-186.05005 -116.75)"
      fill="#f50057"
    />
    <circle cx="525.8999" cy="140.5" r="35" fill="#fff" />
    <path
      d="M847.95,238.5h-65a4.5,4.5,0,0,0,0,9h65a4.5,4.5,0,0,0,0-9Z"
      transform="translate(-186.05005 -116.75)"
      fill="#fff"
    />
    <path
      d="M942.30982,267H782.09009c-2.01025,0-3.64014,2.02-3.64014,4.5s1.62989,4.5,3.64014,4.5H942.30982c2.01025,0,3.64013-2.02,3.64013-4.5S944.32007,267,942.30982,267Z"
      transform="translate(-186.05005 -116.75)"
      fill="#fff"
    />
    <path
      d="M929.72,327.25h-236.54c-1.91992,0-3.48,2.02-3.48,4.5s1.56006,4.5,3.48,4.5H929.72c1.91992,0,3.48-2.02,3.48-4.5S931.63989,327.25,929.72,327.25Z"
      transform="translate(-186.05005 -116.75)"
      fill="#fff"
    />
    <path
      d="M929.72,355.25h-236.54c-1.91992,0-3.48,2.02-3.48,4.5s1.56006,4.5,3.48,4.5H929.72c1.91992,0,3.48-2.02,3.48-4.5S931.63989,355.25,929.72,355.25Z"
      transform="translate(-186.05005 -116.75)"
      fill="#fff"
    />
    <path
      d="M929.72,383.25h-236.54c-1.91992,0-3.48,2.02-3.48,4.5s1.56006,4.5,3.48,4.5H929.72c1.91992,0,3.48-2.02,3.48-4.5S931.63989,383.25,929.72,383.25Z"
      transform="translate(-186.05005 -116.75)"
      fill="#fff"
    />
    <path
      d="M929.72,411.25h-236.54c-1.91992,0-3.48,2.02-3.48,4.5s1.56006,4.5,3.48,4.5H929.72c1.91992,0,3.48-2.02,3.48-4.5S931.63989,411.25,929.72,411.25Z"
      transform="translate(-186.05005 -116.75)"
      fill="#fff"
    />
    <path
      d="M996.95,436.25H645.26a14.94463,14.94463,0,0,1-9.31006-3.24c-.21-.16-.41015-.33-.60986-.51l-29.77-26.25L558.23023,364.5A16.97377,16.97377,0,0,0,546.99,360.25H460.95a17.02411,17.02411,0,0,0-17,17v389a17.02411,17.02411,0,0,0,17,17h536a17.0241,17.0241,0,0,0,17-17v-313A17.0241,17.0241,0,0,0,996.95,436.25Zm15,330a15.01828,15.01828,0,0,1-15,15h-536a15.01828,15.01828,0,0,1-15-15v-389a15.01828,15.01828,0,0,1,15-15h86.04a14.965,14.965,0,0,1,9.91992,3.75l45.64014,40.25L634.01,434a17.14841,17.14841,0,0,0,11.25,4.25H996.95a15.01828,15.01828,0,0,1,15,15Z"
      transform="translate(-186.05005 -116.75)"
      fill="#3f3d56"
    />
    <circle cx="103.25377" cy="326.07215" r="53.51916" fill="#f50057" />
    <path
      d="M288.871,782.668H274.1117a7.00786,7.00786,0,0,1-7-7V652.6543a7.00785,7.00785,0,0,1,7-7H288.871a7.00785,7.00785,0,0,1,7,7V775.668A7.00786,7.00786,0,0,1,288.871,782.668Z"
      transform="translate(-186.05005 -116.75)"
      fill="#f50057"
    />
    <path
      d="M320.871,782.668H306.1117a7.00786,7.00786,0,0,1-7-7V652.6543a7.00785,7.00785,0,0,1,7-7H320.871a7.00785,7.00785,0,0,1,7,7V775.668A7.00786,7.00786,0,0,1,320.871,782.668Z"
      transform="translate(-186.05005 -116.75)"
      fill="#f50057"
    />
    <path
      d="M374.46724,625.41873c0,43.6305-41.04132,57.70416-84.67181,57.70416s-73.32819-14.07366-73.32819-57.70416,29.36951-118,73-118S374.46724,581.78824,374.46724,625.41873Z"
      transform="translate(-186.05005 -116.75)"
      fill="#2f2e41"
    />
    <path
      d="M288.17937,462.65814l-.05566-2c3.7207-.10352,7.001-.33692,9.46582-2.1377a6.14794,6.14794,0,0,0,2.38134-4.52832,3.51432,3.51432,0,0,0-1.15283-2.89453c-1.63623-1.38183-4.269-.93457-6.188-.05469l-1.65478.75879,3.17334-23.19043,1.98144.27149-2.69921,19.72656c2.60742-.7666,5.02343-.43652,6.67822.96094a5.471,5.471,0,0,1,1.86035,4.49219,8.13264,8.13264,0,0,1-3.2002,6.07324C295.60222,462.45013,291.4865,462.56537,288.17937,462.65814Z"
      transform="translate(-186.05005 -116.75)"
      fill="#2f2e41"
    />
    <rect x="119.87932" y="313.83392" width="10.77148" height="2" fill="#2f2e41" />
    <rect x="85.87932" y="313.83392" width="10.77148" height="2" fill="#2f2e41" />
    <path
      d="M883.95,714.25h-294a15.017,15.017,0,0,1-15-15v-162a15.017,15.017,0,0,1,15-15h294a15.017,15.017,0,0,1,15,15v162A15.017,15.017,0,0,1,883.95,714.25Zm-294-190a13.0149,13.0149,0,0,0-13,13v162a13.0149,13.0149,0,0,0,13,13h294a13.01489,13.01489,0,0,0,13-13v-162a13.01489,13.01489,0,0,0-13-13Z"
      transform="translate(-186.05005 -116.75)"
      fill="#e6e6e6"
    />
    <circle cx="451.3999" cy="465.5" r="35" fill="#e6e6e6" />
    <path
      d="M773.44995,563.5h-65a4.5,4.5,0,0,0,0,9h65a4.5,4.5,0,0,0,0-9Z"
      transform="translate(-186.05005 -116.75)"
      fill="#e6e6e6"
    />
    <path
      d="M867.80982,592H707.59009c-2.01025,0-3.64014,2.02-3.64014,4.5s1.62989,4.5,3.64014,4.5H867.80982c2.01025,0,3.64013-2.02,3.64013-4.5S869.82007,592,867.80982,592Z"
      transform="translate(-186.05005 -116.75)"
      fill="#e6e6e6"
    />
    <path
      d="M855.22,652.25h-236.54c-1.91992,0-3.48,2.02-3.48,4.5s1.56006,4.5,3.48,4.5H855.22c1.91992,0,3.48-2.02,3.48-4.5S857.13989,652.25,855.22,652.25Z"
      transform="translate(-186.05005 -116.75)"
      fill="#e6e6e6"
    />
    <path
      d="M855.22,680.25h-236.54c-1.91992,0-3.48,2.02-3.48,4.5s1.56006,4.5,3.48,4.5H855.22c1.91992,0,3.48-2.02,3.48-4.5S857.13989,680.25,855.22,680.25Z"
      transform="translate(-186.05005 -116.75)"
      fill="#e6e6e6"
    />
    <path
      d="M246.591,428.11737c4.16234-6.26559,11.4863-8.92153,18.70849-9.56761,8.12915-.72722,15.984.84034,23.92086,2.36333,8.73488,1.67612,17.97938,3.24433,26.78082,1.1252a26.40082,26.40082,0,0,0,17.94625-14.49785,33.771,33.771,0,0,0,1.96625-22.51809c-2.06247-7.98628-7.18189-15.01346-13.388-20.32108-12.66675-10.83291-29.808-15.70142-46.295-15.24284a78.26922,78.26922,0,0,0-45.37458,15.95451,73.69726,73.69726,0,0,0-26.14492,40.97166,76.82857,76.82857,0,0,0,3.72036,48.324c3.60782,8.36937,8.8257,15.91461,12.852,24.07322,3.74876,7.59622,6.63582,16.428,4.30331,24.90078-2.04673,7.43473-8.05578,14.03982-15.59957,16.05835-7.18512,1.92255-14.27963-1.22877-18.21661-7.43678-3.77111-5.94647-3.86134-13.73512,1.80078-18.53636a13.8984,13.8984,0,0,1,20.05,2.00069,14.30627,14.30627,0,0,1,2.86169,10.48915c-.27893,1.88857,2.61132,2.70358,2.89284.79752a16.923,16.923,0,0,0-31.55308-10.92725c-3.41455,6.41994-1.57221,14.16342,2.76225,19.65111a19.1535,19.1535,0,0,0,19.52851,7.02234c7.88991-1.82216,14.34427-8.093,17.37957-15.462,3.37918-8.2038,1.83093-17.35864-1.46324-25.31936-3.56091-8.60531-8.84882-16.323-13.03059-24.61558a70.10576,70.10576,0,0,1-7.11365-23.95286,75.791,75.791,0,0,1,10.80937-48.0074,70.44136,70.44136,0,0,1,38.79846-29.487c15.80536-4.96142,33.2372-4.80947,48.71265,1.288,15.24373,6.0062,29.96334,19.09555,29.78659,36.67535-.0755,7.50948-2.86319,15.16772-8.61526,20.17651-6.87415,5.98589-16.44555,6.49759-25.08688,5.5634-8.69614-.94013-17.14086-3.47979-25.87454-4.12765-7.59644-.5635-15.79185.02234-22.57455,3.79644a22.571,22.571,0,0,0-7.841,7.27391c-1.07134,1.61269,1.52584,3.11667,2.59041,1.51415Z"
      transform="translate(-186.05005 -116.75)"
      fill="#2f2e41"
    />
    <path
      d="M231.17322,678.957a7.00641,7.00641,0,0,1-1.05725-.08057l-14.59119-2.2207a7.00788,7.00788,0,0,1-5.86694-7.97363l18.5105-121.61279a7.008,7.008,0,0,1,7.97351-5.86719l14.59131,2.22119a7.00713,7.00713,0,0,1,5.86694,7.97314L238.08948,673.00977A7.01178,7.01178,0,0,1,231.17322,678.957Z"
      transform="translate(-186.05005 -116.75)"
      fill="#f50057"
    />
    <path
      d="M248.61011,596.07568a7.03708,7.03708,0,0,1-1.48413-.15918l-27.205-5.47412a6.99946,6.99946,0,0,1-5.37536-8.31836l11.8396-39.4248a17.4836,17.4836,0,0,1,19.77294-12.18408l-.08679.49218.08679-.49169a17.46934,17.46934,0,0,1,14.31128,19.354l-5.01916,40.63476a7.01188,7.01188,0,0,1-6.84021,5.57129Z"
      transform="translate(-186.05005 -116.75)"
      fill="#2f2e41"
    />
    <path
      d="M377.90665,783.25h-157a1,1,0,0,1,0-2h157a1,1,0,0,1,0,2Z"
      transform="translate(-186.05005 -116.75)"
      fill="#3f3d56"
    />
  </svg>
</template>

<script>
export default {
  name: "iCulture",
  props: [],
  methods: {},
  data: () => {
    return {};
  }
};
</script>

<style scoped>
.byourplanIllus {
  width: 100%;
  height: auto;
}
</style>
